<template>
	<div>
	   <nav-bar></nav-bar>
	   <div>
		  <!-- <dashboard-stats></dashboard-stats> -->
	   </div>
	   <div >
		  <section class="cart-main col-xs-12" style="min-height:60vh;" v-if="homeLogin && refsUserData.data">
			 <!-- global accounts details -->
			 <div class="global-accounts-wrapper">
				<div class="wel-note-wrapper">
				   <div class="wel-note">
					  <h1>Hello '{{ refsUserData.data.name }}'</h1>
					  <h2>
						 Welcome to your <span>customer commission area</span>
					  </h2>
				   </div>
				   <span class="link float-right" @click="logoutEvent()">Logout</span>
				   <div class="RR-id">
					  <p>RR ID No: <span>{{ refsUserData.data.resellerNumber }}</span></p>
				   </div>
				</div>
			 </div>
			 <div class="tab-details-wrap">
				<div class="account-and-order-wrapper">
				   <div class="tap-button-wrap">
					  <div class="button-wrapper">
						 <button
							class="global-account-btn"
							:class="{ 'active-btn': isActive === 'accounts' }"
							@click="toggleActive('accounts')"
							type="button"
							>
						 ACCOUNTS
						 </button>
					  </div>
					  <!-- Customer commission button  -->
					  <div class="button-wrapper">
						 <button
							class="customer-order-btn"
							:class="{ 'active-btn': isActive === 'customerOrder' }"
							@click="toggleActive('customerOrder')"
							type="button"
							>
						 CUSTOMER ORDER & COMMISSION
						 </button>
					  </div>
				   </div>
				   <div class="account-details" :class="{ 'visible': isActive === 'accounts', 'hidden': isActive !== 'accounts' }">
						<div class="row">
							<div class="col-sm-4 details-wrapper">
								<ul>
									<li>
									<p>RR Name: 
										<span class="float-right">
										{{ refsUserData.data.name }}
										</span>
									</p>
									</li>
									<li>
									<p>RR ID No.: 
										<span class="float-right">
										{{ refsUserData.data.resellerNumber }}
										</span>
									</p>
									</li>
									<li>
									<p>RR Email address: 
										<span class="float-right">
										{{ refsUserData.data.resellerEmail }}
										</span>
									</p>
									</li>
									<li>
									<p>RR Agreed Commission: 
										<span class="float-right">
										{{ refsUserData.data.resellerCommission }}%
										</span>
									</p>
									</li>
									<li>
									<p>Date RR Account Set Up: 
										<span class="float-right">
										{{ new Date(refsUserData.data.date).toLocaleDateString() }}
										</span>
									</p>
									</li>
								</ul>
							</div>
						</div>					  
				   </div>
				   <div class="cus-order-details-wrap" :class="{ 'visible': isActive === 'customerOrder', 'hidden': isActive !== 'customerOrder' }">
					  <div class="filterandsearch-wrapper">
						 <div class="search-box">
							<a href="#" target="_blank" hidden></a>
							<input type="search" placeholder="Type to search..."> 
							<div class="icon">
							   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search"
								  viewBox="0 0 16 16">
								  <path
									 d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
							   </svg>
							</div>
						 </div>
						 <div class="filter-wrapper">
							<div class="filter-week-wrap">
							   <select name="" id="week">
								  Weeks
								  <option value="">First Week</option>
								  <option value="">Second Week</option>
								  <option value="">Third Week</option>
								  <option value="">Forth Week</option>
							   </select>
							</div>
							<div class="filter-month-wrap">
							   <select class="form-control" v-model="selectedMonth" @change="updateDays" id="month">
								  Month
								  <option v-for="(month, index) in months" :value="index">{{ month }}</option>
							   </select>
							</div>
							<div class="filter-year">
							   <select class="form-control" v-model="selectedYear" @change="updateDays" id="Years">
								  Years
								  <option v-for="year in availableYears" :key="year" :value="year">{{ year }}</option>
							   </select>
							</div>
						 </div>
					  </div>
					  <div class="download-btn-wrap">
						 <button class="download-btn">
							Download
							<svg width="20px" height="20px" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
							   <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
							   <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
							   <g id="SVGRepo_iconCarrier">
								  <g id="Interface / Download">
									 <path id="Vector" d="M6 21H18M12 3V17M12 17L17 12M12 17L7 12" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
								  </g>
							   </g>
							</svg>
						 </button>
					  </div>
					  <table class="cus-order table">
						 <thead>
							<tr>
							   <th>Cust No.</th>
							   <th>Business Name</th>
							   <th>Order No.</th>
							   <th>Supplier</th>
							   <th>Price</th>
							   <th>Product</th>
							   <th>Commission</th>
							   <th>Commission Price</th>
							</tr>
						 </thead>
						 <tbody>
							<tr>
							   <td>VC180</td>
							   <td>VLS2</td>
							   <td>V0368</td>
							   <td>Quinns The Printer</td>
							   <td>€148</td>
							   <td>Business Cards</td>
							   <td>5%</td>
							   <td>€7.40</td>
							</tr>
							<tr>
							   <td>VC181</td>
							   <td>Greenday</td>
							   <td>V0365</td>
							   <td>Quinns The Printer</td>
							   <td>€85</td>
							   <td>Business card</td>
							   <td>5%</td>
							   <td>€14.25</td>
							</tr>
							<tr>
							   <td>VC161</td>
							   <td>AccType3</td>
							   <td>V0343</td>
							   <td>Quinns The Printer</td>
							   <td>€85</td>
							   <td>Business Card</td>
							   <td>5%</td>
							   <td>€4.25</td>
							</tr>
						 </tbody>
					  </table>
					  <div class="total-pricing-wrapper">
						 <div class="total-pricing">
							<p>Total Pricing</p>
							<div class="total-value">€438.00</div>
						 </div>
						 <div class="total-pricing">
							<p>Total Commission</p>
							<div class="total-value">€12.90</div>
						 </div>
					  </div>
				   </div>
				</div>
			 </div>
			<div class="term-condition-wrap">
				<a href="#">Term & Conditions</a>
			</div>
			 <!-- end global accounts -->
		  </section>
		  <!-- Cart main content : End -->
		  <div class="modal-mask" v-if="needLogin">
			 <div class="modal-wrapper">
				<div class="modal-container">
				   <div class="modal-body">
					  <button class="btn btn-danger" style="float:right;" @click="cancelEvent()">
					  <b>&#10006;</b>
					  </button>
					  <h1 class="ct-header" >Reseller Login</h1>
					  <p class="text-success" v-if="loading"> <b>Please Wait...</b> </p>
					  <span class="text-danger" v-if="message.length"> <b>{{ message }}</b></span>
					  <span class="text-danger" v-if="error.length"><b>{{ error }}</b></span>
					  <div>
						 <p>If you have an account with us, please log in.</p>
						 <form id="login-form" class="form-validate form-horizontal" method="post" action="#">
							<p>Email Address <span class="star">*</span></p>
							<input class="email form-control" type="text" value="" v-model="email" required>
							<p>Password <span class="star">*</span></p>
							<input class="pasword form-control" type="password" value="" v-model="password" required>
							<div class="modal-footer">
							   <button type="submit" class="login btn btn-primary" @click.prevent="refsLogin" style="float: right; margin-top:9px;">Login</button>
							   <!-- <b class="btn btn-link" style="float:right;margin-top:-35px;" @click="forgotPassword = true;cleareMessage();">Forgot Password?</b> -->
							</div>
						 </form>
					  </div>
				   </div>
				</div>
			 </div>
		  </div>
		  <!--Cart right banner: Begin-->
		  <footer-section></footer-section>
	   </div>
	</div>
 </template>

<script>
import NavBar from "@/components/NavBar.vue"
import DashboardStats from "./DashboardStats.vue"
import FooterSection from "@/components/FooterSection.vue"
import {mapState} from 'vuex'
export default {
components:{DashboardStats,NavBar, FooterSection},
methods:{

},
computed:{
	...mapState(['userOrders','refs','user','refsUserData']),
	myRefs(){
		let refs = []
		if(this.refs.length){
			this.refs.forEach(element => {
				if(element._id == this.user._id) { refs.push(element)}
			});
		}else{
			refs = []
		}
		return refs;
	},
	usOrders(){
		let ps = [];
		this.userOrders.forEach(element => {
			if(element.items[0].product !== null)
			{
				ps.push(element)
			}
		});
		return ps;
	},
	async cancelEvent(){
		this.$router.push('/');
		this.needLogin = true	
		this.homeLogin = false
	},
	async logoutEvent(){
		localStorage.setItem('globalAcc', "false")
		localStorage.setItem('globalAccUser', '')
		this.$router.push('/user-dashboard/refs');
		this.loading = false;
		this.needLogin = true	
		this.homeLogin = false
	},
	async refsLogin(){
        this.loading = true;
		this.error = ""
		let email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
		let password = (this.password.length > 4) ? true : false;
		if (!email) this.error = "Please provide Valid email"
		if (!password) this.error = "Password must be at least 5 Characters"
		if (this.error.length) {
            this.loading = false
		}else{
			let dec = await this.$store.dispatch("refsLogin", {email: this.email, password: this.password, masterUserId: this.masterUserId}) 
			if (dec.status){ 				
				localStorage.setItem('globalAcc', "true")
				localStorage.setItem('globalAccUser', dec.user._id)
				this.globalAccUser = dec.user._id;
				this.$store.dispatch("refsUserDataAPI");
				this.loading = false;
				this.needLogin = false	
				this.homeLogin = true			
			}else{
				this.error = dec.message
				this.loading = false
			}
		}
	}
},
mounted(){
	this.$store.dispatch("getUser");
    this.$store.dispatch("getRefs");
    if (this.globalAccUser) {
		this.homeLogin = true;
		this.needLogin = false;
    	this.$store.dispatch("refsUserDataAPI");
    } else {
		this.homeLogin = false;
		this.needLogin = true;
    }
	
	this.updateDays();
	this.populateYears();
},
data() {
	return {
		globalAccUser: localStorage.getItem("globalAccUser"),
		homeLogin: false,
		needLogin: true,
		masterUserId: localStorage.getItem('VestigoUser'),
		isActive: 'accounts', 
		loading: false,
		message: [],
		error: [],
		email: '',
		password:'',
		months: {
			1: "January", 2: "February", 3: "March", 4: "April", 5: "May", 6: "June", 7: "July", 8: "August", 9: "September", 10: "October", 11: "November", 12: "December"
		},
		selectedMonth: (new Date()).getMonth() + 1,
		availableYears: [],
		selectedYear: (new Date()).getFullYear(),
	}
},
methods: {
    toggleActive(section) {
      this.isActive = section;
    },
	async updateDays() {
		const year = (new Date()).getFullYear(); // Get current year
		const month = parseInt(this.selectedMonth); // Get selected month
		const daysInMonth = new Date(year, month, 0).getDate(); // Get number of days in the selected month
		this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1); // Generate array of days 
		this.$store.dispatch("getUser")
	},

	async populateYears() {
		//const currentYear = new Date().getFullYear();
		for (let i = 0; i <= 10; i++) {
			this.availableYears.push(this.selectedYear - i);
		}
	}
	// logout(){
	// 	this.$router.push({path: '/user-dashboard/refs'})
	// 	//this.$store.dispatch("logout")
	// 	//this.$store.dispatch("resellerLogout")
	// },
},


}
</script>

<style>
.hidden {
  display: none;
}
.visible {
  display: block;
}

/* global account  */
.global-accounts-wrapper {
  padding: 10px 15px;
}

.global-accounts-wrapper .wel-note {
  padding: 10px 0;
}

.wel-note h1,
.wel-note h2 {
  margin-bottom: 10px;
  text-align: center;
}

.wel-note h1 {
  font-weight: bold;
  font-size: 16px;
  color: #444444;
}

.wel-note h2 span,
.RR-id span {
  color: #fd5b4e;
}

.RR-id span {
  font-weight: 600;
}

.account-and-order-wrapper{
	position: relative;
}

.tap-button-wrap {
  display: flex;
  padding: 0 15px;
  background: #fd5b4e;
  color: #ffffff;
  font-size: 14px;
  gap: 10px;
}

.tap-button-wrap .button-wrapper button,
.tap-button-wrap .button-wrapper button {
  padding: 7px 15px;
  color: #000;
  margin-top: 6px;
  border-radius: 8px 8px 0 0;
  background-color: #f9bcb884;
  color: #fff;
  border: none;
}

.tap-button-wrap .button-wrapper .active-btn {
  background-color: #fff;
  color: #444444;
}

.term-condition-wrap {
  margin: 20px 0;
}

.term-condition-wrap a {
  color: #fd5b4e;
}

.term-condition-wrap a:hover{
	color: #000;
}

.account-details{
	border: 1px solid #f1f1f1;
	position: relative;
	padding: 10px 15px;
}

.details-wrapper li {
    padding: 8px 0 0 8px;
}

.details-wrapper li p {
    margin: 0;
}

.cus-order-details-wrap{
	width: 100%;
	padding: 10px 15px;
}

table.cus-order.table{
	border: 1px solid #ddd;
	margin-top: 20px;
}

table.cus-order th{
	font-weight: 800;
}

.hidden {
    display: none;
}

.hidden {
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: opacity 0.5s ease-out, max-height 0.5s ease-out;
}

.visible {
    opacity: 1;
    max-height: 1000px;
    transition: opacity 0.5s ease-in, max-height 0.5s ease-in;	
}

.total-pricing-wrapper .total-pricing{
	display: flex;
	justify-content: flex-end;
}

.total-pricing-wrapper .total-pricing p{
	max-width: 140px;
    width: 100%;
	font-weight: 700;
}

.total-pricing-wrapper .total-pricing .total-value{
	margin-left: 10px;
	color: #fd5b4e;
	font-weight: 700;
	max-width: 50px;
    width: 100%;
    text-align: right;
}

.filterandsearch-wrapper{
	margin-top: 20px;
	display: flex;
	gap: 40px;
}

.search-box{
	position: relative;
    max-width: 300px;
	width: 100%;
}

.search-box input[type="search"]{
	-webkit-appearance: none;
    box-sizing: border-box;
    border: 1px solid #dada;
    padding: 5px 10px;
    width: 100%;
	border-radius: 4px;
	box-shadow: 0 0 5pt 0.5pt #D3D3D3;
	font-weight: 400;
	font-size: 14px;
}

.search-box .icon {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 35px;
  text-align: center;
  color: #644bff;
  cursor: pointer;
}

.search-box .icon svg{
	top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
}

.search-box input[type="search"]:focus,
.filter-wrapper select:focus{
	box-shadow: 0 0 5pt 2pt #D3D3D3;
	outline: none;
}

.search-box label {
    font-size: 14px;
    margin-right: 10px;
    margin-bottom: 0;
	font-weight: 400;
}

.filter-wrapper {
    display: flex;
    gap: 10px;
}

.filter-wrapper select{
	padding: 5px 10px;
	border-radius: 4px;
    box-shadow: 0 0 5pt 0.5pt #D3D3D3;
    font-weight: 400;
    font-size: 14px;
	border: 1px solid #dada;
}

.download-btn-wrap{
	display: flex;
	justify-content: flex-end;
}

.download-btn-wrap .download-btn {
	padding: 5px 10px;
    border-radius: 4px;
    border: none;
	background-color: #fd5b4e;
	color: #fff;
	font-size: 14px;
    letter-spacing: 0.5px;
	display: flex;
	align-items: center;
}

.download-btn-wrap .download-btn svg{
	margin-left: 5px;
}
</style>
<style scoped>
.modal-mask {
  position: fixed;
  z-index: 999899989998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 385px;
  margin-left: auto;
    margin-right: auto;
  /* margin: 0px auto; */
  padding: 1px 0px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.g-signin-button {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
}
</style>
