<template>
    
    <div>
        <nav-bar></nav-bar>
        <main class="main" style="margin-top:86px;" >

          <div>
            <h1 class="text-center fs3">Artwork Guidelines</h1>
            <!-- <p class="text-center fs2"> <b> Order business cards online with us. It’s simple and easy. </b> </p> -->
            <p class="p-items">
              1. File Format <br><br>
              2. Colour Mode <br><br>
              3. Printing Resolutions <br><br>
              4. Paper Sizes and Bleeds <br><br>
              5. Head Position <br><br>
              6. Font Online <br><br>
              7. Colour Reproduction <br><br>
              8. Responsibility <br><br>
              9. Common Instructions <br><br>

            </p>
          </div>
</main><!--Main index : End-->
        <div style="position:relative;">
            <footer-section></footer-section>	
        </div>
    </div>
    </template>
    
    <script>
    import NavBar from "@/components/NavBar.vue"
    import FooterSection from "@/components/FooterSection.vue"
    import {mapState} from 'vuex'
    export default {
    components:{NavBar, FooterSection},
    computed:{
    },
    mounted(){
    }
    }
    </script>
    
    <style scoped>
.fs3{
  font-size:6vw;
}
.fs2{
  font-size:2vw;
}
.p-items{
  font-size: 16px;
    padding-left: 5vw;
    margin-bottom: 100px;
}
    </style>