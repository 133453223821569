<template>
    
<div>
    <nav-bar></nav-bar>
	<main class="main">
		
		<section class="pr-main" id="pr-register">	
			<div class="container">	
				<div class="col-md-9 col-sm-9 col-xs-12">		
					<div class="col-md-6 col-sm-6 col-xs-12 left">
						<h1>Email Verification</h1>
						Please Provide the Verification Code received VIA email
						<input type="text" name="" placeholder="Code" v-model="verifCode" id="" class="form-control" style="margin-bottom: 5px;">
						<button class="btn btn-primary form-control" :disabled="verifCode.length !== 6" @click='verify'>Submit Verification Code</button>
						<span class="text-danger">If you did not received the email then please check spam or click <span class="link" @click="resend">Resend</span> </span>
						<br><span class="text-danger" v-if="message.length">{{ message }}</span>
					</div>
					
					
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12">
					<img src="/images/banner-wishlist.jpg" />
				</div>
				
			</div>
		</section>
	</main><!--Main index : End-->

	<div >
		<footer-section></footer-section>	
	</div>
</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue"
import FooterSection from "@/components/FooterSection.vue"
export default {
components:{NavBar, FooterSection},
computed:{
	email(){
		return this.$route.params.email
	},
	code(){
		return this.$route.params.code
	},
},

methods:{
	async verify(){
		this.loading = true
		this.message = '';
		let dec = await this.$store.dispatch("checkVerifCode", {code: this.verifCode, email: this.email, id: this.code})
		if (dec.status) {
			this.message = 'Thank you, you will be redirected to login'
			setTimeout(() => {
				this.$router.push({path:"/login"})				
			}, 2000);
		}else{
			this.message = dec.message;
			this.loading = false;
		}
	},
	async resend(){
		this.message = '';
		this.loading = true
		let dec = await this.$store.dispatch("sendVerifCode", {email: this.email})
		if (dec.status) {
		this.loading = false
			this.message = "Verification Code Has Been Sent"
		}else{
		this.loading = false
			this.message = "Failed to Send Verification code, please try again later or Contact Support"
		}
	},
	handle(){
		console.log('i am in handle')
	},
},
data(){
	return {
		loading: false,
		message:'',
		verifCode:''
		
	}
},
mounted(){
	setTimeout(() => {
		window.scrollTo(0,5);
		
	}, 500);
}
}
</script>

<style>
.ef-header{
	margin-bottom:62px;
}
.ef-image{
	height:345px;
	top:170px;
	position:fixed;
	width:100%;
	background-image: url('../../public/images/Online-Print-Shop-scaled.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.ab-desc{
    text-align: center;
    color: #FFFEFE !important;
    font-family: "Roboto", Sans-serif !important;
    font-size: 31px !important;
	position:relative !important;
	line-height:110px !important;
    font-weight: 400 !important;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 30%) !important;
}
.ab-heading {
	margin-top:55px;
    color: #FCFCFC !important;
    font-family: "Roboto", Sans-serif !important;
    font-size: 58px !important;
    font-weight: 600 !important;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
	border:none !important;
}

.tx-intro{
	text-align: center;
    font-family: "Poppins", Sans-serif;
    font-weight: 400;
    line-height: 27px !important;
}
.elementor-widget:not(:last-child) {
    margin-bottom: 20px;
}

.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.elementor-widget-wrap>.elementor-element {
    width: 100%;
}
.elementor-widget-wrap {
    position: relative;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: start;
    align-content: flex-start;
}
.elementor-section{background-image: url('../../public/images/Online-Print-Shop-scaled.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.link{
	color: inherit;text-decoration: underline;cursor:pointer;
}
</style>