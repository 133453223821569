<template>
	<div>
		<nav-bar></nav-bar>
		<main id="main" class="cart" v-if="ready">
			<span class="text-danger" v-if="error.length">{{ error }}</span>

			<section class="cart-content parten-bg" v-if="auth">
				<!-- ['user','userCart','userCartItems','userPCP','cartItem','orderItemUpdates','order'] -->
				<div class="container">

					<div class="row cart-header hidden-xs" style="margin-top:15px;">
						<div class="col-md-6 col-sm-10 cart-title" v-if="order !== null">
							<div v-for="item in order.items" :key="item._id">
								<div>
									<h1>Supplier Order Item Details</h1>
									<h3>{{ item.product ? item.product.productName : item.quote_id.quotesProduct }}</h3>
									<!-- <p class="flaot-left mb-1"> Product <span class="float-right"> <b> {{  }} </b> </span> </p> -->
									<p class="flaot-left mb-1"> Supplier Account Number : <span class="float-left"> <b> {{ item.product ?
										item.product.product.supplierAccountNumber : item.quote_id.supplierProductRef  }} <span
													class="text-disable"></span> </b> </span> </p>
									<p class="flaot-left mb-1"> Supplier Email : <span class="float-left"> <b> {{ item.product ?
										item.product.product.supplierEmail : item.quote_id.supplierEmail  }} </b> </span> </p>
									<p class="flaot-left mb-1"> Supplier Name : <span class="float-left"> <b> {{ item.product ?
										item.product.product.supplierName : item.quote_id.quotesProduct  }} </b> </span> </p>
									<p class="flaot-left mb-1"> Supplier Product Code : <span class="float-left"> <b> {{ item.product ?
										item.product.product.supplierProductCode : item.quote_id.quoteNumber }} </b> </span> </p>
									<p class="flaot-left mb-1"> Product Process : <span class="float-left"> <b> {{ item.product ? (item.product.product.nonPrint ? item.product.product.nonPrint : "Print") : item.quote_id.productProcess }}
											</b> </span> </p>

								</div>
							</div>
							<h1 v-if="order.status == 'Order Cancelled'" style="color: red;"> <b>NOTE: ORDER HAS BEEN
									CANCELLED</b> </h1>
						</div>


					</div><!-- Cart title : End -->
					<div class="row">
						<!--Cart main content : Begin -->
						<section class="col-md-9">
							<p> Order Item Actions </p>
							<p> Order Accepted:
								<span style="float: right;" v-if="order.supplierAccepted"> Accepted</span>
								<span style="float: right;" class="btn btn-success btn-sm" v-else @click="acceptOrder">
									Accept Order </span>
							</p>
							<br>
							<div v-if="order.supplierAccepted && (order.items[0].product ? order.items[0].product.product.nonPrint != 'Non Print' : order.items[0].quote_id.productProcess != 'Non Print')">
							<!-- <div v-if="order.supplierAccepted && order.items[0].product.product.nonPrint != 'Non Print'"> -->
								<p> <span class="atstatus">Artwork Status: {{ (order.artworkApproved) ? 'Approved' : 'NOT Approved' }}</span>
									<a id="downloadLink" style="display: none;"></a>
									<span v-if="cartItem.artwork.length">
										<a :href="$store.state.URL + '/uploads/artworks/' + cartItem.artwork"
											class="text-danger" target="_blank"><b>View Artwork</b></a> /
										<span class="link text-danger"
											@click="downloadFile($store.state.URL + '/uploads/artworks/' + cartItem.artwork, cartItem.artwork)">
											<b>Download Artwork</b></span>


									</span>
									<span v-else>Artwork will be displayed once uploaded</span>

									<span style="float: right;" v-if="order.artworkApproved"> Approved</span>
								</p>

								<div v-if="!order.artworkApproved">

									<p class="text-danger"
										v-if="order.artworkComment && order.artworkComment !== 'no Comments'">Artwork
										Rejected with reason: {{ order.artworkComment }}</p>
									<button class="btn btn-success d-block" @click="approveArtwork(true)"
										v-if="cartItem.artwork"> Approve Artwork </button>
									<b v-if="!order.artworkComment.length">OR</b><br>
									<label v-if="!order.artworkComment.length" for="">Please Provide Reason Before Rejecting
										Artwork</label>

									<div class="input-group"
										v-if="order.artworkComment.length < 1 || order.artworkComment == 'no Comments'">
										<input type="text" class="form-control" v-model="artworkUpdate">
										<span class="input-group-btn">
											<button class="btn btn-default" type="button" @click="updateArtwork(false)"
												:disabled="artworkUpdate.length < 3">Reject Artwork </button>
										</span>
									</div>

								</div>
								<div v-for="it in order.items" :key="it._id">
									<p>Artwork Samples/Attachments</p>
									<div v-if="it.artworkSamples && it.artworkSamples.length">
										<div v-for="(sample, index) in it.artworkSamples" :key="sample">
											<a style="color: blue;"
												:href="$store.state.URL + '/uploads/artworks-attach/' + sample"
												target="_blank" rel="noopener noreferrer">
												<b>{{ index + 1 }}: {{ sample }}</b>
											</a>
										</div>
									</div>
								</div>
							</div>
							<div v-else>
								<button class="btn btn-success d-block" @click="approveArtwork(true)" v-if="!order.artworkApproved && order.supplierAccepted"> Approve the Order </button>
							</div>



							<!-- <span style="float: right;" v-else> Click Here to Approve Artwork </span>  -->
							<!-- <p> Order Updates: </p> -->
							<!-- <label for="">Push updates/requests for Service Provider about order progress</label> -->
							<!-- <div class="input-group"> -->
							<!-- <input type="text" class="form-control" v-model="orderUpdate"> -->
							<!-- <span class="input-group-btn"> -->
							<!-- <button class="btn btn-default" type="button" @click="updateOrder" :disabled="orderUpdate.length < 3">Update</button> -->
							<!-- </span> -->
							<!-- </div> -->
							<!-- <p v-if="orderItemUpdates.length"> Previous Order Updates: </p> -->
							<!-- <p v-for="up in orderItemUpdates" :key="up._id"> <b> {{ up.update }} </b> <i> ({{ mm(up.createdAt) }})</i> </p> -->

						</section>
						<section class="cart-main col-md-9 col-xs-12">
							<!--Cart Item-->
							<h3 class="atstatus" v-if="order.status == 'Order Completed & Shipped'">Order has been marked
								as: Order Completed & Shipped </h3>
							<button class="btn btn-success form-control" v-if="order.status == 'Artwork Approved'"
								style="margin-top:9px;" @click="orderCompleted">
								Mark Order AS Order Completed & Shipped</button>
							<div v-if="order.notes.length">
								<i style="padding-right:20px;">Order Notes </i>: <b>{{ order.notes }}</b>
							</div>
							<p>Order Item Details</p>
							<div class="table-responsive">
								<table cellspacing="0" class="table-cart table">
									<thead class="hidden-xs">
										<tr>
											<th class="product-info" style="width: 55%;">
												{{ order.items[0].product 
													? (order.items[0].product.product.nonPrint == 'Non Print' ? 'Customer' : 'Product Specification') 
													: (order.items[0].quote_id.productProcess == 'Non Print' ? 'Customer' : 'Product Specification') 
												}}
<!-- 
												{{ order.items[0].product.product.nonPrint == 'Non Print' ? 'Customer' :
													'Product Specification' }} -->
											</th>
											<th class="product-info" style="width: 25%;">Order No.</th>
											<!-- <th class="product-price">Price</th> -->
											<th class="product-quantity">Qty</th>
											<!-- <th class="product-quantity">Delivery Turnaround</th> -->
											<!-- <th class="product-discount"> Discount</th> -->
											<!-- <th class="product-wishlist">Move to wishlist</th> -->
											<!-- <th class="product-subtotal">Total</th> -->
										</tr>
									</thead>
									<tbody>
										<tr class="cart_item">
											<template v-if="(order.items[0].product && order.items[0].product.product.nonPrint === 'Non Print') || (order.items[0].quote_id && order.items[0].quote_id.productProcess === 'Non Print')">
												<td>
													<div>
														<p class="flaot-left mb-1"> Customer Name : <span
																class="float-left"> <b> {{ order.user.name }} {{
																	order.user.lastName }}</b> </span> </p>
														<p class="flaot-left mb-1"> Customer Business Name : <span
																class="float-left"> <b> {{ order.user.accountTitle }} <span
																		class="text-disable"></span> </b> </span> </p>
													</div>
												</td>
											</template>
											<template v-else>
												<td class="product-info">
													<div class="product-image-col">
														<a class="product-image" title="product card">
															<img  :src="$store.state.URL+'/uploads/artworks/'+cartItem.artwork" style="max-width:150px;" v-if="cartItem.artwork">
															<img  :src="$store.state.URL+'/uploads/product/'+cartItem.product.image" style="max-width:150px;" v-else>

															<!-- <img :src="$store.state.URL + '/uploads/product/' + cartItem.product.image"
																alt="product card"> -->
														</a>
														<div class="product-action hidden-xs">
															<!-- <a href="#" class="cart-edit" title="Edit Product">
																  <i class="fa fa-pencil-square-o"></i>
														  </a> -->
															<!-- <a href="#" class="cart-delete" title="Delete Product">
																  <i class="fa fa-times"></i>
														  </a> -->
															<!-- <a href="#" class="cart-update" title="Update Product">
																  <i class="fa fa-refresh"></i>
														  </a> -->
														</div>
													</div>
													<div class="product-info-col">
														<h3 class="product-name">{{ cartItem.product ? cartItem.product.productName : cartItem.quote_id.quoteNumber }}</h3>
														<ul class="pro-option"  v-if="cartItem.opData">
															<li v-if="cartItem.opData.bookletPagination !== ''">
																<span class="pro-opt-label">Booklet Pagination: </span>
																<span class="pro-opt-value">{{
																	cartItem.opData.bookletPagination }}</span>
															</li>
															<li v-if="cartItem.opData.bookletType !== ''">
																<span class="pro-opt-label">Booklet Type: </span>
																<span class="pro-opt-value">{{ cartItem.opData.bookletType
																}}</span>
															</li>
															<li v-if="cartItem.opData.foldingOption !== ''">
																<span class="pro-opt-label">Folding Option: </span>
																<span class="pro-opt-value">{{ cartItem.opData.foldingOption
																}}</span>
															</li>
															<li v-if="cartItem.opData.printed !== 'None'">
																<span class="pro-opt-label">Print Sides: </span>
																<span class="pro-opt-value">{{ cartItem.opData.printed
																}}</span>
															</li>
															<li v-if="cartItem.opData.productPaperSizeFormat !== 'None'">
																<span class="pro-opt-label">Paper Size: </span>
																<span class="pro-opt-value">{{
																	cartItem.opData.productPaperSizeFormat }}</span>
															</li>
															<li v-if="cartItem.opData.productPaperTypeWeight !== 'None'">
																<span class="pro-opt-label">Paper Weight: </span>
																<span class="pro-opt-value">{{
																	cartItem.opData.productPaperTypeWeight }}</span>
															</li>

															<li class="product-price hidden-lg hidden-md hidden-sm">
																<span class="pro-opt-label">Price:</span>
																<span class="pro-opt-value" style="color: #25bce9;">&euro;
																	{{ cartItem.quantity.sellingProductPrice }}</span>
															</li>
														</ul>
														<ul class="pro-option" v-else>

															<li v-if="cartItem.quote_id !== ''">
																<span class="pro-opt-label">Quote ID No: </span>
																<span class="pro-opt-value">{{
																	cartItem.quote_id.quoteNumber }}</span>
															</li>

															<li v-if="cartItem.quote_id !== ''">
																<span class="pro-opt-label">Quote Description: </span>
																<span class="pro-opt-value">{{
																	cartItem.quote_id.suppliersDesription }}</span>
															</li>

															

														</ul>
													</div>
												</td>
											</template>

											<td>{{ order.orderNumber }}</td>

											<!-- <td class="product-price hidden-xs">
											  <span>&euro;  {{ (cartItem.price).toFixed(2) }}</span>
										  </td> -->
											<td class="product-quantity hidden-xs">
												<span>{{ cartItem.quantity.quantity }}</span>
												<!-- <input type="number"  v-model="cartItem.quantity" name="qty" id="qty" style="width: 100px;"> -->
											</td>
											<!-- <td>
											  {{ (cartItem.product.deliveryTurnaround) ? cartItem.product.deliveryTurnaround : "" }}
										  </td> -->
											<!-- <td class="product-discount hidden-xs"> 
											  &euro;  {{ 
												  (cartItem.discountPCP + cartItem.discountPromoCode
													   )
													   .toFixed(2) }}
											  <br><span v-if="cartItem.discountPCP"> PCP Applied: &euro; 
												  {{ (cartItem.discountPCP ).toFixed(2) }} 
											  </span>
											  <br><span v-if="cartItem.discountPromoCode"> Promotion Applied: &euro; 
												  {{ ( cartItem.discountPromoCode ).toFixed(2) }} </span>
										  </td> -->
											<!-- <td class="product-wishlist hidden-xs checkbox-w">
											  <input type="checkbox" id="addWishlist01" name="move-wishlist" checked>
											  <label for="addWishlist01" class="clearfix">
												  <i class="fa fa-square-o"></i>
												  <i class="fa fa-check-square-o"></i>
											  </label>
										  </td> -->
											<!-- <td class="product-subtotal hidden-xs">
											  <span>&euro; {{
												  ( cartItem.price - (cartItem.discountPCP + cartItem.discountPromoCode
													   ) ).toFixed(2)  }} </span>
										  </td> -->
										</tr>

									</tbody>
								</table>
							</div>
							<div v-if="order.deliveryAddress">
								<p>Order Delivery Address Info</p>
								<p> Address Line One <span style="float: right;">{{ order.deliveryAddress.addressOne
								}}</span> </p>
								<p> Address Line Two <span style="float: right;">{{ order.deliveryAddress.addressTwo
								}}</span> </p>
								<p> City <span style="float: right;">{{ order.deliveryAddress.city }}</span> </p>
								<p> County <span style="float: right;">{{ order.deliveryAddress.county }}</span> </p>
								<p> Country <span style="float: right;">{{ order.deliveryAddress.country }}</span> </p>
								<p> Postcode <span style="float: right;">{{ order.deliveryAddress.postCode }}</span> </p>
							</div>
							<div class="row cart-bottom">

								<!-- 						
						  <div class="col-sm-6 subtotal clearfix">
							  <h3>Sub total</h3>
							  <p>Checkout with Multiple Addresses</p>
							  <ul>
								  <li class="grand-total">
									  <span class="sub-title">Sub Total</span>
									  <span class="sub-value">&euro; {{
												  ( cartItem.price - (cartItem.discountPCP + cartItem.discountPromoCode
													   ) ).toFixed(2)  }} </span>
								  </li>
								  <li class="grand-total">
									  <span class="sub-title">VAT</span>
									  <span class="sub-value">&euro; {{ cartItem.vat.toFixed(2) }} </span>
								  </li><li class="grand-total">
									  <span class="sub-title">Grand Total</span>
									  <span class="sub-value">&euro; {{ (cartItem.total).toFixed(2) }} </span>
								  </li>
							  </ul>
						  </div> -->
							</div>

						</section><!-- Cart main content : End -->
						<!--Cart right banner: Begin-->
						<section class="col-sm-3 cart-right-banner hidden-sm hidden-xs">
							<div class="cart-right-banner">
								<a href="#" title="cart right banner">
									<img src="/images/banner/cart/cart-right-banner.jpg" alt="cart right banner">
								</a>
							</div>
						</section>
					</div>

				</div>
			</section>
			<section v-else style="margin: 55px 5px !important;">
				<div class="row">
					<div class="col-md-4 col-md-offset-4">

						<h3 style="font-size:24px;">VestiGo Supplier Authentication</h3>
						<p>You will need to provide the password provided in Order Notification email for just one time to
							Authenticate your device</p>
						<input type="password" v-model="password" name="" id="" placeholder="Password" class="form-control">
						<button class="btn btn-primary form-control" style="margin-top:10px;"
							@click="submitPassword">Submit</button>
					</div>
				</div>
			</section>
		</main><!-- Main Category: End -->
		<!--Footer : Begin-->
		<footer-section></footer-section>
	</div>
</template>
  
<script>
import moment from 'moment'
import NavBar from "@/components/NavBar.vue"
import FooterSection from "@/components/FooterSection.vue"
import { mapState } from 'vuex'
export default {
	components: { NavBar, FooterSection },
	data() {
		return {
			ready: false,
			auth: false,
			error: "",
			PROMOCODE: '',
			promoError: '',
			password: "",
			artworkUpdate: '',
			orderUpdate: "",
		}
	},
	computed: {
		...mapState(['user', 'userCart', 'userCartItems', 'userPCP', 'cartItem', 'orderItemUpdates', 'order']),
		// ((item.product.sellingProductPrice / item.product.quantity ) * item.quantity)
		// (( ((item.product.sellingProductPrice / item.product.quantity ) * item.quantity) * item.discountPCP ) / 100 )
		// (( ((item.product.sellingProductPrice / item.product.quantity ) * item.quantity) * item.discountPromoCode ) / 100 )

		total() {
			// if (this.userCartItems.length) {

			// 	let total = 0;

			// 	this.userCartItems.forEach(element => {
			// 		total += (((element.product.sellingProductPrice / element.product.quantity) * element.quantity)
			// 			-
			// 			(
			// 				((((element.product.sellingProductPrice / element.product.quantity) * element.quantity) * element.discountPCP) / 100)
			// 				+
			// 				((((element.product.sellingProductPrice / element.product.quantity) * element.quantity) * element.discountPromoCode) / 100)
			// 			))
			// 	});

			// 	return Number(total.toFixed(2))
			// }
			return 0;
		},
		VAT() {
			// if (this.userCartItems.length) {

			// 	let total = 0;

			// 	this.userCartItems.forEach(element => {
			// 		let totals = (
			// 			((element.product.sellingProductPrice / element.product.quantity) * element.quantity)
			// 			-
			// 			(
			// 				((((element.product.sellingProductPrice / element.product.quantity) * element.quantity) * element.discountPCP) / 100)
			// 				+
			// 				((((element.product.sellingProductPrice / element.product.quantity) * element.quantity) * element.discountPromoCode) / 100)
			// 			))
			// 		let tt = ((totals * element.product.supplierVAT / 100))
			// 		total += Number(tt)
			// 	});

			// 	return Number(total.toFixed(2))
			// }
			return 0;
		},
	},
	async mounted() {
		let user = localStorage.getItem("VestigoSupplier");
		if (user) {
			await this.$store.dispatch("getCartItem", { item: this.$route.params.item, order: this.$route.params.order })
			this.auth = true;
			this.ready = true

		} else {
			this.auth = false;
			this.ready = true
		}
		// await this.$store.dispatch("getPcps")
		// setTimeout(() => {
		// 	this.checkDiscounts()
		// }, 3000);
	},
	methods: {
		async orderCompleted() {
			await this.$store.dispatch("markOrderComplete", { order: this.$route.params.order })
			await this.$store.dispatch("getCartItem", { item: this.$route.params.item, order: this.$route.params.order })


		},
		downloadFile(fileUrl, fileName) {
			fetch(fileUrl)
				.then(response => response.blob())
				.then(blob => {
					const url = URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url;
					link.download = fileName;
					link.click();
					URL.revokeObjectURL(url);
				})
				.catch(error => console.error('Error:', error));
		},
		async acceptOrder() {
			console.log("accepting order")
			let dec = await this.$store.dispatch("acceptOrder", { item: this.$route.params.item, number: this.$route.params.order })
			console.log("response of accept/order", dec)
			if (dec.status) {
				this.$store.state.order.supplierAccepted = true
			}
		},
		async updateArtwork(status) {
			let dec = await this.$store.dispatch("updateArtwork", {
				title: "Artwork Rejected",
				status, update: this.artworkUpdate, item: this.$route.params.item, number: this.$route.params.order
			})
			if (dec.status) {
				this.$store.state.order.artworkApproved = false;
				this.$store.state.order.artworkComment = this.artworkUpdate;
				this.artworkUpdate = '';
			}
		},
		async approveArtwork(status) {
			console.log("approving artwork")
			let dec = await this.$store.dispatch("updateArtwork", {
				title: "Artwork Approved", status,
				update: "Artwork Was Approved By Supplier", item: this.$route.params.item, number: this.$route.params.order
			})
			console.log("result of approving artwork", dec)
			if (dec.status) {
				this.$store.state.order.artworkApproved = true;
				this.$store.state.order.artworkComment = 'Artwork Was Approved By Supplier';
				this.artworkUpdate = '';
				location.reload()
			}
		},

		mm(date) {
			return moment(date).fromNow()
		},
		async updateOrder() {
			let dec = this.$store.dispatch("addOrderUpdate", { update: this.orderUpdate, item: this.$route.params.item, order: this.$route.params.order })
			if (dec.status) {
				this.orderUpdate = '';
			}
		},
		async submitPassword() {
			this.error = ''
			let dec = await this.$store.dispatch("verifySupplier", { password: this.password, item: this.$route.params.item, order: this.$route.params.order });
			if (dec.status && dec.item) {
				localStorage.setItem('VestigoSupplier', true);
				this.$store.state.cartItem = dec.item
				this.$store.state.order = dec.order
				this.auth = true;
			} else {
				this.auth = false;
				this.error = dec.message;
			}

		},
		handleQtyChange(index) {
			let num = Number(this.$store.state.userCartItems[index].quantity)
			console.log(num)
			if (num) {
				this.$store.state.userCartItems[index].price = ((this.$store.state.userCartItems[index].product.sellingProductPrice / this.$store.state.userCartItems[index].product.quantity) * num)
			}

		},
		async proceedCheckout() {
			let cartItems = [];
			this.userCartItems.forEach(element => {
				cartItems.push({
					id: element._id,
					discountPCP: element.discountPCP,
					discountPromoCode: element.discountPromoCode,
					promoCode: element.promoCode,
					total: Number(this.total + this.VAT),
					quantity: Number(element.quantity),
				})
			});
			let dec = await this.$store.dispatch("updateCartItems", { items: cartItems })
			if (dec.status) {
				this.$router.push({ path: '/checkout' })
			}
		},
		async checkPromo() {
			this.promoError = ''
			let dec = await this.$store.dispatch("checkPromo", { code: this.PROMOCODE });
			if (dec.status) {
				console.log(dec.code)
				if (moment().isBetween(dec.code.startAt, dec.code.endAt)) {
					console.log("code is in valid date range")


					if (dec.code.discountOn == 'selected') {
						console.log("userPCP is selected")
						dec.code.products.forEach(element => {
							console.log("loop check on userPCP supported products")
							if (this.$store.state.userCartItems.length) {
								console.log("loop check on cart items started")
								this.$store.state.userCartItems.forEach((elm, index) => {
									console.log("loop check on cart items")
									if (elm.product._id == element) {
										if (elm.discountPromoCode) {
											this.promoError = 'Promotion Code Discount Already Applied On ' + elm.product.productName
										} else {
											// let dc = ((elm.price * dec.code.discount) / 100)
											// console.log("found match on cart item on userPCP supported item", dc)
											this.$store.state.userCartItems[index].discountPromoCode = dec.code.discount
											this.$store.state.userCartItems[index].promoCode = dec.code.code
										}

									}
								});
							}
						});
					} else {
						if (this.$store.state.userCartItems.length) {
							console.log("loop check on cart items started")
							this.$store.state.userCartItems.forEach((elm, index) => {
								console.log("loop check on cart items")
								// if (elm.product._id == element._id) {
								let dc = ((elm.price * dec.code.discount) / 100)
								console.log("found match on cart item on userPCP supported item", dc)
								this.$store.state.userCartItems[index].discountPromoCode = dc
								// }
								if (elm.discountPromoCode) {
									this.promoError = 'Promotion Code Discount Already Applied On ' + elm.product.productName
								} else {
									// let dc = ((elm.price * dec.code.discount) / 100)
									// console.log("found match on cart item on userPCP supported item", dc)
									this.$store.state.userCartItems[index].discountPromoCode = dec.code.discount
									this.$store.state.userCartItems[index].promoCode = dec.code.code
								}
							});
						}
					}


				} else {
					this.promoError = "The Code is not Useful"
				}
			} else {
				this.promoError = dec.message;
			}
		},
		async checkDiscounts() {
			console.log("check discount triggered")
			await this.$store.dispatch("getPcps")

			if (this.userPCP !== null) {
				if (moment().isBetween(this.userPCP.startAt, this.userPCP.endAt)) {
					console.log("found user PCP")
					if (this.userPCP.discountOn == 'selected') {
						console.log("userPCP is selected")
						this.userPCP.products.forEach(element => {
							console.log("loop check on userPCP supported products")
							if (this.$store.state.userCartItems.length) {
								console.log("loop check on cart items started")
								this.$store.state.userCartItems.forEach((elm, index) => {
									console.log("loop check on cart items")
									if (elm.product._id == element._id) {
										// let dc = ((elm.price * this.userPCP.discount) / 100)
										// console.log("found match on cart item on userPCP supported item", dc)
										this.$store.state.userCartItems[index].discountPCP = this.userPCP.discount
									}
								});
							}
						});
					} else {
						if (this.$store.state.userCartItems.length) {
							console.log("loop check on cart items started")
							this.$store.state.userCartItems.forEach((elm, index) => {
								console.log("loop check on cart items")
								// if (elm.product._id == element._id) {
								let dc = ((elm.price * this.userPCP.discount) / 100)
								console.log("found match on cart item on userPCP supported item", dc)
								this.$store.state.userCartItems[index].discountPCP = this.userPCP.discount
								// }
							});
						}
					}
				}

			} else {
				console.log("userPCP found NULL")
			}
		}
	},
}

</script>
  
<style>
.mapouter {
	position: relative;
	text-align: right;
	height: 100%;
	width: 100%;
}

.gmap_canvas {
	overflow: hidden;
	background: none !important;
	height: 100%;
	width: 100%;
}

.link {
	cursor: pointer;
}

.atstatus {
	background: #282827;
	color: #FFF;
	font-weight: 600;
	padding: 9px 11px;
}</style>