<template>
  <div>
    <nav-bar></nav-bar>
    <main class="main">
	
		<section id="pr-contact" class="pr-main">
			<div class="container"><h1 class="ct-header">Contact us</h1></div>
			<div class="contact-map">
			<!--<img src="images/maps.jpg" />-->
			<!-- <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="100%" id="gmap_canvas" src="https://maps.google.com/maps?q=600 Bloomfield Ave, Montclair, NJ 07042, USA&t=&z=10&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://2yu.co">2yu</a><br><a href="https://embedgooglemap.2yu.co/">html embed google map</a></div></div> -->
				<iframe width="100%" height="500" frameborder="0" src="https://maps.google.com/maps?q=600 Bloomfield Ave, Montclair, NJ 07042, USA&t=&z=10&ie=UTF8&iwloc=&output=embed" marginwidth="0" marginheight="0" scrolling="no"></iframe>		
			</div>
			
			<div class="container">
				<div class="col-md-3 col-sm-3 col-xs-12">
					<div class="address">
						<i class="fa fa-home"></i>
						<p>
						<span>Stress: {{ $store.state.contactUs.stress }}</span><br/>
						<span>Suburb: {{ $store.state.contactUs.subrub }}</span><br/>
						<span>State: {{ $store.state.contactUs.state }} </span><br/>
						<span>Zip Code: {{ $store.state.contactUs.zipCode }} </span><br/>
						<span>Country: {{ $store.state.contactUs.country }} </span>
						</p>
					</div>
					<div class="phone">
						<i class="fa fa-phone"></i>
						<p>
						<span>Telephone:  {{ $store.state.contactUs.tele }}</span>						
						</p>
					</div>
					
					<div class="fax">
						<i class="fa fa-fax"></i>
						<p>
						<span>Fax: {{ $store.state.contactUs.fax }} </span>						
						</p>
					</div>
					
					<div class="website">
						<i class="fa fa-globe"></i>
						<p>
						<span>Website: {{ $store.state.contactUs.web }} </span>						
						</p>
					</div>
				</div>
				<form id="contact-form" class="form-validate form-horizontal" method="post" action="#">
				<div class="col-md-6 col-sm-6 col-xs-12">
						<textarea aria-required="true" required="required" class="required invalid" rows="10" cols="50" id="jform_contact_message" name="contact_message" aria-invalid="true" >Message *</textarea>
					<p>Ask us a question and we'll write back to you promptly! Simply fill out the form below and click Send Email.</p>
					<p>Thanks. Items marked with an asterisk (<span class="star">*</span>) are required fields.</p>
				</div>
				
				<div class="col-md-3 col-sm-3 col-xs-12">
					<input class="name" type="text" value="Enter your name *">
					<input class="email" type="text" value="Enter E-mail *">
					<input class="mesage" type="text" value="Enter Mesage Subject *">
					<div class="button">
					<input class="subject" type="checkbox" value="Enter Mesage Subject *">
					<span>Send copy to yourself</span>
					</div>
					<button type="submit" class="sendmail">Submit</button>
				</div>
				</form>
			</div>
		</section>
	</main><!--Main index : End-->

    <footer-section></footer-section>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue"
import FooterSection from "@/components/FooterSection.vue"
export default {
components:{NavBar, FooterSection},
mounted(){
	console.log(this.$store.state.contactUs)
}
}

</script>

<style>
.mapouter{position:relative;text-align:right;height:100%;width:100%;}
.gmap_canvas {overflow:hidden;background:none!important;height:100%;width:100%;}
</style>