<template>
    
    <div>
        
    
        <nav-bar></nav-bar>
        <main id="main" class="account dashboard">
            
            <section class="account-content parten-bg">
                <div class="container-fluid" style="margin-top:15px;">
                
                    <div class="row acc-dashboard">
                        
                        <section class="account-main col-md-12 col-xs-12">
                            <div class="row">
                                
                                <div class="col-12">
                                    <dashboard-stats></dashboard-stats>
                                </div>
                                
                            </div>
                            <div class="row db-content pb-1"><button class="btn btn-primary float-right" style="margin: 4px;" :class="{'active-tag':path == '/user-dashboard/addresses'}"  @click="goTo(path, '/user-dashboard/add-users')">Add User</button></div>
                            <h3 class="acc-title lg">My Users List </h3>                           
                            <div class="row db-content">                            
                                <table class="invoice-table table">
                                    <thead>
                                        <tr>
                                            <th>User ID</th>
                                            <th>FIRST NAME</th>
                                            <th>LAST NAME</th>
                                            <th>USERNAME</th>
                                            <th>EMAIL</th>
                                            <th>STATUS</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="subUser in subChildUser" :key="subUser._id">
                                            <td>{{ subUser.userCode }}</td>
                                            <td>{{ subUser.name }}</td>
                                            <td>{{ subUser.lastName }}</td>
                                            <td>{{ subUser.username }}</td>
                                            <td>{{ subUser.email }}</td>
                                            <td>{{ subUser.isVerified ? 'Active' : 'Inactive' }}</td>
                                            </tr>

                                        <!-- <tr>
                                            <td>VC130</td>
                                            <td>User</td>
                                            <td>One</td>
                                            <td>userone</td>
                                            <td>userone@gmail.com</td>
                                            <td>Active</td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </section><!-- Cart main content : End -->
                    </div>
                    
                </div>
            </section>
        </main><!-- Main Category: End -->
        <!--Footer : Begin-->
        <div >
            <footer-section></footer-section>	
        </div>
    </div>
    </template>
    
    <script>
    // import VueHtml2pdf from 'vue-html2pdf'
    // import VueHtml2pdf from 'vue-html2pdf'
    // import html2pdf from "html2pdf.js";
    import jsPDF from 'jspdf'
    import NavBar from "@/components/NavBar.vue"
    import DashboardStats from "./DashboardStats.vue"
    import FooterSection from "@/components/FooterSection.vue"
    import {mapState} from 'vuex'
    export default {
    components:{DashboardStats,NavBar, FooterSection},
    methods:{
        goTo(from, to){
            if (from !== to) {
                this.$router.push({path: to})
            }
        },
    },
    computed:{
        ...mapState(['user', 'subChildUser']),

        // usOrders(){
        //     let ps = [];
        //     this.subChildUser.forEach(element => {
        //         if(element.items[0].product !== null)
        //         {
        //             ps.push(element)
        //         }
        //     });
        //     console.log(ps,"checkoin auseer");
        //     return ps;
        // },

        path(){
            return this.$route.path
        },
    
    },
    mounted(){
        console.log('SubChildUser:', this.subChildUser);
        // this.$store.dispatch("getOrdersUser")
        
    }
    }
    </script>
    
    <style>
    .invoice-table tr{ border: 1px solid lightgrey;}
    .invoice-table th{
        font-weight:800;
        padding:8px;
    }
    .invoice-table td{
        padding:8px;
    }
    .invoice-table img { cursor: pointer;padding:2px;}
    .invoice-table img:hover { border:1px solid red;}
    </style>