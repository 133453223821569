<template>
    
<div>
    

    <nav-bar></nav-bar>
	<main id="main" class="account dashboard">
		
		<section class="account-content parten-bg">
			<div class="container-fluid" style="margin-top:15px;">
			
				<div class="row acc-dashboard">
					
					<section class="account-main col-md-12 col-xs-12">
						<div class="row">
							
							<div class="col-12">
								<dashboard-stats></dashboard-stats>
							</div>
							
						</div>
							
						<h3 class="acc-title lg">My Invoices</h3>
						<div class="row db-content">
							<table class="invoice-table table">
								<thead>
									<tr>
										<th>Job ID</th>
										<th>Username</th>
										<th>Status</th>
										<th>Description</th>
										<th>Quantity</th>
										<th>Invoice</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="job in usOrders" :key="job._id">
										<td>{{ job.orderNumber }}</td>
									
										<td v-if="job.isSubChildID && job.isSubChildID.length > 0 && job.isSubChildID[0] != ''" >
												{{ job.isSubChildID[0].username ?  job.isSubChildID[0].username : job.user.username}}
										</td>
										<td v-else >{{ user.isSubChildUser ? user.username : job.user.username}}</td>
										<td>{{ job.status }}</td>
										<td>{{ getDesc(job.items) }}</td>
										<td>{{ getQty(job.items) }}</td>
										<td v-if="job.user.accountTerms > 0">
											<span v-if="job.status == 'Order Completed & Shipped'"> <img @click="generatePdf(job._id)" src="@/assets/pdf.png" style="max-width:22px;"> </span>
										</td>
										<td v-else>
											<span> <img @click="generatePdf(job._id)" src="@/assets/pdf.png" style="max-width:22px;"> </span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</section><!-- Cart main content : End -->
				</div>
				
			</div>
		</section>
	</main><!-- Main Category: End -->
	<!--Footer : Begin-->
	<div >
		<footer-section></footer-section>	
	</div>
</div>
</template>

<script>
// import VueHtml2pdf from 'vue-html2pdf'
// import VueHtml2pdf from 'vue-html2pdf'
// import html2pdf from "html2pdf.js";
import jsPDF from 'jspdf'
import NavBar from "@/components/NavBar.vue"
import DashboardStats from "./DashboardStats.vue"
import FooterSection from "@/components/FooterSection.vue"
import {mapState} from 'vuex'
export default {
components:{DashboardStats,NavBar, FooterSection},
methods:{
	getDesc(items){
		let desc = '';
		items.forEach(element => {
			if (element.product) {
				desc = desc+' '+element.product.productName+','+element.product.productDesc
			}
			else{
				desc = desc+' '+element.quote_id.quotesProduct+','+element.quote_id.suppliersDesription
			}
		});
		return desc;
	},
	generatePdf(job){
		const url = this.$store.state.URL+'/generate-pdf/'+job;
window.open(url, '_blank');
    },
	getQty(items){
		let desc = 0;
		items.forEach(element => {
			desc+=element.quantity.quantity
		});
		return desc;
	},
},
computed:{
	...mapState(['userOrders','user']),
	usOrders(){
		let ps = [];
		this.userOrders.forEach(element => {
			if(element.items[0].product !== null)
			{
				ps.push(element)
			}
		});
		return ps;
	},

},
mounted(){
	this.$store.dispatch("getOrdersUser")
	
}
}
</script>

<style>
.invoice-table tr{ border: 1px solid lightgrey;}
.invoice-table th{
	font-weight:800;
	padding:8px;
}
.invoice-table td{
	padding:8px;
}
.invoice-table img { cursor: pointer;padding:2px;}
.invoice-table img:hover { border:1px solid red;}
</style>