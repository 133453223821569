<template>
    
    <div>
        <nav-bar></nav-bar>
        <main class="main" style="margin-top:86px;" >
            <!-- <section class="home-promotion-product home-product parten-bg">
			<div class="container">
				<div class="row">
					<div class="block-title-w">
						<h2 class="block-title">Promotions Products</h2> 
						<span class="icon-title">
							<span></span>
							<i class="fa fa-star"></i>
						</span>
					</div>
					<ul class="slider-w slider-owl">
						<li class="pro-item" v-for="pro in $store.state.products" :key="pro.id">
							<div class="product-image-action">
								<img :src="getImgUrlPro(pro.image)" :alt="pro.title">
								<div class="action">
									<button type="button" data-toggle="tooltip" data-placement="top" class="add-to-cart gbtn" title="Add to cart">
										<i class="fa fa-shopping-cart"></i> 
									</button>
									<a href="#" data-toggle="tooltip" data-placement="top" title="Wishlist" class="add-to-wishlist">
										<i class="fa fa-heart"></i>
									</a>
									<a href="#" data-toggle="tooltip" data-placement="top" title="compare" class="add-to-compare">
										<i class="fa fa-refresh"></i> 
									</a> 
									<a href="#" data-toggle="tooltip" data-placement="top" title="Quickview" class="quick-view">
										<i class="fa fa-eye"></i>
									</a>
								</div>
								<span class="product-icon sale-icon" v-if="pro.isSale">sale!</span>
								<span class="product-icon new-icon" v-if="pro.isNew">new!</span>

							</div>
							<div class="product-info">
								<a href="detail.html" title="product" class="product-name">{{pro.title}}</a> 
								<div class="price-box" v-if="pro.discount">
									<span class="old-price">$ {{pro.price}}</span>
									<span class="special-price">$ {{ (Number(pro.price) - Number(pro.discount)).toFixed(2) }}</span> 
								</div>
								<div class="price-box" v-else>
									<span class="normal-price">$ {{ pro.price }}</span>
								</div>
								<div class="rating-box">  
									<i class="fa fa-star"></i>
									<i class="fa fa-star"></i>
								  	<i class="fa fa-star-half-o"></i>
								  	<i class="fa fa-star-o"></i>
								  	<i class="fa fa-star-o"></i>
								</div>
							</div>
						</li>
						
					</ul>
				</div>
			</div>
		</section> -->
		<!--Home New print Template : Begin -->
		<section class="home-new-product home-product parten-bg">
			<div class="container" v-if="services.length">
  <div class="card" v-for="(service, index) in services" :key="service._id">
    <div class="box">
      <div class="content">
        <h2 class="bronze">{{ index+1 }}</h2>
        <h3 class="bronze">{{ service.type }}</h3>
        <p class="bronze">{{ service.title }}</p>
        <!-- <a href="#" class="bronze-bg">Read More</a> -->
      </div>
    </div>
  </div>

</div>
		</section><!--Home New Products : End -->
		<!--Home blog : Begin -->        
        </main><!--Main index : End-->
        <div style="position:relative;">
            <footer-section></footer-section>	
        </div>
    </div>
    </template>
    
    <script>
    import NavBar from "@/components/NavBar.vue"
    import FooterSection from "@/components/FooterSection.vue"
    import {mapState} from 'vuex'
    export default {
    components:{NavBar, FooterSection},
    computed:{
      ...mapState(['servicesData','services'])
    },
        methods:{
	gtImgUrlBig(pet){
		var images = require.context('../../public/images/product/', false, /\.jpg$/)
    return images('./' + pet)
	},
	getImgUrl(pet) {
    var images = require.context('../../public/images/slider/home/', false, /\.jpg$/)
    return images('./' + pet)
  },
  getImgUrlPro(pet) {
    var images = require.context('../../public/images/product/263x263/', false, /\.jpg$/)
    return images('./' + pet)
  },
    },
    mounted(){
      this.$store.dispatch('getServices')
        setTimeout(() => {
            window.scrollTo(0,5);
            
        }, 500);
    }
    }
    </script>
    
    <style scoped>
    
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 100vh;
  background: #232427;
}

body .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  /* margin: 40px 0; */
  margin-left: auto;
    margin-right: auto;
}

body .container .card {
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 30px;
  transition: 0.5s;
}

body .container .card:nth-child(1) .box .content a {
  background: #2196f3;
}

body .container .card:nth-child(2) .box .content a {
  background: #e91e63;
}

body .container .card:nth-child(3) .box .content a {
  background: #23c186;
}

body .container .card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

body .container .card .box:hover {
  transform: translateY(-50px);
}

body .container .card .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

body .container .card .box .content {
  padding: 20px;
  text-align: center;
}

body .container .card .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

body .container .card .box .content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

body .container .card .box .content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

body .container .card .box .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
body .container .card .box .content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #000;
}
.bronze{
	color:#CD7F32 !important;
}
.bronze-bg{
	background:#CD7F32 !important;
}
.silver{
	color:#C0C0C0 !important;
}
.silver-bg{
	background:#C0C0C0 !important;
}
.gold{
	color:#D4AF37 !important;
}
.gold-bg{
	background:#D4AF37 !important;
}

    </style>