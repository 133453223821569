<template>
    <div>
        <nav-bar></nav-bar>
        <div>
            <dashboard-stats></dashboard-stats>
        </div>

        <div>
            <section class="cart-main col-xs-12" style="min-height: auto;">
                <main>
                    <h3 class="acc-title lg">Quotes</h3>
                </main>

                <div>
                    <table class="table-cart table" style="margin: auto;">
                        <tr>
							<th>Date of Quote</th>
                            <th style="padding: 0px 20px;">ID Quote Ref</th>
                            <th>Status</th>
                        </tr>
                        <tr v-for="order in userQuotes" :key="order._id">
							<td>{{ order.sendQuotesDate ? new Date(order.sendQuotesDate).toDateString() : '' }}</td>
                            <td class="link" @click="openModal(order)">{{ order.quoteNumber }}</td>
                            <td>{{ order.quoteStatus }}</td>
                        </tr>
                    </table>
                </div>
            </section>
            <!-- Cart main content : End -->
            <!--Cart right banner: Begin-->
            <footer-section></footer-section>
            <div class="modal-mask" v-if="orderDetails == true">
                <div class="modal-wrapper " v-if="subquotes != ''">
                    <div class="modal-container">
                        <div class="modal-body">
                            <button class="btn btn-danger close-btn" style="float: right;" @click="closeModal">
                                <b>&#10006;</b>
                            </button>
                            <h3 class="branch-title" style="text-align: center; font-weight: bold; font-size: 30px;" ><span class="icon icon-one"></span>Quotes Details</h3>
                            <br />
							<br />
							<div style="font-size: 20px;">
								<span class="col-lg-6"><strong>Quote ID No:</strong>  {{ selectedItem.quoteNumber }}</span>
								<span class="col-lg-6"><strong>Quote Date:</strong> {{ new Date(selectedItem.createdAt).toDateString() }}</span>
								<span class="col-lg-6"><strong>Quote Expiry Date:</strong>  {{ quoteExpiryDate }}</span>
								<span class="col-lg-6"><strong>Quote Validity </strong>  ({{ subquotes[0].validity }} Days)</span>
								<span class="col-lg-12"><strong>Description:</strong>  {{ subquotes[0].suppliersDesription }}</span><br>
							</div>
                            <div style="margin: 80px 0;">
                                <h3 class="text-left acc-title lg" style="margin: 5px;">Quantity Details</h3>
                                <br />
                                <table class="table-cart table" style="margin: auto;">
                                    <tr>
                                        <th>Quantity</th>
                                        <th>Price</th>
										<th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                    <tr v-for="order in subquotes" :key="order._id">
                                        <td>{{ order.quantity.quantity }}</td>
                                        <td>&euro;{{ order.quantity.sellingProductPrice ? $LFT(order.quantity.sellingProductPrice) : $LFT(order.quantity.supplierProductCostPrice + order.quantity.creditCardCost + order.quantity.markup) }}</td>
										<td>{{ order.quoteStatus }}</td>
										<td>
											<a :href="'/single-product/' + order._id" title="product" class="product-name">Order</a>
											<p v-if="order.quoteStatus == 'Expired'" ><a :href="'/quotes-form/'" title="product" class="product-name">Get New Quote</a></p>
										</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
				<div v-else>
					<div class="modal-container" style="margin: 452px 650px; width: 30%; height: 25%;">
                        <div class="modal-body">
                            <button class="btn btn-danger close-btn" style="float: right;" @click="closeModal">
                                <b>&#10006;</b>
                            </button>
                            <br />
                            <h3 class="branch-title" style="text-align: center; font-weight: bold; font-size: 30px;" ><span class="icon icon-one"></span>Quote on the Way</h3>
							
                            
                        </div>
                    </div>
				</div>
            </div>
        </div>
    </div>
</template>

	
	<script>
	import NavBar from "@/components/NavBar.vue"
	import DashboardStats from "./DashboardStats.vue"
	import FooterSection from "@/components/FooterSection.vue"
	import {mapState} from 'vuex'
	export default {
	components:{DashboardStats,NavBar, FooterSection},
	methods:{
		async addToCart(){
			this.error = ''
		
			let fileName = (this.allFiles !== null && this.needArtwork) ? await this.startUpload() : null;
			// if (this.product.product.productPaperSizeFormat.length  && this.q.productPaperSizeFormat.length < 1) this.error = "Please Select a Size"
			// if (this.product.product.productPaperTypeWeight.length  && this.q.productPaperTypeWeight.length < 1) this.error = "Please Select a Type Weight"
			// if (this.product.product.foldingOption.length  && this.q.foldingOption.length < 1) this.error = "Please Select a Folding Option"
			// if (this.product.product.bookletPagination.length  && this.q.bookletPagination.length < 1) this.error = "Please Select a Booklet Pagination"
			// if (this.product.product.bookletType.length  && this.q.bookletType.length < 1) this.error = "Please Select a Booklet Type"
			// return
			if (this.needArtwork) {
			if(!fileName) this.error = "Please Upload Artwork"	
			}
			if (this.orderQuantity !== null && this.error.length < 1) {
			let opData = this.selectedItem.opData
		
			// return
			 	let cartItem = {
					artwork:fileName,
					quote_id:this.selectedItem._id,
					// product: this.selectedItem.product._id,
					user: this.user._id,
					isPacakgeUsed: false,
					quantity: this.orderQuantity
				}
				// console.log(cartItem);
				// return
				this.$store.dispatch("updateQuoteItem", {q: this.selectedItem._id})
				let dec = await this.$store.dispatch("addCartItem", cartItem)
				if (dec.status) {
					this.cartSuccess()
				}
			}else{
				this.error = (this.error.length) ? this.error : "Please add any sufficient quantity/amount in item first";
			}
		},
		isExpired(d, v){
			d = new Date(d);
			d.setDate(d.getDate() + v)
			let today = new Date();
			if (today > d) {
				return true
			}else{
				return false
			}
			// return true

		},
		goTo(name){
			this.$router.push({name})
		},
		handle(event){
            this.images = []
            this.allFiles = []
            this.allFiles = event.target.files[0]
			console.log(event, this.allFiles)
			const reader = new FileReader();
                reader.readAsDataURL(this.allFiles);
                reader.onload = () => {
                    this.images.push(reader.result)
                };

            // this.allFiles.forEach((element) => {
                
            // })

        
        },
		async startUpload(){
			if(this.allFiles !== null){
				let name = Date.now()+this.allFiles.name
				const formData = new FormData();
            formData.append('encType', 'multipart/form-data');

		  formData.append('artworks', this.allFiles, name)
          console.log(formData)
		//   let DD = Date.now()
          let dec = await this.$store.dispatch("uploadFilesArtworks", formData )
		  return name
			}else{
				return null
			}

        },
		async cartSuccess(){
			// this.selectedPackage = null;
			// this.orderQuantity = null;
			// this.success = "Product Has Been Added to Cart";
			await this.$store.dispatch("getUser");
			location.replace("/checkout")
			// this.$store.state.uploadProgress = 0;
			// this.allFiles = null
		},
		async orderNow(order){
			let dec = await this.$store.dispatch("orderNow", order)
			if (dec.status) {
				
				alert("order placed")
			this.$store.dispatch("getUserQuotes");
			}else{
				alert("can't place the order")
			}
		},
		openModal(order) {
			this.orderDetails = true;
			this.selectedItem = order;
			this.$store.dispatch("getQuotesQuantity", order._id)
		},
		closeModal() {
			this.orderDetails = false;
			this.selectedItem = null;
		},
	},
	computed:{
		...mapState(['userQuotes','user','subquotes']),
		quoteExpiryDate() {
        if (this.selectedItem && this.selectedItem.createdAt) {
            let createdAt = new Date(this.selectedItem.createdAt);
            createdAt.setDate(createdAt.getDate() + this.selectedItem.validity);
            return createdAt.toDateString(); // Format as YYYY-MM-DD
        }
        return '';
    }
	},
	mounted(){
		this.$store.dispatch("getUserQuotes")
	},
	data() {
		return {
			selectedItem: null,
			files : [],
			orderQuantity:null,
            allFiles : null,
			error:"",
            images : [],
			needArtwork:true,
			tab:0,
			orderDetails: false,
		}
	}
	}
	</script>
	
	<style>
	.tabs{
		margin: 13px 0px;
		border-bottom: 1px solid lightgray;
		padding: 5px 0px;
	}
	.tab{
		color: red;
		padding: 5px 25px;
		cursor:pointer;
	}
	.selected{
		border-bottom: 4px solid red;
	}
	.link {
		color:blue; font-weight: 800;
	}
	.item-details .first {display: inline-block; width:160px;}
	.item-details{
		padding: 11px 4px;
		margin: 0px;
	}
	.sec {
		background: aliceblue;
	}
	.head {
		background: #fd5b4e;
	}
	.v-input{
		position: absolute;
		z-index: 9999;
		height: 100%;
		border: 2px solid red;
		width: 100%;
		opacity: 0;
	}
	.modal-mask {
		position: fixed;
		z-index: 999899989998;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: table;
		transition: opacity 0.3s ease;
	}

	.modal-wrapper {
		display: table-cell;
		vertical-align: middle;
	}

	.modal-container {
		width: 1200px;
		margin-left: auto;
		margin-right: auto;
		/* margin: 0px auto; */
		padding: 1px 0px;
		background-color: #fff;
		border-radius: 2px;
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
		transition: all 0.3s ease;
		font-family: Helvetica, Arial, sans-serif;
	}

	.modal-header h3 {
		margin-top: 0;
		color: #42b983;
	}

	.modal-body {
		margin: 0px 0;
	}

	.modal-default-button {
		float: right;
	}

	/*
	* The following styles are auto-applied to elements with
	* transition="modal" when their visibility is toggled
	* by Vue.js.
	*
	* You can easily play with the modal transition by editing
	* these styles.
	*/

	.modal-enter {
		opacity: 0;
	}

	.modal-leave-active {
		opacity: 0;
	}

	.modal-enter .modal-container,
	.modal-leave-active .modal-container {
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
	}

	.close-btn {
		padding: 3px 7px;
		font-size: 12px;
		line-height: 1.5;
		border-radius: 3px;
	}
	/* .c-table > tr > th{ padding:10px; font-weight:600; font-size:14px;} */
	/* .c-table > tr > td{ padding:10px; font-size:14px;} */
	</style>