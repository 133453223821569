<template>
    
    <div>
        <nav-bar></nav-bar>
        <main id="main" class="account dashboard">
            
            <section class="account-content parten-bg">
                <div class="container-fluid" style="margin-top:15px;">
                
                    <div class="row acc-dashboard">
                        <section class="account-main col-md-12 col-xs-12">
                            <div class="row">
                                <div class="col-12">
                                    <h3 class="acc-title lg text-center"> WELLCOME VESTIGO LOGISTICS (DUBLIN) TO YOUR CUSTOMER AREA </h3>
                                </div>
                                <div class="col-12">
                                    <dashboard-stats></dashboard-stats>
                                </div>
                                
                            </div>
                                
                            <h3 class="acc-title lg">Users
                            </h3>
                            <div class="row db-content">
                                <div class="db-hello col-xs-12">
                                    <div class="pad-1015">
                                        <p class="hello-user">
                                            Hello, Vestigo User!                                            
                                        </p>
                                    
                                    </div>
                                </div>
                                    <div>
                                        <h3 class="text-center acc-title lg">Add User</h3>
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <div>
                                                    <label for="">Customer First Name</label>
                                                    <span><input type="text" name="" id="" placeholder="Customer Name" v-model="firstName" class="form-control"><small class="text-danger"></small></span>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div>
                                                    <label for="">Customer Last Name</label>
                                                    <span><input type="text" name="" id="" placeholder="Customer Last Name" v-model="lastName" class="form-control"><small class="text-danger"></small></span>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div>
                                                    <label for="">Business Name</label>
                                                    <span><input type="text" name="" id="" placeholder="Business Name" v-model="businessName" class="form-control"><small class="text-danger"></small></span>
                                                </div>
                                            </div>
                                            <div class="col-lg-4">
                                                <div>
                                                    <label for="">Customer Username</label>
                                                    <span><input type="text" name="" id="" placeholder="Username" v-model="username" class="form-control"><small class="text-danger"></small></span>
                                                </div>
                                                <span v-if="error === 'Username Already Used' " class="text-danger"> {{ error }} </span>
                                            </div>
                                            <div class="col-lg-4">
                                                <div>
                                                    <label for="">Customer Email</label><span><input type="email" name="" id="" placeholder="Customer Email" v-model="email" class="form-control"><small class="text-danger"></small></span>
                                                </div>
                                                <span v-if="error === 'Email Already Registered' " class="text-danger"> {{ error }} </span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <h3 class="text-left acc-title lg" style="margin: 5px;">Add Address</h3>
                                            <div class="col-lg-4">
                                                <label for=""> Address Line One</label>
                                                <input type="text" name="" id="" v-model="addLineOne" class="form-control">
                                            </div>
                                            <div class="col-lg-4">
                                                <label for=""> Address Line Two</label><input type="text" name="" id="" v-model="addLineTwo" class="form-control">
                                            </div>
                                            <div class="col-lg-4">
                                                <label for=""> City</label><input type="text" name="" id="" v-model="city" class="form-control">
                                            </div>
                                            <div class="col-lg-4">
                                                <label for=""> County</label><input type="text" name="" id="" v-model="county" class="form-control">
                                            </div>
                                            <div class="col-lg-4">
                                                <label for=""> Country</label><input type="text" name="" id="" v-model="country" class="form-control">
                                            </div>
                                            <div class="col-lg-4">
                                                <label for=""> Post Code</label><input type="text" v-model="postCode" name="" id="" class="form-control">
                                            </div>
                                            
                                        </div>
                                        <div class="col-12" style="margin: 8px;">
                                            <button class="btn btn-primary" @click="addUser"> Submit </button>
                                        </div> 
                                    </div>
                            </div>
                        </section><!-- Cart main content : End -->
                    </div>
                    
                </div>
            </section>
        </main><!-- Main Category: End -->
        <!--Footer : Begin-->
        <div >
            <footer-section></footer-section>	
        </div>
    </div>
    </template>
    
    <script>
    import NavBar from "@/components/NavBar.vue";
    import DashboardStats from "./DashboardStats.vue";
    import FooterSection from "@/components/FooterSection.vue";
    import { mapState } from 'vuex';
    
    export default {
      components: { DashboardStats, NavBar, FooterSection },
    
      computed: {
        ...mapState(['user', 'userAddresses']),
      },
    
      methods: {
        goTo(path) {
          location.replace(path);
        },
    
        async addUser() {
            this.error = '';
            let userData = {
              user: this.user,
              isParentID: this.user._id,
              name: this.firstName,
              lastName: this.lastName,
              businessName: this.businessName,
              username: this.username,
              email: this.email,
              password: this.user.password,
              isSubChildUser: true,
              accountTermsActive: true,
              hasAddress:(this.addLineOne.length && this.addLineTwo.length) ? true : false,
              address:{ addressOne: this.addLineOne,addressTwo: this.addLineTwo, city: this.city, county: this.county, country: this.country,postCode: this.postCode }
            };

            console.log(userData,"dataa comming ");
    
            let dec = await this.$store.dispatch("addUser", userData);
    
            if (dec.status) {            
                
           
              this.$store.dispatch("getUser");
              // Optionally, you can clear the form fields here
              this.firstName = '';
              this.lastName = '';
              this.businessName = '';
              this.username = '';
              this.email = '';
              this.password = '';
              this.addLineOne = '';
              this.addLineTwo = '';
              this.city = '';
              this.county = '';
              this.country = '';
              this.postCode = '';
              
              this.goTo('/user-dashboard/users');
            

            } else {
              this.error = dec.message;
            }
        },
    
      },
    
      data() {
        return {
          addLineOne: '',
          addLineTwo: '',
          city: '',
          county: '',
          country: '',
          postCode: '',
          firstName: '',
          lastName: '',
          businessName: '',
          username: '',
          email: '',
          password: '',
          error: '',
        };
      },
    
      mounted() {
        // Your mounted logic
      },
    };
    </script>
    
    
    <style>
    .link{
        text-decoration: underline;
    }
    .span-link{
        cursor: pointer;
        text-decoration: underline;
    }
    .span-link:hover{
    transition: all 200ms;
    font-weight:700;
    }
    .ddarker{
        font-weight: bold;
        color: #444444;
        font-size: 18px;
        padding-bottom: 10px;
        position: relative;
    }
    </style>