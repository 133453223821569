<template>
  <div class="row" style="margin:0px;">
							<div class="col-12">
								
								<div class="card card-3 link dop-down-tab" :class="{'active-tag':path == '/user-dashboard'}" @click="goTo(path, '/user-dashboard')">
                                    <div class="inner-card">
                                        <i class="fa fa-user c-icon"></i>
                                    </div>
                                    <div style="width:50%;display: inline-block;">
                                        <span class="c-text"> ACCOUNT <br> <span>VIEW</span> </span>
                                    </div>

                                    <!-- <div class="drop-accounts-types">
                                        <div class="details">
                                            <button id="admin-ac">Customer Account</button>
                                            <button id="Global-ac"  @click="goTo(path, '/user-dashboard/refs')">Global Accounts Details</button>
                                        </div>
                                    </div> -->
								</div>
                                <div class="card card-3 link" :class="{'active-tag':path == '/user-dashboard/invoices'}" @click="goTo(path, '/user-dashboard/invoices')">
                                    <div class="inner-card">
                                        <i class="fa fa-file-text c-icon"></i>
                                    </div>
                                    <div style="width:50%;display: inline-block;">
                                        <span class="c-text"> INVOICES <br> <span>VIEW</span> </span>
                                    </div>
								</div>
                                <div class="card card-3 link" :class="{'active-tag':path == '/user-dashboard/jobs'}" @click="goTo(path, '/user-dashboard/jobs')">
                                    <div class="inner-card">
                                        <i class="fa fa-info-circle c-icon"></i>
                                    </div>
                                    <div style="width:50%;display: inline-block;">
                                        <span class="c-text"> JOB STATUS <br> <span>VIEW</span> </span>
                                    </div>
								</div>
                                <div class="card card-3 link" :class="{'active-tag':path == '/user-dashboard/quotes'}" @click="goTo(path, '/user-dashboard/quotes')">
                                    <div class="inner-card">
                                        <i class="fa fa-file-text c-icon"></i>
                                    </div>
                                    <div style="width:50%;display: inline-block;">
                                        <span class="c-text"> QUOTES <br> <span>VIEW</span> </span>
                                    </div>
								</div>

                                <div v-if="!user.isSubChildUser" class="card card-3 link" :class="{'active-tag':path == '/user-dashboard/users'}" @click="goTo(path, '/user-dashboard/users')">
                                    <div class="inner-card">
                                        <i class="fa fa-users c-icon"></i>
                                    </div>
                                    <div style="width:50%;display: inline-block;">
                                        <span class="c-text">Multiple Users<br> <span>VIEW</span> </span>
                                    </div>
                                </div>
                                
                                <div class="card card-3 link" :class="{'active-tag':path == '/user-dashboard/addresses'}" @click="goTo(path, '/user-dashboard/addresses')">
                                    <div class="inner-card">
                                        <i class="fa fa-map-marker c-icon"></i>
                                    </div>
                                    <div style="width:50%;display: inline-block;">
                                        <span class="c-text"> ADDRESSES <br> <span>VIEW</span> </span>
                                    </div>
								</div>
							</div>
                            <div class="col-12">
								<div class="calculate-spend middle">
									<h3 class="spend-title lg text-center"> YOUR PRODUCTION OVERVIEW FOR</h3>
									<select class="form-control" style="width: 7%;" v-model="selectedMonth" @change="updateDays">
										<option v-for="(month, index) in months" :value="index">{{ month }}</option>
									</select>
									<select class="form-control" style="width: 7%;" v-model="selectedYear" @change="updateDays">
										<option v-for="year in availableYears" :key="year" :value="year">{{ year }}</option>
									</select>
								</div>	
							</div>
							<br>
							<div class="col-lg-4 col-sm-6 link" style="padding:0px 5px;" @click="$router.push({path:'/user-dashboard/jobs'})">
								<div class="bg-card-first">
								<div><span > {{ ordersJob.length }} </span>	</div>
								</div>
								<div class="bg-card-second" style="background-color: rgb(106, 225, 225)">
								<div><span> ORDERD <br> JOBS </span></div>
								</div>
							</div>
							<div class="col-lg-4 col-sm-6 link" style="padding:0px 5px;" @click="$router.push({path:'/user-dashboard/jobs'})">
								<div class="bg-card-first" >
								<div><span > {{ completedOrders.length }} </span>	</div>
								</div>
								<div class="bg-card-second" style="background-color: rgb(73, 190, 73);">
								<div><span> COMPLETED <br> JOBS </span></div>
								</div>
							</div>
							<!-- <div class="col-lg-3 col-sm-6 link" style="padding:0px 5px;" @click="$router.push({path:'/user-dashboard/jobs'})">
								<div class="bg-card-first" >
								<div><span > 0 </span>	</div>
								</div>
								<div class="bg-card-second" style="background-color: rgb(69, 118, 204)">
								<div><span> DUE TO DISPATCH TODAY </span></div>
								</div>
							</div> -->
							<div class="col-lg-4 col-sm-6 link" style="padding:0px 5px;" @click="$router.push({path:'/user-dashboard/jobs'})">
								<div class="bg-card-first" >
								<div><span > &euro;{{ completedOrdersSpend }} </span>	</div>
								</div>
								<div class="bg-card-second" style="background-color: rgb(148, 148, 234)">
								<div><span> TOTAL SPEND </span></div>
								</div>
							</div>
						</div>

</template>

<script>
import {mapState} from 'vuex'

export default {
    data(){
        return {
            months: {
            1: "January", 2: "February", 3: "March", 4: "April", 5: "May", 6: "June",
            7: "July", 8: "August", 9: "September", 10: "October", 11: "November", 12: "December"
            },
            selectedMonth: (new Date()).getMonth() + 1,
            availableYears: [],
            selectedYear: (new Date()).getFullYear(),
        }
    },
    computed:{
		...mapState(['userOrders','user']),
        path(){
            return this.$route.path
        },
        completedOrders(){
            if (this.userOrders) {
                
                let orders = this.userOrders.filter((order) => {
                    
                    const date = new Date(order.deliveryAt);
                    const delMonth = date.getMonth() + 1;
                    const delYear = date.getFullYear();				
                    return order.deliveryAt != null && delMonth == this.selectedMonth && delYear == this.selectedYear 
                });
                return orders;
            }else{
                return []
            }
	    },
        ordersJob(){
            if (this.userOrders) {
                
                let orders = this.userOrders.filter((order) => {
                    
                    const date = new Date(order.createdAt);
                    const delMonth = date.getMonth() + 1;
                    const delYear = date.getFullYear();				
                    return order.createdAt != null && delMonth == this.selectedMonth && delYear == this.selectedYear 
                });
                return orders;
            }else{
                return []
            }
	    },
        completedOrdersSpend(){
            if (this.completedOrders) {
                let orders = 0;
                this.completedOrders.forEach(element => {
                    element.items.forEach(elm => {
                        orders += ((elm.quantity.sellingProductPrice )) - ( elm.discountPCPAm + elm.discountPromoCodeAm + elm.spendDiscountAm ) 
                    });
                });
                
                return orders;
            }else{
                return 0
            }
        },
    },
    methods:{
        goTo(from, to){
            if (from !== to) {
                this.$router.push({path: to})
            }
        },
        async updateDays() {
            const year = (new Date()).getFullYear(); // Get current year
            const month = parseInt(this.selectedMonth); // Get selected month
            const daysInMonth = new Date(year, month, 0).getDate(); // Get number of days in the selected month
            this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1); // Generate array of days 
            this.$store.dispatch("getUser")
        },

        async populateYears() {
            //const currentYear = new Date().getFullYear();
            for (let i = 0; i <= 10; i++) {
                this.availableYears.push(this.selectedYear - i);
            }
        }
    },
    mounted(){
	    this.updateDays();
	    this.populateYears();
    }
}
</script>

<style>


.card {
  background: #fff;
  border-radius: 2px;
  display: inline-block;
  height: 100px;
  margin: 1rem;
  position: relative;
  width: 18%;
  box-sizing:border-box;
}

.card-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.card-3:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.active-tag {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22) !important;
}

.card-2 {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.card-3 {
  /* box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); */
  box-shadow: 0 0px 0px rgb(0 0 0 / 25%), 0 2px 10px rgb(0 0 0 / 22%);
}

.card-4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-5 {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}
.c-text{
	float: right;
    font-size: 16px;
    margin-right: 16%;
    margin-top: 12%;
    line-height: 18px;
}
.c-text span{
	color: lightgray;
	font-size:16px;
}
.c-icon{
	font-size: 55px;
    margin-left: 29%;
    color: #fd5b4e;
    margin-top: 16%;

}
.bg-card-first{
	display: inline-block;width:50%;background:lightgray;height:100px;
}
.bg-card-first > div, .bg-card-second > div  {
	width: 100%;
	height: 100%;
	display: table;
	text-align: center;
}
.bg-card-first > div > span, .bg-card-second > div > span {
	display: table-cell;
	vertical-align: middle;
	line-height: 17px;
    font-size: 18px;
} 
.bg-card-second{
	display: inline-block;width:50%;background:gray;height:100px;
}
.inner-card{
    width: 50%;
    display: inline-block;
    margin-top: 0%;
    height: 100%;
    background: aliceblue;
}
.link{cursor: pointer;}
.middle {
	background-color: rgb(31, 31, 191);
}
.calculate-spend {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 17px 0;
}
.calculate-spend select {
    margin: 0 5px;
}

.spend-title {
    /* / background: #fd5b4e; / */
    padding: 10px 15px;
    color: #ffffff;
    font-size: 16px;
    overflow: hidden;
}

.dop-down-tab{
    position: relative;
}

.dop-down-tab:hover .drop-accounts-types{
    visibility: visible;
    opacity: 1;
    height: 100%;
}

.dop-down-tab:hover .drop-accounts-types .details{
    visibility: visible;
    opacity: 1;
}

.dop-down-tab .drop-accounts-types{
    position: absolute;
    top: 100%;
    width: 100%;
    height: 0;
    z-index: 1;
    background-color: aliceblue;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all ease-in-out 0.3s;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22) !important;
    visibility: hidden;
    opacity: 0;
    padding: 0 15px;
}
.dop-down-tab .drop-accounts-types .details button{
    padding: 4px 15px;
    background-color: #fd5b4e;
    color: #fff;
    width: 100%;
    text-align: left;
    margin-bottom: 8px;
    font-weight: 600;
}

.dop-down-tab .drop-accounts-types .details button:last-child{
    margin-bottom: 0;
}
</style>