<template>
  <div>
    <nav-bar></nav-bar>

    <main class="main" id="product-detail">
		<!--Breadcrumb : Begin-->
		
		<section class="product-info-w">
			<div class="container">
				

				<div class="row">
					<div class="tab-content clearfix">
					    <div role="tabpanel" class="tab-pane active" id="features">
					    	<div class="product-image">
						    	<div class="col-sm-12 col-xs-12">
						    		<img :src="$store.state.URL+'/uploads/product/'+product.image" alt="ideal 1">
						    	</div>
								<div class="col-sm-12">
									
									<div>
											<label for="">Order Quantity </label><br>
											<button class="btn" @click="orderQuantity = q" :class="{'btn-success':(orderQuantity && orderQuantity.quantity == q.quantity), 'btn-light':(orderQuantity && orderQuantity.quantity !== q.quantity)}" v-for="q in product.quantityGroups" :key="q.quantity" style="margin: 1%;width: 48%;">
												{{ q.quantity }} Units for &euro; {{ Number(q.sellingProductPrice).toFixed(0) }} </button>
											<!-- <select name="" id="" class="form-control" v-model="orderQuantity">
												<option v-for="q in product.quantityGroups" :key="q.quantity" :value="q">
													{{ q.quantity }} For &euro;{{ q.sellingProductPrice }}
												</option>
											</select> -->
										<!-- <input type="number" name="" id="" v-model.number="orderQuantity" class="form-control"> -->

										</div>
										<a @click="goTo('HowToOrderOnline')"> <b>How to Order Online ? Click here to view</b> </a>
								</div>
						    </div>
						    <div class="product-shortdescript v-middle" v-if="product">
								<div class="col-sm-12 col-xs-12">
									<div class="" >
										<button class="btn btn-primary float-right" style="min-width: 150px;" @click="custom(product.product)"> Custom Product</button>
										<h3 style="margin-bottom:15px;">{{ product.productName }}</h3>

										<div class="col-12" style="margin-bottom:15px;">
											<div class="rating-box">  
												<i class="fa fa-star"></i>
												<i class="fa fa-star"></i>
												  <i class="fa fa-star-half-o"></i>
												  <i class="fa fa-star-o"></i>
												  <i class="fa fa-star-o"></i>
												  <span>(23 Customer Reviews)</span>
											</div>
										</div>
							    		<p>
											{{ product.product.productMktDesc }}
										</p>
										<div  style="height: 45px;">
											<label for="">Product Print Side</label>
											<button class="btn btn-success float-right" style="min-width: 150px;" >{{ product.printed }}</button>
										
										</div>
										
										<div v-if="product.productPaperSizeFormat.length" style="height: 45px;">
											<label for="">Product Paper Size Format</label>
											<button class="btn btn-success float-right" style="min-width: 150px;" >{{ product.productPaperSizeFormat }}</button>
										<!-- <select name="" id="" class="form-control" v-model="productPaperSizeFormat" style="margin-bottom:15px;">
                                            <option v-for="size in product.productPaperSizeFormat" :key="size" :value="size">{{ size }}</option>
										</select> -->
										</div>
										<div v-else>
										</div>
						
										<div v-if="product.productPaperTypeWeight.length" style="height: 45px;">
											<label for="">Product Paper Type Weight</label>
											<button class="btn btn-success float-right" style="min-width: 150px;" >{{ product.productPaperTypeWeight }}</button>

										<!-- <select name="" id="" class="form-control" v-model="productPaperTypeWeight" style="margin-bottom:15px;">
                                            <option v-for="ty in product.productPaperTypeWeight" :key="ty" :value="ty">{{ ty }}</option>
										</select> -->
										</div>
										<div v-else>
										</div>

										<div v-if="product.foldingOption.length" style="height: 45px;">
											<label for="">Folding Options</label>
										<!-- <select name="" id="" class="form-control" v-model="foldingOption" style="margin-bottom:15px;">
                                            <option v-for="ty in product.foldingOption" :key="ty" :value="ty">{{ ty }}</option>
										</select> -->
										<button class="btn btn-success float-right" style="min-width: 150px;" >{{ product.foldingOption }}</button>

										</div>
										<div v-else>
											<!-- <b>Folding Options:</b> <i>NOT SUPPORTED</i>  -->
										</div>
										

										<div v-if="product.bookletType.length" style="height: 45px;">
											<label for="">Booklet Type</label>
										<!-- <select name="" id="" class="form-control" v-model="bookletType" style="margin-bottom:15px;">
                                            <option v-for="ty in product.bookletType" :key="ty" :value="ty">{{ ty }}</option>
										</select> -->
										<button class="btn btn-success float-right" style="min-width: 150px;" >{{ product.bookletType }}</button>

										</div>
										<div v-else>
											<!-- <b>Booklet Type:</b> <i>NOT SUPPORTED</i>  -->
										</div>

										<div v-if="product.bookletPagination.length" style="height: 45px;">
											<label for="">Booklet Pagination</label>
										<!-- <select name="" id="" class="form-control" v-model="bookletPagination" style="margin-bottom:15px;">
                                            <option v-for="ty in product.bookletPagination" :key="ty" :value="ty">{{ ty }}</option>
										</select> -->
										<button class="btn btn-success float-right" style="min-width: 150px;" >{{ product.bookletPagination }}</button>

										</div>
										<div v-else>
											<!-- <b>Booklet Pagination:</b> <i>NOT SUPPORTED</i>  -->
										</div>




                                        <div style="margin-bottom:15px;">
                                            <label for="" class="d-block">Lamination(Optional)</label>
                                            <div style="display:inline-block;width:50%;padding-right:2.5%;"> 
                                                <button class="btn" @click="lamination = 'WITHOUT LAMINATION'" :class="{'btn-success': lamination == 'WITHOUT LAMINATION','btn-light': lamination !== 'WITHOUT LAMINATION', }" style="border-radius:0px;width:97.5%;">WITHOUT LAMINATION</button> </div>
                                            <div style="display:inline-block;width:50%;padding-left:2.5%;"> 
                                                <button class="btn" @click="lamination = 'MATT LAMINATION'" :class="{'btn-success': lamination == 'MATT LAMINATION','btn-light': lamination !== 'MATT LAMINATION', }" style="border-radius:0px;width:100%;">MATT LAMINATION</button> </div>
                                        </div>
<!-- 
										<div v-if="product.printed == 'Optional'">
										    <label for="" class="d-block">Sides(Optional)</label>
                                            <div style="display:inline-block;width:50%;padding-right:2.5%;"> 
                                                <button class="btn" @click="side = 'SINGLE SIDE'" :class="{'btn-success': side == 'SINGLE SIDE','btn-light': side !== 'SINGLE SIDE', }" style="border-radius:0px;width:97.5%;">SINGLE SIDE</button> </div>
                                            <div style="display:inline-block;width:50%;padding-left:2.5%;"> 
                                                <button class="btn" @click="side = 'DOUBLE SIDE'" :class="{'btn-success': side == 'DOUBLE SIDE','btn-light': side !== 'DOUBLE SIDE', }" style="border-radius:0px;width:100%;">DOUBLE SIDE</button> </div>
                                        	
										</div> -->
										<!-- <div v-if="product.printed !== 'Disable'">
										    <label for="" class="d-block">Sides(Fixed)</label>
                                            <div style="display:inline-block;width:50%;padding-right:2.5%;"> 
                                                <span class="btn"  :class="{'btn-success': product.printed == 'Single Side','btn-light': product.printed !== 'Single Side', }" style="border-radius:0px;width:97.5%;">SINGLE SIDE</span> </div>
                                            <div style="display:inline-block;width:50%;padding-left:2.5%;"> 
                                                <span class="btn"  :class="{'btn-success': product.printed == 'Double Side','btn-light': product.printed !== 'Double Side', }" style="border-radius:0px;width:100%;">DOUBLE SIDE</span> </div>
										</div> -->
                                        <!-- <div style="margin-bottom:15px;">
                                            <label for="" class="d-block">Sides(Fixed)</label>
                                            <div style="display:inline-block;width:50%;padding-right:2.5%;"> 
                                                <button class="btn" :class="{'btn-success': product.printed == 'SINGLE SIDE','btn-light': side !== 'SINGLE SIDE', }" style="border-radius:0px;width:97.5%;">SINGLE SIDE</button> </div>
                                            <div style="display:inline-block;width:50%;padding-left:2.5%;"> 
                                                <button class="btn" :class="{'btn-success': product.printed == 'DOUBLE SIDE','btn-light': side !== 'DOUBLE SIDE', }" style="border-radius:0px;width:100%;">DOUBLE SIDE</button> </div>
                                        </div> -->
										
										<!-- <div> -->
											<!-- <label for="">Order Quantity </label><br> -->
											<!-- <button class="btn" @click="orderQuantity = q" :class="{'btn-success':orderQuantity.quantity == q.quantity, 'btn-light':orderQuantity.quantity !== q.quantity}" v-for="q in product.quantityGroups" :key="q.quantity" style="margin: 1%;width: 48%;"> -->
												<!-- {{ q.quantity }} Units for &euro; {{ q.sellingProductPrice }} </button> -->
											<!-- <select name="" id="" class="form-control" v-model="orderQuantity">
												<option v-for="q in product.quantityGroups" :key="q.quantity" :value="q">
													{{ q.quantity }} For &euro;{{ q.sellingProductPrice }}
												</option>
											</select> -->
										<!-- <input type="number" name="" id="" v-model.number="orderQuantity" class="form-control"> -->

										<!-- </div> -->
                                         <button class="btn btn-primary" style="margin:10px 0px;" v-if="needArtwork" @click="needArtwork = false">Need Support for Artwork</button>
                                         <button class="btn btn-primary" style="margin:10px 0px;" v-else @click="needArtwork = true">I Have Artwork to Upload</button>
										<div v-if="product.artworkInst" style="margin-top:10px;"> <b>Note:</b> {{ product.artworkInst }}</div>
                                        <div class="" style="padding:10px;border:2px solid lightgray;" v-if="needArtwork">
                                            <p> <b>Upload Your Artwork</b> </p>
                                            <p>You can email us the artwork to "artwork@discoverprint.ie" after completing the order with order number</p>
                                            <p>Please check our <a href="#" @click="goTo('ArtworkGuide')" style="font-weight:600; text-decoration: underline;">Artwork Guide</a> </p>
                                            <div style="border:1px dotted lightgray;padding:10px;position:relative;">
											<input type="file" name="" id="" class="v-input" @drop="drop"  @change="handle">

                                                <img src="/images/download.png" alt="" class="" srcset="" style="width:110px;display: block; margin-left:auto; margin-right:auto;">
                                                <p>Click here to choose the files or drag them here</p>
                                            </div>
											<div > 
                                        </div>
										<div v-if="$store.state.uploadProgress">
                                            <b>Uploading:</b> <span> {{ $store.state.uploadProgress }}% </span>
										</div>
										<div v-if="allFiles">
												{{ allFiles.name }}
										</div>
                                        </div>
										<div v-else>
									<h4>
										Email our Design Team with your order number after completing the order to
design@vestigoprint.com if you require design support. Our team will respond with design
price that begins @ €50.00 for basic artwork print support, and we will let you design pricing
for more complicated print artwork.
									</h4>
										</div>
										
							    	</div>
									<div v-if="!final">
										<h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> SUB TOTAL: 
											<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
											 <span v-if="orderQuantity">&euro; {{ orderQuantity.sellingProductPrice }}</span>
											 </h3>
											 <h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> +VAT({{ product.supplierVAT }}%): 
											<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
											 <span v-if="orderQuantity">&euro; {{  ((orderQuantity.sellingProductPrice * product.supplierVAT) / 100) .toFixed(0) }}</span>
											 </h3>
											 <h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> TOTAL: 
											<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
											 <span v-if="orderQuantity">&euro; {{ ( ((orderQuantity.sellingProductPrice * product.supplierVAT) / 100) + orderQuantity.sellingProductPrice  ).toFixed(0) }}</span>
											 </h3>	 
											 <h4 style="margin-bottom:0px;margin-top:5px;text-align:right;"> TOTAL UNITS: 
											<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
											 <span v-if="orderQuantity">{{ orderQuantity.quantity }}</span>
											 </h4>
									</div>
									<div v-else>
										<h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> SUB TOTAL: 
											<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
											 <!-- <span v-if="orderQuantity">&euro; {{ orderQuantity.sellingProductPrice }}</span> -->
											 </h3>
											 <h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> +VAT({{ product.supplierVAT }}%): 
											<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
											 <!-- <span v-if="orderQuantity">&euro; {{  ((orderQuantity.sellingProductPrice * product.supplierVAT) / 100) .toFixed(0) }}</span> -->
											 </h3>
											 <h3 style="margin-bottom:0px;margin-top:5px;text-align:right;"> TOTAL: 
											<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
											 <!-- <span v-if="orderQuantity">&euro; {{ ( ((orderQuantity.sellingProductPrice * product.supplierVAT) / 100) + orderQuantity.sellingProductPrice  ).toFixed(0) }}</span> -->
											 </h3>	 
											 <h4 style="margin-bottom:0px;margin-top:5px;text-align:right;"> TOTAL UNITS: 
											<!-- <span style="color:lightgray;text-decoration:line-through;">&euro;46</span> -->
											 <!-- <span v-if="orderQuantity">{{ orderQuantity.quantity }}</span> -->
											 </h4>
									</div>
									<button class="btn btn-success" v-if="user" :disabled="bVerify" @click="addToCart" style="border-radius:0px;width:100%;margin-top:5px;">ADD TO BASKET</button> 
									<div v-else>
    <button class="btn btn-primary form-control" @click="$store.state.showModal = true">Login/Regsiter</button>
                                       

										<!-- <a class="btn btn-success" href="/login"  style="border-radius:0px;width:100%;margin-top:5px;">Login</a>  -->
									</div>
									<p class="text-danger" v-if="error.length">{{ error }}</p>
									<p class="text-success" v-if="success.length">{{ success }}</p>

								</div>
							</div>
					    </div>
					    <div role="tabpanel" class="tab-pane" id="ideals">
					    	<div class="col-md-8 col-md-offset-2 col-xs-12 ideals-w">
					    		<div class="ideal">
					    			<img src="/images/img-paper.png" alt="ideal 1">
					    		</div>
					    		<div class="ideal">
					    			<img src="/images/product/details/ideal-2.jpg" alt="ideal 2">
					    		</div>
					    		<div class="ideal">
					    			<img src="/images/product/details/ideal-3.jpg" alt="ideal 3">
					    		</div>
					    		<div class="ideal">
					    			<img src="/images/product/details/ideal-4.jpg" alt="ideal 4">
					    		</div>
					    	</div>
					    </div>
					    <div role="tabpanel" class="tab-pane" id="paper">
					    	
                                                <div class="product-image v-middle">
						    	<div class="col-sm-12">
						    		<img src="/images/img-paper.png" alt="ideal 1" class="v-middle">
						    	</div>
						    </div>
					    </div>
					</div>
				</div>
			</div>
		</section>
	</main>
    
    <div style="position:relative;">
            <footer-section></footer-section>	
    </div>  
</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue"
import FooterSection from "@/components/FooterSection.vue"
import {mapState} from 'vuex';
export default {
	computed:{
		...mapState(['product','user']),
		final(){
			let error = ''
			if (this.product.productPaperSizeFormat.length  && this.productPaperSizeFormat.length < 1) error = "Please Select a Size"
			if (this.product.productPaperTypeWeight.length  && this.productPaperTypeWeight.length < 1) error = "Please Select a Type Weight"
			if (this.product.foldingOption.length  && this.foldingOption.length < 1) error = "Please Select a Folding Option"
			if (this.product.bookletPagination.length  && this.bookletPagination.length < 1) error = "Please Select a Booklet Pagination"
			if (this.product.bookletType.length  && this.bookletType.length < 1) error = "Please Select a Booklet Type"

			return error.length
		},
		bVerify(){
			if (this.orderQuantity == null) {
				return true
			}else{
                return false
			}
		},
		totalOrderPrice(){
			if (this.orderQuantity > 0) {
				return (this.product.sellingProductPrice / this.product.quantity) * Number(this.orderQuantity)
			}else{
				if (this.selectedPackage == null) {
					return 0
				}else{
					return this.selectedPackage.price
				}
			}
		},
		VAT(){
			if (this.totalOrderPrice > 0) {
				return ((this.totalOrderPrice*this.product.supplierVAT) / 100 )
			}else{
				return 0
			}
		},
		totalOrderUnits(){
			if (this.orderQuantity > 0) {
				return this.orderQuantity
			}else{
				if (this.selectedPackage == null) {
					return 0
				}else{
					return this.selectedPackage.quantity
				}
			}
		},
	},
    components:{NavBar, FooterSection},
	methods:{
		custom(product){
			console.log(product)
			this.$router.push({name:"CustomProduct", params:{id: product._id}})
		},
		goTo(name){
			this.$router.push({name})
		},
		handle(event){
            this.images = []
            this.allFiles = []
            this.allFiles = event.target.files[0]
			console.log(event, this.allFiles)
			const reader = new FileReader();
                reader.readAsDataURL(this.allFiles);
                reader.onload = () => {
                    this.images.push(reader.result)
                };

            // this.allFiles.forEach((element) => {
                
            // })

        
        },
		async startUpload(){
			if(this.allFiles !== null){
				const formData = new FormData();
            formData.append('encType', 'multipart/form-data');
		  formData.append('artworks', this.allFiles, this.allFiles.name)
          console.log(formData)
          let dec = await this.$store.dispatch("uploadFilesArtworks", formData )
		  return this.allFiles.name
			}else{
				return null
			}
            
        console.log(dec)
		  //   if(dec.status){
        //     this.files = [] ;
        //     this.allFiles = [];
        //     this.images = [];
        //   }
    // this.$store.dispatch("getProductImages")


        },
		async cartSuccess(){
			this.selectedPackage = null;
			this.orderQuantity = null;
			this.success = "Product Has Been Added to Cart";
			await this.$store.dispatch("getUser");
			location.replace("/checkout")
			// this.$store.state.uploadProgress = 0;
			// this.allFiles = null
		},
		async addToCart(){
			this.error = ''
			let fileName = (this.allFiles !== null && this.needArtwork) ? await this.startUpload() : null;
			if (this.product.productPaperSizeFormat.length  && this.productPaperSizeFormat.length < 1) this.error = "Please Select a Size"
			if (this.product.productPaperTypeWeight.length  && this.productPaperTypeWeight.length < 1) this.error = "Please Select a Type Weight"
			if (this.product.foldingOption.length  && this.foldingOption.length < 1) this.error = "Please Select a Folding Option"
			if (this.product.bookletPagination.length  && this.bookletPagination.length < 1) this.error = "Please Select a Booklet Pagination"
			if (this.product.bookletType.length  && this.bookletType.length < 1) this.error = "Please Select a Booklet Type"
			// return
			if (this.orderQuantity !== null && this.error.length < 1) {
			let opData = { 
			productPaperSizeFormat :this.productPaperSizeFormat,
			productPaperTypeWeight :this.productPaperTypeWeight,
			foldingOption :this.foldingOption,
			bookletPagination :this.bookletPagination,
			bookletType :this.bookletType,
			lamination: this.lamination,
			printed : (this.product.printed == "Optional") ? this.side : this.product.printed
		}
			 	let cartItem = {
					artwork:fileName,
					opData,
					product: this.product._id,
					user: this.user._id,
					isPacakgeUsed: false,
					quantity: this.orderQuantity
				}
				// console.log(cartItem);
				// return
				let dec = await this.$store.dispatch("addCartItem", cartItem)
				if (dec.status) {
					this.cartSuccess()
				}
			}else{
				this.error = (this.error.length) ? this.error : "Please add any sufficient quantity/amount in item first";
			}
		},
		drop(event){
			console.log(event)
		}
	},
	async mounted(){
		console.log(this.$route)
        await this.$store.dispatch("getProductBySlug",{slug: this.$route.params.product});
		if(this.product){
			this.orderQuantity = this.product.quantityGroups[0]
		}else{
			setTimeout(() => {
			this.orderQuantity = this.product.quantityGroups[0]
				
			}, 1000);
		}
	},
    data:() => {
        return {
			files : [],
            allFiles : null,
            images : [],
			needArtwork:true,
			productPaperSizeFormat:"",
			productPaperTypeWeight:"",
			foldingOption:"",
			bookletPagination:"",
			bookletType:"",
			error:"",
			success:"",
			orderQuantity:null,
			selectedPackage:null,
            lamination : 'MATT LAMINATION',
            side:"DOUBLE SIDE",
            quantity:[
      20,50,100,150,200,250,300,500,1000,2000,5000,10000,20000,30000,40000,50000,60000,70000,80000,90000,100000
        ],
            sizes:[
      "55x85mm",
      "DL (210x99mm)",
      "A7 (74x105mm)",
      "A6 (105x148mm)",
      "A5 (148x210mm)",
      "A4 (210x297mm)",
      "A3 (297x420mm)",
      "A2 (420x524mm)",
      "A1 (594x841mm)",
      "A0 (841x1189mm)"
      ],
	  bPTypes:[
        "8pp", "44pp",
        "12pp", "48pp",
        "16pp", "52pp",
        "20pp", "56pp",
        "24pp", "60pp",
        "28pp", "64pp",
        "32pp",
        "36pp",
        "40pp"
        ],
        bTypes:[
          "A5 Booklet Self Cover",
          "A5 Booklet with Heavy Cover",
          "A5 Booklet with Heavy Cover with Matt Lamination",
          "A5 Booklet with Heavy Cover with Gloss Lamination",
          "A4 Booklet Self Cover",
          "A4 Booklet with Heavy Cover",
          "A4 Booklet with Heavy Cover with Matt Lamination",
          "A4 Booklet with Heavy Cover with Gloss Lamination" 
        ],
	  fOptions:[
	  "A6 fold A7 (half fold)",
        "A5 fold A6 (half fold)",
        "198x210mm fold 99x210mm (half fold)",
        "A4 fold A5 (half fold) ",
        "6pp DL – (A4 roll fold DL) ",
        "6pp DL – (A4 Z fold DL) ",
        "210x396mm fold 210x198mm (half fold)",
        "210x396mm fold 210x99mm (Z fold)",
        "210x396mm fold 210x99mm (roll fold)",
        "A3 fold A4 (half fold) ",
        "A3 – A5 (cross fold)",
        "297x140mm (Z fold)",
        "297x140mm (roll fold)",
        "297x105mm (Z fold)",
        "297x105mm (roll fold)",
        "6pp A5 (roll fold)",
        "6pp A5 (Z fold)",
        "6pp A4 (roll fold)",
        "6pp A4 (Z fold)",
        "A2 fold A3 (half fold) ",
        "A2 to 210x99mm (map fold)"
	  ],
	  types:[
      "130gsm Silk",
      "130gsm Gloss",
      "120gsm Bond / Offset",
      "170gsm Silk ",
      "170gsm Gloss",
      "250gsm Gloss",
      "300gsm Bond",
      "300gsm Silk",
      "350gsm Silk",
      "400gsm Silk",
      "400gsm Silk with Matt lamination",
      "400gsm Silk with Gloss Lamination"
      ],
        }
    }

}
</script>

<style>
.v-input{
	position: absolute;
    z-index: 9999;
    height: 100%;
    border: 2px solid red;
    width: 100%;
    opacity: 0;
}
</style>