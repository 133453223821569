<template>
    
    <div>
        <nav-bar></nav-bar>
        <main class="main" style="margin-top:86px;" >

          <div>
            <h1 class="text-center fs3">How to Order Online</h1>
            <p class="text-center fs2"> <b> Order online with us. It’s simple and easy. </b> </p>
            <p class="p-items">
              1. Select the quantity you need and if either single of double-sided printing. The System will 
show you the final prices on the page ( ex-vat ) & Delivery.<br><br>
2. Place your Order <br><br>
3. Register your email address and password or sign in with your existing email address and 
password<br><br>
4. Move to checkout page and fill Billing and delivery details for the order.<br><br>
5. Use credit cards/ debits cards to complete the order. Once payment is done, you will get a 
confirmation email along with order details and an attached PDF Invoice. <br><br>
6. Upload your print ready design file using the upload option on the page. Please upload the 
design files in good quality PDF in size 85mm x 55mm with 3mm bleed. <br><br>
OR<br><br>
Choose our Design Template to help you create your own print ready artwork for FREE!
OR<br><br>
If you require your own bespoke business card design, contact our team @ 
design@vestigoprint.ie with your content, logo and design brief, we will undertake a full 
bespoke design for you at a competitively priced design price<br><br>
7. Also view our ‘Artwork Guidelines’ to help ensure artwork uploaded files are correct <br><br>
8. Our team will check the order and design files. If there is an issue with design files, we will 
contact you or else we will process the order.<br><br>
9. Delivery takes 3-4 workdays to print (from receipt of print ready artwork) and dispatch for 
next day delivery. Delivery is FREE nationwide 
            </p>
          </div>
</main><!--Main index : End-->
        <div style="position:relative;">
            <footer-section></footer-section>	
        </div>
    </div>
    </template>
    
    <script>
    import NavBar from "@/components/NavBar.vue"
    import FooterSection from "@/components/FooterSection.vue"
    import {mapState} from 'vuex'
    export default {
    components:{NavBar, FooterSection},
    computed:{
    },
    mounted(){
    }
    }
    </script>
    
    <style scoped>
.fs3{
  font-size:6vw;
}
.fs2{
  font-size:2vw;
}
.p-items{
  /* font-size: 2vw; */
  font-size: 16px;
    padding-left: 5vw;
    margin-bottom: 100px;
}
    </style>