<template>
    
	<div>
		<nav-bar></nav-bar>
		<div>
	<auth-comp @success="success" @fail="fail"></auth-comp>
		</div>
		<div >
			<footer-section></footer-section>	
		</div>
	</div>
	</template>
	
	<script>
	import NavBar from "@/components/NavBar.vue"
	import FooterSection from "@/components/FooterSection.vue"
	import AuthComp from "@/components/AuthComp.vue"
	export default {
	components:{NavBar, FooterSection, AuthComp},
	methods:{
		async login(){
			this.error = ""
			// var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
			let email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)
			let password = (this.password.length > 4) ? true : false;
			if (!email) this.error = "Please provide Valid email"
			if (!password) this.error = "Password must be at least 5 Characters"
			if (this.error.length) {
				console.log("val failed")
			}else{
			let dec = await this.$store.dispatch("login", {email: this.email, password: this.password}) 
			if (dec.status) {
				if (dec.user.isVerified) {
					localStorage.setItem('VestigoUser', dec.user._id)
					localStorage.setItem('VestigoUserToken', dec.token)
					location.reload()	
				}else{
					location.replace('/verify/'+dec.user.email+'/'+dec.user._id)
					
				}
				
			}else{
				this.error = dec.message
			}
			}
			// console.log(email)
			// let dec = await this.$store.dispatch("login", {email: this.email, password: this.password}) 
		},
	},
	watch:{
		
	},
	mounted(){
		this.$store.state.showModal = true;
	},
	methods:{
		success(){
			this.$router.push({path:"/user-dashboard"})
		},
		fail(){
			this.$router.push({path:"/"})
		},
	},
	data(){
		return {
			error:"Please provide Valid email",
			email:'',
			password:''
		}
	}
	}
	</script>
	
	<style>
	
	</style>