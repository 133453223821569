<template>
    
<div>
    <nav-bar></nav-bar>
	<main class="main">
		
		<section class="pr-main" id="pr-register">	
			<div class="container">	
				<div class="col-md-9 col-sm-9 col-xs-12">		
					<div class="col-md-6 col-sm-6 col-xs-12 left">
						<h1>Create an Account</h1>
						<h4>Personal Information</h4>
						{{ formValid }}
						<ValidationObserver ref="simpleRules">
							<p>First name <span class="star">*</span></p>
							<ValidationProvider name="First Name"  rules="names" v-slot="{ errors }">
							<input type="text" v-model="firstName" style="margin-bottom:3px;">
							<span class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
							<ValidationProvider name="Company"   v-slot="{ errors }">
							<input type="text" v-model="company" style="margin-bottom:3px;">
							<!-- <span class="text-danger">{{ errors[0] }}</span> -->
							</ValidationProvider>
							<p>Last name <span class="star">*</span></p>
							<ValidationProvider name="Last Name"  rules="names" v-slot="{ errors }">
							<input type="text" v-model="lastName" style="margin-bottom:3px;">
							<span class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
							<p>Username <span class="star">*</span></p>
							<ValidationProvider name="Username"  rules="names" v-slot="{ errors }">
							<input type="text" v-model="username" style="margin-bottom:3px;">
							<span class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
							<p>Email Address <span class="star">*</span></p>
							<ValidationProvider name="Email"  rules="required|email" v-slot="{ errors }">
							<input type="email"  v-model="email" style="margin-bottom:3px;">
							<span class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
							<p>Password <span class="star">*</span></p>
							<ValidationProvider name="Password"  rules="password" v-slot="{ errors }">
							<input type="password"  v-model="password" style="margin-bottom:3px;">
							<span class="text-danger" v-if="password">{{ errors[0] }}</span><br>
							<span class="text-danger" v-if="password !== confirmation">Password Must Be Confirmed</span>
							</ValidationProvider>
							<p>Confirm Password <span class="star">*</span></p>
							<ValidationProvider name="Password"  v-slot="{ errors }" vid="confirmation">
							<input type="password"  v-model="confirmation" style="margin-bottom:3px;">
							<span class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider>
							<!-- <p>Email Address <span class="star">*</span></p>
							<ValidationProvider name="email" rules="required|email" v-slot="{ errors }">
							<input v-model="email" type="text" style="margin-bottom:3px;">
							<span class="text-danger">{{ errors[0] }}</span>
							</ValidationProvider> -->
							<button @click="register">
							<span v-if="loading">Loading...</span>
							<span v-else>Register</span>
							</button>
							<span class="text-danger" v-if="message.length">{{ message }}</span>
						</ValidationObserver>
						
					</div>
					
				</div>
				<div class="col-md-3 col-sm-3 col-xs-12">
					<img src="/images/banner-wishlist.jpg" />
				</div>
				
			</div>
		</section>
	</main><!--Main index : End-->

	<div >
		<footer-section></footer-section>	
	</div>
</div>
</template>

<script>
import { extend } from 'vee-validate';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email, min, length } from 'vee-validate/dist/rules';

extend('names', value => {
  if (value.length > 3) {
    return true;
  }

  return 'This {_field_} field must be at least 3 characters long';
});
extend('password', value => {
  if ( value.length > 3 ) {
    return true;
  }

  return 'Password must be at least 5 characters long and must be confirmed';
});

extend('email', email);
extend('required', {
  ...required,
  message: 'This field is required'
});
import NavBar from "@/components/NavBar.vue"
import FooterSection from "@/components/FooterSection.vue"
export default {
components:{NavBar, FooterSection, ValidationProvider, ValidationObserver},

methods:{
	register(){
		this.$refs.simpleRules.validate().then(async(success) => {
			if (success) {
				if (this.password == this.confirmation) {
					this.loading = true
					let user = {
						password:this.password,
						name: this.firstName,
						lastName: this.lastName,
						username: this.username,
						email: this.email,
						isSuperUser: false
					}
					let dec = await this.$store.dispatch("registerUser", user)
					if (dec.status) {
						location.replace('/verify/'+this.email+'/'+dec.id)
					}else{
						this.message = dec.message;
						this.loading = false
					}
				}
			}
		})
		
	},
	handle(){
		console.log('i am in handle')
	},
},
data(){
	return {
		loading: false,
		mailSent:false,
		min,
		verifCode:'',
		length,
		email:'',
		username:'',
		firstName:'',
		company:"",
		lastName:'',
		password:'',
		confirmation:'',
		formValid:'',
		message:"",
	}
},
mounted(){
	setTimeout(() => {
		window.scrollTo(0,5);
		
	}, 500);
}
}
</script>

<style>
.ef-header{
	margin-bottom:62px;
}
.ef-image{
	height:345px;
	top:170px;
	position:fixed;
	width:100%;
	background-image: url('../../public/images/Online-Print-Shop-scaled.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.ab-desc{
    text-align: center;
    color: #FFFEFE !important;
    font-family: "Roboto", Sans-serif !important;
    font-size: 31px !important;
	position:relative !important;
	line-height:110px !important;
    font-weight: 400 !important;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 30%) !important;
}
.ab-heading {
	margin-top:55px;
    color: #FCFCFC !important;
    font-family: "Roboto", Sans-serif !important;
    font-size: 58px !important;
    font-weight: 600 !important;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
	border:none !important;
}

.tx-intro{
	text-align: center;
    font-family: "Poppins", Sans-serif;
    font-weight: 400;
    line-height: 27px !important;
}
.elementor-widget:not(:last-child) {
    margin-bottom: 20px;
}

.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.elementor-widget-wrap>.elementor-element {
    width: 100%;
}
.elementor-widget-wrap {
    position: relative;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: start;
    align-content: flex-start;
}
.elementor-section{background-image: url('../../public/images/Online-Print-Shop-scaled.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.link{
	color: inherit;text-decoration: underline;cursor:pointer;
}
</style>