<template>
	<div>
	   <nav-bar></nav-bar>
	   <main class="main">
		  <section id="checkout" class="pr-main">
			 <div class="container">
				<div class="cart-view-top">
				   <div class="col-md-6 col-sm-6 col-xs-12">
					  <h1>Checkout</h1>
				   </div>
				   <div class="col-md-6 col-sm-6 col-xs-12 right">
					  <h1>Continue Shopping</h1>
				   </div>
				   <div id="login-pane" class="col-md-12 col-sm-12 col-xs-12">
					  <p>Please fill in the fields below to complete your order.</p>
				   </div>
				</div>
				<div class="onepage">
				   <div  class="col-md-4 col-sm-6 col-xs-12">
					  <div v-if="!user.isSubChildUser" id="sub_customer_list">
						 <div class="pane round-box">
							<h3 class="title">
							   <span class="icon icon-one">1</span>
							   {{ ((user.billing == null &&  user.billing == undefined) || ((userAddresses.length === 1 || userAddresses.length == 0) && userAddresses.length <= 1)) ? 'MY CUSTOMER LIST' : 'MY Address LIST' }}
							</h3>
							<div class="pane-inner">
							   <select name="customer" id="customer" class="customer-list" v-model="selectedCustomer" @change="handleOptionChange">
								  <option value="default">Default</option>
								  <template v-if="(user.billing == null &&  user.billing == undefined) || (userAddresses.length === 1 || userAddresses.length == 0)">
									 <option v-for="subUser in subChildUser" :key="subUser._id" :value="subUser._id">{{ subUser.name }} {{ subUser.lastName }}</option>
								  </template>
								  <template v-else>
									 <option v-for="address in userAddresses" :key="address._id" :value="address._id" v-if="address._id !== user.address._id">{{ address.addressOne }} {{ address.addressTwo }}</option>
								  </template>
							   </select>
							   <div v-if="shouldShowDiv">
								  <p>Switch to branch/location.</p>
								  <div id="radio_wrapper" class="radio-btn">
									 <p>
										<input type="radio" name="parent_user" value="yes" v-model="selectedOption" @change="handleRadioChange" checked />No
									 </p>
									 <p>
										<input type="radio" name="parent_user" value="no" v-model="selectedOption" @change="handleRadioChange" />Yes
									 </p>
								  </div>
							   </div>
							</div>
						 </div>
					  </div>
					  <!-- <div v-if="user.isSubChildUser" id="branch_list">
						 <div class="pane round-box" >
							 <h3 class="title"><span class="icon icon-one">1</span>Switch To Branch/Location</h3>
								 
								 <div class="pane-inner">
									 <div id="radio_wrapper" class="radio-btn">
										 <p>
										 <input type="radio" name="sub_child_no" value="yes" v-model="selectedOption" @change="handleRadioChange" checked />No
										 </p>
										 <p>
										 <input type="radio" name="sub_child_no" value="no" v-model="selectedOption" @change="handleRadioChange" />Yes
										 </p>
									 </div>
								 </div>
							</div>
									</div>	 -->
					  <div id="div_billto">
						 <div class="pane round-box" v-if="vbill">
							<h3 class="title"><span class="icon icon-one">{{user.isSubChildUser ? 1 : 2 }}</span>BILLING INFO</h3>
							<div v-if="(user.billing == null &&  user.billing == undefined) || (userAddresses.length === 1 || userAddresses.length == 0)">
							   <div class="acc-info-content pad-1015" v-if="user.billing !== null &&  user.billing !== undefined">
								  <div>
									 <span style="display:inline-block;width:120px;">Address Line 1:</span>
									 <span style="display:inline-block;width:140px;" v-if="!user.isSubChildUser">
									 {{ selectedOption === 'no' ? 
									 (selectedparentOption === 'both' ? branchSwitch.billing.addressOne :
									 (selectedparentOption === 'deiveryInfo' ? user.billing.billAddressOne : 
									 (selectedparentOption === 'billInfo' ? branchSwitch.billing.addressOne : '')
									 )
									 )
									 : (user.billing.billAddressOne ? user.billing.billAddressOne : user.billing.addressOne)
									 }}													
									 </span>
									 <span style="display:inline-block;width:140px;" v-else>
									 {{ selectedOption === 'yes' ? branchSwitch.billing.addressOne : user.billing.addressOne }}												
									 </span>
								  </div>
								  <div>
									 <span style="display:inline-block;width:120px;">Address Line 2</span>
									 <span style="display:inline-block;width:140px;" v-if="!user.isSubChildUser">
									 {{ selectedOption === 'no' ? 
									 (selectedparentOption === 'both' ? branchSwitch.billing.addressTwo :
									 (selectedparentOption === 'deiveryInfo' ? user.billing.billAddressTwo : 
									 (selectedparentOption === 'billInfo' ? branchSwitch.billing.addressTwo : '')
									 )
									 )
									 : (user.billing.billAddressTwo ? user.billing.billAddressTwo : user.billing.addressTwo)
									 }}													
									 </span>
									 <span style="display:inline-block;width:140px;" v-else>	
									 {{ selectedOption === 'yes' ? branchSwitch.billing.addressTwo : user.billing.addressTwo }}												
									 </span>
								  </div>
								  <div>
									 <span style="display:inline-block;width:120px;">City/Town:</span>
									 <span style="display:inline-block;width:140px;" v-if="!user.isSubChildUser">
									 {{ selectedOption === 'no' ? 
									 (selectedparentOption === 'both' ? branchSwitch.billing.city :
									 (selectedparentOption === 'deiveryInfo' ? user.billing.billCity : 
									 (selectedparentOption === 'billInfo' ? branchSwitch.billing.city : '')
									 )
									 )
									 : (user.billing.billCity ? user.billing.billCity : user.billing.city)
									 }}
									 </span>
									 <span style="display:inline-block;width:140px;" v-else>	
									 {{ selectedOption === 'yes' ? branchSwitch.billing.city : user.billing.city }}												
									 </span>
								  </div>
								  <div>
									 <span style="display:inline-block;width:120px;">County:</span>
									 <span style="display:inline-block;width:140px;" v-if="!user.isSubChildUser">
									 {{ selectedOption === 'no' ? 
									 (selectedparentOption === 'both' ? branchSwitch.billing.county :
									 (selectedparentOption === 'deiveryInfo' ? user.billing.billCounty : 
									 (selectedparentOption === 'billInfo' ? branchSwitch.billing.county : '')
									 )
									 )
									 : (user.billing.billCounty ? user.billing.billCounty : user.billing.county)
									 }}
									 </span>
									 <span style="display:inline-block;width:140px;" v-else>	
									 {{ selectedOption === 'yes' ? branchSwitch.billing.county : user.billing.county }}												
									 </span>
								  </div>
								  <div>
									 <span style="display:inline-block;width:120px;">Country:</span>
									 <span style="display:inline-block;width:140px;" v-if="!user.isSubChildUser">
									 {{ selectedOption === 'no' ? 
									 (selectedparentOption === 'both' ? branchSwitch.billing.country :
									 (selectedparentOption === 'deiveryInfo' ? user.billing.billCountry : 
									 (selectedparentOption === 'billInfo' ? branchSwitch.billing.country : '')
									 )
									 )
									 : (user.billing.billCountry ? user.billing.billCountry : user.billing.country)
									 }}													
									 </span>
									 <span style="display:inline-block;width:140px;" v-else>	
									 {{ selectedOption === 'yes' ? branchSwitch.billing.country : user.billing.country }}												
									 </span>
								  </div>
								  <div>
									 <span style="display:inline-block;width:120px;">PostCode:</span>
									 <span style="display:inline-block;width:140px;" v-if="!user.isSubChildUser">
									 {{ selectedOption === 'no' ? 
									 (selectedparentOption === 'both' ? branchSwitch.billing.postCode :
									 (selectedparentOption === 'deiveryInfo' ? user.billing.billPostCode : 
									 (selectedparentOption === 'billInfo' ? branchSwitch.billing.postCode : '')
									 )
									 )
									 : (user.billing.billPostCode ? user.billing.billPostCode : user.billing.postCode)
									 }}
									 </span>
									 <span style="display:inline-block;width:140px;" v-else>	
									 {{ selectedOption === 'yes' ? branchSwitch.billing.postCode : user.billing.postCode }}												
									 </span>
								  </div>
								  <p v-if="user.isUserType === 'dentalcare'"></p>
								  <p v-else-if="user.isSubChildUser"></p>
								  <p v-else>Want a diffrent Address, click <a class="text-danger" @click="resetBilling()">HERE</a> to set different </p>
							   </div>
							   <div class="pane-inner" v-else>
								  <span class="text-danger"> Select Billing Address </span>
								  <select name="" id="" class="form-control" v-model="slBilling">
									 <option v-for="subUser in subChildUser" :key="subUser._id" :value="subUser._id">{{ subUser.name }} {{ subUser.lastName }}</option>
									 <!-- <option v-for="add in userAddresses" :value="add" :key="add._id">
										{{ selectedOption === 'no' ? branchSwitch.billing.addressOne : add.addressOne }},
										{{ selectedOption === 'no' ? branchSwitch.billing.city : add.city }},
										{{ selectedOption === 'no' ? branchSwitch.billing.county : add.county }}
										</option> -->
								  </select>
								  <!-- <span class="text-danger"> No Billing address found, click <a href="/user-dashboard/addresses">HERE</a> to set one </span> -->
								  <h3>OR</h3>
								  <p>Provide Manually</p>
								  <input type="text" name="" id="" v-model="newBilling.addressOne" placeholder="Address Line One" class="form-control">
								  <input type="text" name="" id="" v-model="newBilling.addressTwo" placeholder="Address Line Two" class="form-control">
								  <input type="text" name="" id="" v-model="newBilling.city" placeholder="City/Town" class="form-control">
								  <input type="text" name="" id="" v-model="newBilling.county" placeholder="County" class="form-control">
								  <input type="text" name="" id="" v-model="newBilling.country" placeholder="Country" class="form-control">
								  <input type="text" name="" id="" v-model="newBilling.postCode" placeholder="Post Code" class="form-control">
								  <button class="btn btn-success form-control" style="margin-bottom:15px;" @click="addBillingAddress">Add Billing Address</button>
							   </div>
							</div>
							<div v-else>
							   <div class="acc-info-content pad-1015" v-if="user.billing !== null &&  user.billing !== undefined">
								  <div>
									 <span style="display:inline-block;width:120px;">Address Line 1:</span>
									 <span style="display:inline-block;width:140px;" v-if="userAddresses.length > 1">
									 {{ selectedOption === 'no' ? 
									 (selectedparentOption === 'both' ?  (branchSwitchAddress.billAddressOne ? branchSwitchAddress.billAddressOne : branchSwitchAddress.addressOne) :
									 (selectedparentOption === 'deiveryInfo' ? user.billing.addressOne : 
									 (selectedparentOption === 'billInfo' ? (branchSwitchAddress.billAddressOne ? branchSwitchAddress.billAddressOne : branchSwitchAddress.addressOne) : '')
									 )
									 )
									 : user.billing.addressOne
									 }}											
									 </span>
									 <span style="display:inline-block;width:140px;" v-else>	
									 {{ selectedOption === 'yes' && defaultBranchSwitchAddress ? defaultBranchSwitchAddress.billing.billAddressOne : user.billing.addressOne }}												
									 </span>
								  </div>
								  <div>
									 <span style="display:inline-block;width:120px;">Address Line 2</span>
									 <span style="display:inline-block;width:140px;" v-if="userAddresses.length > 1">
									 {{ selectedOption === 'no' ? 
									 (selectedparentOption === 'both' ?  (branchSwitchAddress.billAddressTwo ? branchSwitchAddress.billAddressTwo : branchSwitchAddress.addressOne) :
									 (selectedparentOption === 'deiveryInfo' ? user.billing.addressTwo : 
									 (selectedparentOption === 'billInfo' ? (branchSwitchAddress.billAddressTwo ? branchSwitchAddress.billAddressTwo : branchSwitchAddress.addressTwo) : '')
									 )
									 )
									 : user.billing.addressTwo
									 }}
									 </span>
									 <span style="display:inline-block;width:140px;" v-else>	
									 {{ selectedOption === 'yes' && defaultBranchSwitchAddress ? defaultBranchSwitchAddress.billing.billAddressTwo : user.billing.addressTwo }}												
									 </span>
								  </div>
								  <div>
									 <span style="display:inline-block;width:120px;">City/Town:</span>
									 <span style="display:inline-block;width:140px;" v-if="userAddresses.length > 1">
									 {{ selectedOption === 'no' ? 
									 (selectedparentOption === 'both' ?  (branchSwitchAddress.billCity ? branchSwitchAddress.billCity : branchSwitchAddress.city) :
									 (selectedparentOption === 'deiveryInfo' ? user.billing.city : 
									 (selectedparentOption === 'billInfo' ? (branchSwitchAddress.billCity ? branchSwitchAddress.billCity : branchSwitchAddress.city) : '')
									 )
									 )
									 : user.billing.city
									 }}
									 </span>
									 <span style="display:inline-block;width:140px;" v-else>	
									 {{ selectedOption === 'yes' && defaultBranchSwitchAddress ? defaultBranchSwitchAddress.billing.billCity : user.billing.city }}												
									 </span>
								  </div>
								  <div>
									 <span style="display:inline-block;width:120px;">County:</span>
									 <span style="display:inline-block;width:140px;" v-if="userAddresses.length > 1">
									 {{ selectedOption === 'no' ? 
									 (selectedparentOption === 'both' ?  (branchSwitchAddress.billCountry ? branchSwitchAddress.billCountry : branchSwitchAddress.county) :
									 (selectedparentOption === 'deiveryInfo' ? user.billing.county : 
									 (selectedparentOption === 'billInfo' ? (branchSwitchAddress.billCountry ? branchSwitchAddress.billCountry : branchSwitchAddress.county) : '')
									 )
									 )
									 : user.billing.county
									 }}
									 </span>
									 <span style="display:inline-block;width:140px;" v-else>	
									 {{ selectedOption === 'yes' && defaultBranchSwitchAddress ? defaultBranchSwitchAddress.billing.billCountry : user.billing.county }}												
									 </span>
								  </div>
								  <div>
									 <span style="display:inline-block;width:120px;">Country:</span>
									 <span style="display:inline-block;width:140px;" v-if="userAddresses.length > 1">
									 {{ selectedOption === 'no' ? 
									 (selectedparentOption === 'both' ?  (branchSwitchAddress.billCounty ? branchSwitchAddress.billCounty : branchSwitchAddress.country) :
									 (selectedparentOption === 'deiveryInfo' ? user.billing.country : 
									 (selectedparentOption === 'billInfo' ? (branchSwitchAddress.billCounty ? branchSwitchAddress.billCounty : branchSwitchAddress.country) : '')
									 )
									 )
									 : user.billing.country
									 }}
									 </span>
									 <span style="display:inline-block;width:140px;" v-else>	
									 {{ selectedOption === 'yes' && defaultBranchSwitchAddress ? defaultBranchSwitchAddress.billing.billCounty : user.billing.country }}												
									 </span>
								  </div>
								  <div>
									 <span style="display:inline-block;width:120px;">PostCode:</span>
									 <span style="display:inline-block;width:140px;" v-if="userAddresses.length > 1">
									 {{ selectedOption === 'no' ? 
									 (selectedparentOption === 'both' ?  (branchSwitchAddress.billPostCode ? branchSwitchAddress.billPostCode : branchSwitchAddress.postCode) :
									 (selectedparentOption === 'deiveryInfo' ? user.billing.postCode : 
									 (selectedparentOption === 'billInfo' ? (branchSwitchAddress.billPostCode ? branchSwitchAddress.billPostCode : branchSwitchAddress.postCode) : '')
									 )
									 )
									 : user.billing.postCode
									 }}
									 </span>
									 <span style="display:inline-block;width:140px;" v-else>	
									 {{ selectedOption === 'yes' && defaultBranchSwitchAddress ? defaultBranchSwitchAddress.billing.billPostCode : user.billing.postCode }}												
									 </span>
								  </div>
								  <div>
									 <span style="display:inline-block;width:120px;">Mobile Number:</span>
									 <span style="display:inline-block;width:140px;" v-if="userAddresses.length > 1">
									 {{ selectedOption === 'no' ? 
									 (selectedparentOption === 'both' ? branchSwitchAddress.mobileNumber :
									 (selectedparentOption === 'deiveryInfo' ? user.billing.mobileNumber : 
									 (selectedparentOption === 'billInfo' ? branchSwitchAddress.mobileNumber : '')
									 )
									 )
									 : user.billing.mobileNumber
									 }}
									 </span>
									 <span style="display:inline-block;width:140px;" v-else>	
									 {{ selectedOption === 'yes' && defaultBranchSwitchAddress ? defaultBranchSwitchAddress.billing.mobileNumber : user.billing.mobileNumber }}												
									 </span>
								  </div>
								  <p v-if="userAddresses.length > 1"></p>
								  <p v-else-if="user.isSubChildUser"></p>
								  <p v-else>Want a diffrent Address, click <a class="text-danger" @click="resetBilling()">HERE</a> to set different </p>
							   </div>
							   <div class="pane-inner" v-else>
								  <span class="text-danger"> Select Billing Address </span>
								  <select name="" id="" class="form-control" v-model="slBilling">
									 <option v-for="subUser in subChildUser" :key="subUser._id" :value="subUser._id">{{ subUser.name }} {{ subUser.lastName }}</option>
									 <!-- <option v-for="add in userAddresses" :value="add" :key="add._id">
										{{ selectedOption === 'no' ? branchSwitch.billing.addressOne : add.addressOne }},
										{{ selectedOption === 'no' ? branchSwitch.billing.city : add.city }},
										{{ selectedOption === 'no' ? branchSwitch.billing.county : add.county }}
										</option> -->
								  </select>
								  <!-- <span class="text-danger"> No Billing address found, click <a href="/user-dashboard/addresses">HERE</a> to set one </span> -->
								  <h3>OR</h3>
								  <p>Provide Manually</p>
								  <input type="text" name="" id="" v-model="newBilling.addressOne" placeholder="Address Line One" class="form-control">
								  <input type="text" name="" id="" v-model="newBilling.addressTwo" placeholder="Address Line Two" class="form-control">
								  <input type="text" name="" id="" v-model="newBilling.city" placeholder="City/Town" class="form-control">
								  <input type="text" name="" id="" v-model="newBilling.county" placeholder="County" class="form-control">
								  <input type="text" name="" id="" v-model="newBilling.country" placeholder="Country" class="form-control">
								  <input type="text" name="" id="" v-model="newBilling.postCode" placeholder="Post Code" class="form-control">
								  <button class="btn btn-success form-control" style="margin-bottom:15px;" @click="addBillingAddress">Add Billing Address</button>
							   </div>
							</div>
						 </div>
					  </div>
					  <div id="shipping_info_w">
						 <div id="div_shipto" class="shipping_info">
							<div class="pane round-box" v-if="vadd">
							   <h3 class="title">
								  <span class="icon icon-two">{{user.isSubChildUser ? 2 : 3 }}</span>
								  DEIVERY ADDRESS		
							   </h3>
							   <div v-if="(user.address == null &&  user.address == undefined) || (userAddresses.length === 1 || userAddresses.length == 0)">
								  <div class="pane-inner"  v-if="user.address !== null &&  user.address !== undefined">
									 <div>
										<span style="display:inline-block;width:120px;">Address Line 1:</span>
										<span style="display:inline-block;width:140px;" v-if="!user.isSubChildUser">
										{{ selectedOption === 'no' ? 
										(selectedparentOption === 'both' ? branchSwitch.address.addressOne :
										(selectedparentOption === 'deiveryInfo' ? branchSwitch.address.addressOne : 
										(selectedparentOption === 'billInfo' ? user.address.addressOne : '')
										)
										)
										: user.address.addressOne
										}}
										</span>
										<span style="display:inline-block;width:140px;" v-else>	
										{{ selectedOption === 'yes' ? branchSwitch.address.addressOne : user.address.addressOne }}												
										</span>
									 </div>
									 <div>
										<span style="display:inline-block;width:120px;">Address Line 1</span>
										<span style="display:inline-block;width:140px;" v-if="!user.isSubChildUser">
										{{ selectedOption === 'no' ? 
										(selectedparentOption === 'both' ? branchSwitch.address.addressTwo :
										(selectedparentOption === 'deiveryInfo' ? branchSwitch.address.addressTwo : 
										(selectedparentOption === 'billInfo' ? user.address.addressTwo : '')
										)
										)
										: user.address.addressTwo
										}}													
										</span>
										<span style="display:inline-block;width:140px;" v-else>	
										{{ selectedOption === 'yes' ? branchSwitch.address.addressTwo : user.address.addressTwo }}											
										</span>
									 </div>
									 <div>
										<span style="display:inline-block;width:120px;">City/Town:</span>
										<span style="display:inline-block;width:140px;" v-if="!user.isSubChildUser">
										{{ selectedOption === 'no' ? 
										(selectedparentOption === 'both' ? branchSwitch.address.city :
										(selectedparentOption === 'deiveryInfo' ? branchSwitch.address.city : 
										(selectedparentOption === 'billInfo' ? user.address.city : '')
										)
										)
										: user.address.city
										}}													
										</span>
										<span style="display:inline-block;width:140px;" v-else>	
										{{ selectedOption === 'yes' ? branchSwitch.address.city : user.address.city }}										
										</span>
									 </div>
									 <div>
										<span style="display:inline-block;width:120px;">County:</span>
										<span style="display:inline-block;width:140px;" v-if="!user.isSubChildUser">
										{{ selectedOption === 'no' ? 
										(selectedparentOption === 'both' ? branchSwitch.address.county :
										(selectedparentOption === 'deiveryInfo' ? branchSwitch.address.county : 
										(selectedparentOption === 'billInfo' ? user.address.county : '')
										)
										)
										: user.address.county
										}}													
										</span>
										<span style="display:inline-block;width:140px;" v-else>	
										{{ selectedOption === 'yes' ? branchSwitch.address.county : user.address.county }}			
										</span>
									 </div>
									 <div>
										<span style="display:inline-block;width:120px;">Country:</span>
										<span style="display:inline-block;width:140px;" v-if="!user.isSubChildUser">
										{{ selectedOption === 'no' ? 
										(selectedparentOption === 'both' ? branchSwitch.address.country :
										(selectedparentOption === 'deiveryInfo' ? branchSwitch.address.country : 
										(selectedparentOption === 'billInfo' ? user.address.country : '')
										)
										)
										: user.address.country
										}}													
										</span>
										<span style="display:inline-block;width:140px;" v-else>	
										{{ selectedOption === 'yes' ? branchSwitch.address.country : user.address.country }}		
										</span>
									 </div>
									 <div>
										<span style="display:inline-block;width:120px;">PostCode:</span>
										<span style="display:inline-block;width:140px;" v-if="!user.isSubChildUser">													
										{{ selectedOption === 'no' ? 
										(selectedparentOption === 'both' ? branchSwitch.address.postCode :
										(selectedparentOption === 'deiveryInfo' ? branchSwitch.address.postCode : 
										(selectedparentOption === 'billInfo' ? user.address.postCode : '')
										)
										)
										: user.address.postCode
										}}
										</span>
										<span style="display:inline-block;width:140px;" v-else>	
										{{ selectedOption === 'yes' ? branchSwitch.address.postCode : user.address.postCode }}		
										</span>
									 </div>
									 <p v-if="user.isUserType === 'dentalcare'"></p>
									 <p v-else-if="user.isSubChildUser"></p>
									 <p v-else>Want a diffrent Address, click <a class="text-danger" @click="resetDelivery()">HERE</a> to set different </p>
								  </div>
								  <div class="pane-inner" v-else>
									 <!-- <span class="text-danger"> No Default Delivery address found, click <a href="/user-dashboard/addresses">HERE</a> to set one </span> -->
									 <span class="text-danger"> Select Delivery address </span>
									 <select name="" id="" class="form-control" v-model="slAddress">
										<option v-for="subUser in subChildUser" :key="subUser._id" :value="subUser._id">{{ subUser.name }} {{ subUser.lastName }}</option>
										<!-- <option v-for="add in userAddresses" :value="add" :key="add._id">
										   {{ selectedOption === 'no' ? branchSwitch.billing.addressOne : add.addressOne }},
										   {{ selectedOption === 'no' ? branchSwitch.billing.city : add.city }},
										   {{ selectedOption === 'no' ? branchSwitch.billing.county : add.county }}
										   </option> -->
									 </select>
									 <h3>OR</h3>
									 <p>Provide Manually</p>
									 <input type="text" name="" id="" v-model="newAddress.addressOne" placeholder="Address Line One" class="form-control">
									 <input type="text" name="" id="" v-model="newAddress.addressTwo" placeholder="Address Line Two" class="form-control">
									 <input type="text" name="" id="" v-model="newAddress.city" placeholder="City/Town" class="form-control">
									 <input type="text" name="" id="" v-model="newAddress.county" placeholder="County" class="form-control">
									 <input type="text" name="" id="" v-model="newAddress.country" placeholder="Country" class="form-control">
									 <input type="text" name="" id="" v-model="newAddress.postCode" placeholder="Post Code" class="form-control">
									 <button class="btn btn-success form-control" style="margin-bottom:15px;" @click="addDeliveryAddress">Add Delivery Address</button>
								  </div>
							   </div>
							   <div v-else>
								  <div class="pane-inner"  v-if="user.address !== null &&  user.address !== undefined">
									 <div>
										<span style="display:inline-block;width:120px;">Address Line 1:</span>
										<span style="display:inline-block;width:140px;" v-if="userAddresses.length > 1">
										{{ selectedOption === 'no' ? 
										(selectedparentOption === 'both' ?  branchSwitchAddress.addressOne :
										(selectedparentOption === 'deiveryInfo' ? branchSwitchAddress.addressOne : 
										(selectedparentOption === 'billInfo' ? user.address.addressOne : '')
										)
										)
										: user.address.addressOne
										}}											
										</span>
										<span style="display:inline-block;width:140px;" v-else>	
										{{ selectedOption === 'yes' && defaultBranchSwitchAddress ? defaultBranchSwitchAddress.address.addressOne : user.address.addressOne }}												
										</span>
									 </div>
									 <div>
										<span style="display:inline-block;width:120px;">Address Line 2</span>
										<span style="display:inline-block;width:140px;" v-if="userAddresses.length > 1">
										{{ selectedOption === 'no' ? 
										(selectedparentOption === 'both' ?  branchSwitchAddress.addressTwo :
										(selectedparentOption === 'deiveryInfo' ? branchSwitchAddress.addressTwo : 
										(selectedparentOption === 'billInfo' ? user.address.addressTwo : '')
										)
										)
										: user.address.addressTwo
										}}											
										</span>
										<span style="display:inline-block;width:140px;" v-else>	
										{{ selectedOption === 'yes' && defaultBranchSwitchAddress ? defaultBranchSwitchAddress.address.addressTwo : user.address.addressTwo }}												
										</span>
									 </div>
									 <div>
										<span style="display:inline-block;width:120px;">City/Town:</span>
										<span style="display:inline-block;width:140px;" v-if="userAddresses.length > 1">
										{{ selectedOption === 'no' ? 
										(selectedparentOption === 'both' ?  branchSwitchAddress.city :
										(selectedparentOption === 'deiveryInfo' ? branchSwitchAddress.city : 
										(selectedparentOption === 'billInfo' ? user.address.city : '')
										)
										)
										: user.address.city
										}}											
										</span>
										<span style="display:inline-block;width:140px;" v-else>	
										{{ selectedOption === 'yes' && defaultBranchSwitchAddress ? defaultBranchSwitchAddress.address.city : user.address.city }}												
										</span>
									 </div>
									 <div>
										<span style="display:inline-block;width:120px;">County:</span>
										<span style="display:inline-block;width:140px;" v-if="userAddresses.length > 1">
										{{ selectedOption === 'no' ? 
										(selectedparentOption === 'both' ?  branchSwitchAddress.county :
										(selectedparentOption === 'deiveryInfo' ? branchSwitchAddress.county : 
										(selectedparentOption === 'billInfo' ? user.address.county : '')
										)
										)
										: user.address.county
										}}											
										</span>
										<span style="display:inline-block;width:140px;" v-else>	
										{{ selectedOption === 'yes' && defaultBranchSwitchAddress ? defaultBranchSwitchAddress.address.county : user.address.county }}												
										</span>
									 </div>
									 <div>
										<span style="display:inline-block;width:120px;">Country:</span>
										<span style="display:inline-block;width:140px;" v-if="userAddresses.length > 1">
										{{ selectedOption === 'no' ? 
										(selectedparentOption === 'both' ?  branchSwitchAddress.country :
										(selectedparentOption === 'deiveryInfo' ? branchSwitchAddress.country : 
										(selectedparentOption === 'billInfo' ? user.address.country : '')
										)
										)
										: user.address.country
										}}											
										</span>
										<span style="display:inline-block;width:140px;" v-else>	
										{{ selectedOption === 'yes' && defaultBranchSwitchAddress ? defaultBranchSwitchAddress.address.country : user.address.country }}												
										</span>
									 </div>
									 <div>
										<span style="display:inline-block;width:120px;">PostCode:</span>
										<span style="display:inline-block;width:140px;" v-if="userAddresses.length > 1">
										{{ selectedOption === 'no' ? 
										(selectedparentOption === 'both' ?  branchSwitchAddress.postCode :
										(selectedparentOption === 'deiveryInfo' ? branchSwitchAddress.postCode : 
										(selectedparentOption === 'billInfo' ? user.address.postCode : '')
										)
										)
										: user.address.postCode
										}}											
										</span>
										<span style="display:inline-block;width:140px;" v-else>	
										{{ selectedOption === 'yes' && defaultBranchSwitchAddress ? defaultBranchSwitchAddress.address.postCode : user.address.postCode }}												
										</span>
									 </div>
									 <div>
										<span style="display:inline-block;width:120px;">Mobile Number:</span>
										<span style="display:inline-block;width:140px;" v-if="userAddresses.length > 1">
										{{ selectedOption === 'no' ? 
										(selectedparentOption === 'both' ?  branchSwitchAddress.mobileNumber :
										(selectedparentOption === 'deiveryInfo' ? branchSwitchAddress.mobileNumber : 
										(selectedparentOption === 'billInfo' ? user.address.mobileNumber : '')
										)
										)
										: user.address.mobileNumber
										}}											
										</span>
										<span style="display:inline-block;width:140px;" v-else>	
										{{ selectedOption === 'yes' && defaultBranchSwitchAddress ? defaultBranchSwitchAddress.address.mobileNumber : user.address.mobileNumber }}												
										</span>
									 </div>
									 <p v-if="userAddresses.length > 1"></p>
									 <p v-else-if="user.isSubChildUser"></p>
									 <p v-else>Want a diffrent Address, click <a class="text-danger" @click="resetDelivery()">HERE</a> to set different </p>
								  </div>
								  <div class="pane-inner" v-else>
									 <!-- <span class="text-danger"> No Default Delivery address found, click <a href="/user-dashboard/addresses">HERE</a> to set one </span> -->
									 <span class="text-danger"> Select Delivery address </span>
									 <select name="" id="" class="form-control" v-model="slAddress">
										<option v-for="subUser in subChildUser" :key="subUser._id" :value="subUser._id">{{ subUser.name }} {{ subUser.lastName }}</option>
										<!-- <option v-for="add in userAddresses" :value="add" :key="add._id">
										   {{ selectedOption === 'no' ? branchSwitch.billing.addressOne : add.addressOne }},
										   {{ selectedOption === 'no' ? branchSwitch.billing.city : add.city }},
										   {{ selectedOption === 'no' ? branchSwitch.billing.county : add.county }}
										   </option> -->
									 </select>
									 <h3>OR</h3>
									 <p>Provide Manually</p>
									 <input type="text" name="" id="" v-model="newAddress.addressOne" placeholder="Address Line One" class="form-control">
									 <input type="text" name="" id="" v-model="newAddress.addressTwo" placeholder="Address Line Two" class="form-control">
									 <input type="text" name="" id="" v-model="newAddress.city" placeholder="City/Town" class="form-control">
									 <input type="text" name="" id="" v-model="newAddress.county" placeholder="County" class="form-control">
									 <input type="text" name="" id="" v-model="newAddress.country" placeholder="Country" class="form-control">
									 <input type="text" name="" id="" v-model="newAddress.postCode" placeholder="Post Code" class="form-control">
									 <button class="btn btn-success form-control" style="margin-bottom:15px;" @click="addDeliveryAddress">Add Delivery Address</button>
								  </div>
							   </div>
							</div>
						 </div>
					  </div>
					  <!-- shipping_info -->
					  <div id="right-pane-top" class="col-md-12 col-sm-12 col-xs-12">
						 <div id="shipping_method" class="col-md-6 col-sm-12 col-xs-12" style="display: none;">
							<div class="shipment-pane">
							   <div class="pane round-box">
								  <h3 class="title">
									 <span class="icon icon-three">{{user.isSubChildUser ? 3 : 4 }}</span>
									 Shipping method		
								  </h3>
								  <div class="pane-inner" style="visibility: visible;">
									 Select shipment
									 <fieldset id="shipments">
										<input type="radio" value="cmsmart" v-model="shippingMethod" id="shipment_id_1" name="virtuemart_shipmentmethod_id" >
										<label for="shipment_id_1"><span class="vmshipment"><span class="vmshipment_name">Cmsmart</span></span></label>
										<br>
										<input type="radio" v-model="shippingMethod" value="printmart" id="shipment_id_2" name="virtuemart_shipmentmethod_id">
										<label for="shipment_id_2"><span class="vmshipment"><span class="vmshipment_name">Printmart</span></span></label>
										<br>
									 </fieldset>
								  </div>
							   </div>
							</div>
						 </div>
						 <!-- shipping_method -->
						 <div id="payment_method" class="col-md-12 col-sm-12 col-xs-12">
							<div class="payment-pane">
							   <div class="pane round-box">
								  <h3 class="title"> <span class="icon icon-four">{{user.isSubChildUser ? 3 : 4 }}</span>	Order Email		</h3>
								  <div class="pane-inner">
									 Select Email to receive order notifications<br>
									 optional, leaving empty will select default user email for notifications
									 <select name="" id="" class="form-control" v-model="slEmail">
										<option v-for="em in user.emails" :key="em" :value="em">{{ em }}</option>
									 </select>
								  </div>
							   </div>
							</div>
						 </div>
						 <!-- payment_method -->
						 <div id="payment_method" class="col-md-6 col-sm-12 col-xs-12">
							<div class="payment-pane">
							   <div class="pane round-box">
								  <h3 class="title"> <span class="icon icon-four">{{user.isSubChildUser ? 4 : 5 }}</span>	Payment method		</h3>
								  <div class="pane-inner">
									 Select payment method
									 <fieldset id="payments">
										<input type="radio" v-model="paymentMethod" value="Credit" id="payment_id_1" name="virtuemart_paymentmethod_id" checked="checked">
										<label for="payment_id_1"><span class="vmpayment"><span class="vmpayment_name">Account</span></span></label>
										<br>
										<input type="radio" v-model="paymentMethod" value="Card" id="payment_id_2" name="virtuemart_paymentmethod_id">
										<label for="payment_id_2"><span class="vmpayment"><span class="vmpayment_name">Card</span></span></label>
										<br>
									 </fieldset>
								  </div>
							   </div>
							</div>
						 </div>
						 <!-- payment_method -->
					  </div>
				   </div>
				   <div class="col-md-8 col-sm-6 col-xs-12">
				   </div>
				   <div id="checkfull" class="col-md-8 col-sm-12 col-xs-12">
					  <div  class="col-md-12 col-sm-12 col-xs-12" >
						 <!-- render layout -->
						 <fieldset class="round-box" id="cart-contents">
							<h3 class="title"><span class="icon fa fa-check"></span>ORDER REVIEWS</h3>
							<table cellspacing="0" cellpadding="0" border="0" class="cart-summary no-border">
							   <tbody>
								  <tr clas="top-header">
									 <th align="left" class="th-name">Products Name</th>
									 <!-- <th width="10%" class="th-quoteId">Quote ID No</th> -->
									 <th width="10%" class="th-price">Price</th>
									 <th width="10%" class="th-tax"><span class="priceColor2">Discount</span></th>
									 <th width="15%"  class="th-quanlity">Quantity</th>
									 <th width="15%"  class="th-quanlity">Delivery Trunaround</th>
									 <!-- <th width="15%" class="th-discount"><span class="priceColor2">Action</span></th> -->
									 <th width="15%" align="left" class="th-total th-last">SubTotal</th>
								  </tr>
								  <tr valign="top" id="product_row_0" class="product-detail-row product-detail-last-row sectiontableentry1" v-for="item in userCartItems" :key="item._id">
									 <td align="left" class="pro_name">
										<img class="cart-images" :src="getImageSrc(item)" style="max-width:150px;" @error="handleImageError">
										<!-- <img class="cart-images" :src="$store.state.URL+'/uploads/artworks/'+item.artwork" style="max-width:150px;" v-if="item.artwork">
										<img class="cart-images" :src="$store.state.URL+'/uploads/product/'+item.product.image" style="max-width:150px;" v-else> -->
										<a class="product-name" href="#">
										{{ item.product ? item.product.productName : item.quote_id.suppliersDesription }} <strong>({{ item.quote_id ? item.quote_id.quoteNumber : '' }})</strong> 
										</a>
										<span>
										<a class="cart-delete" style="color:red;cursor:pointer;font-size:30px;" title="Delete Product" @click="removeCartItem(item)"><i class="fa fa-times"></i>
										<span style="font-size:16px;color:red;cursor:pointer;">Remove From Basket</span>
										</a>
										</span>
									 </td>
									 <!-- <td>{{ item.quote_id ? item.quote_id.quoteNumber : '-' }}</td> -->
									<td align="left" class="base-price">
										<div class="PricebasePrice vm-display vm-price-value">
										   <span class="vm-price-desc"></span>
										   <span class="PricebasePrice">&euro; {{ item.product ? item.quantity.sellingProductPrice : item.quantity.sellingProductPrice }}</span>
										</div>
									 </td>
									 <td align="right" class="pro_tax">
										<span class="priceColor2">
										   <div class="PricetaxAmount vm-display vm-price-value">
											  <span class="vm-price-desc"></span>
											  <span class="PricetaxAmount" :class="{ 'blue-text': item.discountPCPAm + item.discountPromoCodeAm + item.spendDiscountAm > 0 }">
												&euro; {{ item.quote_id ? 'N/A' : $LFT(
												item.discountPCPAm +
												item.discountPromoCodeAm +
												item.spendDiscountAm
												)}}
											  </span>
										   </div>
										</span>
									 </td>
									 <td align="left" class="pro_tax">
										<span>{{ item.quantity.quantity }}</span>
										<!-- <input type="text" id="quantity_0" value="1" maxlength="4" size="3" name="quantity[0]" class="quantity-input js-recalculate" title="Update Quantity In Cart"> -->
									 </td>
									 <td>
										{{ (item.product) ? item.product.deliveryTurnaround : item.quote_id.deliveryTurnaround }}
									 </td>
									 <td align="right" id="subtotal_with_tax_0" colspan="0" class="sub-total td-last" ><span class="line-through" style="color: blue;">
										&euro; {{ item.quote_id ? $LFT(item.quantity.sellingProductPrice) :
										$LFT(
										((item.quantity.sellingProductPrice ))
										- (
										item.discountPCPAm
										+
										item.discountPromoCodeAm
										+
										item.spendDiscountAm
										) )  }}
										</span>
									 </td>
								  </tr>
								  <!--Begin of SubTotal, Tax, Shipment, Coupon Discount and Total listing -->
								  <!--  Total -->
								  <tr class="pr-total">
									 <td colspan="6">
										<table>
										   <tbody>
											  <tr class="first">
												 <td>SubTotal:</td>
												 <td class="pr-right">
													<div class="PricesalesPrice vm-display vm-price-value"><span class="vm-price-desc"></span><span class="PricesalesPrice">
													   &euro; {{ $LFT(total) }}
													   </span>
													</div>
												 </td>
											  </tr>
											  <tr>
												 <td>VAT:</td>
												 <td class="pr-right"><span id="total_tax" class="priceColor2">&euro; {{ $LFT(VAT) }}</span></td>
											  </tr>
											  <!-- <tr>
												 <td>Shipment:</td>
												 <td class="pr-right"><span id="shipment" class="priceColor2">0</span></td>
												 </tr> -->
											  <tr class="last">
												 <td>Total:</td>
												 <td class="pr-right" ><strong id="bill_total" style="color: blue;">&euro; {{  $LFT( Number(total) + Number(VAT) )  }}</strong></td>
											  </tr>
										   </tbody>
										</table>
									 </td>
								  </tr>
							   </tbody>
							</table>
						 </fieldset>
						 <div id="right-pane-bottom">
							<div class="customer-note">
							   <p class="comment">Notes and special requests</p>
							   <textarea class="inputbox" rows="1" cols="60" v-model="notes" name="customer_note" id="customer_note_field"></textarea>
							</div>
							<div class="row cart-bottom">
							   <div class="col-sm-6 discount-code clearfix">
								  <h4>Discount Codes</h4>
								  <p>Do you have a promotional code?</p>
								  <form action="#" method="POST" name="discount-code">
									 <div class="form-group">
										<input type="text" class="form-control" v-model="PROMOCODE" id="discountCode" placeholder="Promo Code" :disabled="preferentialMessage.isDisabled">
									 </div>
									 <button  class="gbtn btn-discount" @click.prevent="checkPromo">Apply Coupon</button>
									
									 <span class="text-danger" v-if="userCartItems.length > 0">
										{{ preferentialMessage.message }}
									 <!-- {{ userCartItems[0].discountPCP ? 'Preferential Pricing agreements already applied on product.' : promoError }} -->
									 </span>
								  </form>
							   </div>
							   <!--Discount Code: End-->
							</div>
							<fieldset class="vm-fieldset-tos">
							   <div class="checkout-button-top">
								  <button class="vm-button-correct btn btn-success"  @click="purchase">Confirm Purchase</button>
								  <span class="text-danger d-block" style="color: red;" v-if="!user.address">Please set a default Delivery address</span>
								  <span class="text-danger d-block" style="color: red;" v-if="!user.billing">Please set a default Billing address</span>
							   </div>
							</fieldset>
						 </div>
						 <!-- right-pane-bottom -->
					  </div>
				   </div>
				</div>
			 </div>
		  </section>
	   </main>
	   <!--Main index : End-->
	   <footer-section></footer-section>
	   <div class="modal-mask" v-if="selectedOption === 'no' && selectedparentOption == null">
		  <div class="modal-wrapper">
			 <div class="modal-container">
				<div class="modal-body">
				   <button class="btn btn-danger close-btn" style="float:right;" @click="closeModal">
				   <b>&#10006;</b>
				   </button>
				   <h3 class="branch-title"><span class="icon icon-one"></span>Switch To Branch/Location</h3>
				   <br>
				   <div class="pane-inner">
					  <div id="radio_wrapper" class="radio-btn">
						 <p>
							<input type="radio" name="parentoption" value="both" v-model="selectedparentOption" checked />Both
						 </p>
						 <p>
							<input type="radio" name="parentoption" value="billInfo"  v-model="selectedparentOption" />BILLING INFO
						 </p>
						 <p>
							<input type="radio" name="parentoption" value="deiveryInfo"  v-model="selectedparentOption"/> DEIVERY ADDRESS
						 </p>
						 <br>
					  </div>
				   </div>
				</div>
			 </div>
		  </div>
	   </div>
	</div>
 </template>

<script>
import moment from 'moment'
import NavBar from "@/components/NavBar.vue"
import FooterSection from "@/components/FooterSection.vue"
import {mapState} from 'vuex'
export default {
components:{NavBar, FooterSection},
data(){
	return {
		PROMOCODE:'',
		promoError:'',
		manualBilling: false,
		manualAddress: false,
		date:'',
		vbill: true,
		vadd: true,
		slEmail:"",
		slAddress:null,
		slBilling:null,
		notes:'', 
		newAddress:{},
		newBilling:{},
		shippingMethod:"cmsmart",
		paymentMethod:"Credit",
		selectedCustomer: 'default',
		selectedOption: null,
		selectedparentOption:null,
	}
},
                            // <input type="text" name="" id="" v-model="newAddress.addressOne" placeholder="Address Line One" class="form-control">
							// <input type="text" name="" id="" v-model="newAddress.addressTwo" placeholder="Address Line Two" class="form-control">
							// <input type="text" name="" id="" v-model="newAddress.city" placeholder="City/Town" class="form-control">
							// <input type="text" name="" id="" v-model="newAddress.county" placeholder="County" class="form-control">
							// <input type="text" name="" id="" v-model="newAddress.country" placeholder="Country" class="form-control">
							// <input type="text" name="" id="" v-model="newAddress.postCode" placeholder="Post Code" class="form-control">
							// <input type="text" name="" id="" v-model="newBilling.addressOne" placeholder="Address Line One" class="form-control">
							// <input type="text" name="" id="" v-model="newBilling.addressTwo" placeholder="Address Line Two" class="form-control">
							// <input type="text" name="" id="" v-model="newBilling.city" placeholder="City/Town" class="form-control">
							// <input type="text" name="" id="" v-model="newBilling.county" placeholder="County" class="form-control">
							// <input type="text" name="" id="" v-model="newBilling.country" placeholder="Country" class="form-control">
							// <input type="text" name="" id="" v-model="newBilling.postCode" placeholder="Post Code" class="form-control">
watch:{
	slAddress(val){
		this.newAddress.addressOne = val.addressOne;
		this.newAddress.addressTwo = val.addressTwo;
		this.newAddress.city = val.city;
		this.newAddress.county = val.county;
		this.newAddress.country = val.country;
		this.newAddress.postCode = val.postCode;
		this.addDeliveryAddress()
	},
	slBilling(val){
		this.newBilling.addressOne = val.addressOne;
		this.newBilling.addressTwo = val.addressTwo;
		this.newBilling.city = val.city;
		this.newBilling.county = val.county;
		this.newBilling.country = val.country;
		this.newBilling.postCode = val.postCode;
		this.addBillingAddress()
	},
},
computed:{
	shouldShowDiv() {
      return this.selectedCustomer !== 'default';
    },
	...mapState(['user','userCart','userCartItems','userPCP','userAddresses','subChildUser','branchSwitchYes','branchSwitch','branchSwitchAddress','defaultBranchSwitchAddress']),
	// ((item.product.sellingProductPrice / item.product.quantity ) * item.quantity)
	// (( ((item.product.sellingProductPrice / item.product.quantity ) * item.quantity) * item.discountPCP ) / 100 )
	// (( ((item.product.sellingProductPrice / item.product.quantity ) * item.quantity) * item.discountPromoCode ) / 100 )
	total(){
		if (this.userCartItems.length) {

			let total = 0;

			this.userCartItems.forEach(element => {
				total += element.quote_id 
					? element.quantity.sellingProductPrice 
					: ((element.quantity.sellingProductPrice) 
					- (element.discountPCPAm 
						+ element.discountPromoCodeAm 
						+ element.spendDiscountAm));
				});

			return Number(total.toFixed(2))
		}
		return 0;
	},
	VAT(){
		if (this.userCartItems.length) {

			let total = 0;

			this.userCartItems.forEach(element => {
				let totals = element.quote_id 
						? element.quantity.sellingProductPrice 
						: (
						(element.quantity.sellingProductPrice) 
						- (
							element.discountPCPAm
							+ element.discountPromoCodeAm
							+ element.spendDiscountAm
						)
						);

						let vatOrPrice = element.product !== undefined && element.product !== null
						? element.product.product.supplierVAT
						: element.quantity.supplierVATNumber !== undefined && element.quantity.supplierVATNumber !== null
						? element.quantity.supplierVATNumber
						: 0;


						let tt = ((totals * vatOrPrice / 100));
				// let tt =  ((totals * element.product.product.supplierVAT / 100) )
				total += Number(tt)
			});

			return Number(total.toFixed(2))
		}
		return 0;
	},

	preferentialMessage() {
      let message = '';
      let isDisabled = false;

      if (this.userCartItems.length === 1) {
        message = this.userCartItems[0].discountPCP
          ? 'Preferential Pricing agreements already applied on product.'
          : this.promoError;
        isDisabled = this.userCartItems[0].discountPCP > 0;
      } else if (this.userCartItems.length > 1) {
        for (let item of this.userCartItems) {
        if (item.quote_id && item.discountPCP == 1 || (item.product && item.discountPCP > 1)) {
            message = 'Preferential Pricing agreements already applied on product Rahul.';
            isDisabled = true;
          }
		  else{
	      	isDisabled = false;
		  }
        }
        if (!isDisabled) {
          message = this.promoError;
        }
      }
      
      return { message, isDisabled };
    },
},
async mounted(){
	await this.$store.dispatch("getPcps")
	await this.$store.dispatch("getUser")	
	setTimeout(() => {
		this.checkDiscounts()
	}, 3000);
},
methods:{
	async removeCartItem(item){
		console.log("removing cart item", item)
		await this.$store.dispatch("removeCartItem",{item})
		await this.$store.dispatch("getUser")
		
	},
	getImageSrc(item) {
		if (item.artwork) {
		return `${this.$store.state.URL}/uploads/artworks/${item.artwork}`;
		} else if (item.product && item.product.image) {
		return `${this.$store.state.URL}/uploads/product/${item.product.image}`;
		} else {
		return ''; 
		}
	},
	handleImageError(event) {
		event.target.src = ''; 
	},
	handleOptionChange() {
		this.selectedOption = 'yes';
		this.selectedparentOption = null;
		if (this.selectedOption == 'yes' && this.userAddresses.length > 1) {
			this.switchBranchAddressNo(); 
		}
		else{
			this.switchBranchYes();
			this.switchBranchNo();
		}
    },
	handleRadioChange() {
		if (this.selectedOption === 'yes') {
			this.switchBranchYes();
			this.selectedparentOption = null;
		}
		else if (this.selectedOption === 'no') {
        	this.switchBranchNo();
      }
    },
	closeModal() {
		this.selectedOption = 'yes';
    },
	async switchBranchYes() {
		if(this.selectedCustomer == 'default' && this.user.isParentID){
			await this.$store.dispatch("branchSwitch",{slug: this.user.isParentID});
		}else{
			await this.$store.dispatch("branchSwitchYes");
		}
	},
    async switchBranchNo() {
		if(this.selectedCustomer == 'default'){
			await this.$store.dispatch("branchSwitchYes");
		}else{
			await this.$store.dispatch("branchSwitch",{slug: this.selectedCustomer});
		}
    },
	async switchBranchAddressNo() {
		console.log("INNNNNNNNNNNNNNNNNNNNNNNNNN");
		if(this.selectedCustomer == 'default'){
            console.log(this.selectedCustomer,"branchSwitchAddress default",this.user._id);
            await this.$store.dispatch("defaultBranchSwitchAddress",{slug: this.user._id});
        }else{
            console.log(this.selectedCustomer,"branchSwitchAddress No");
            await this.$store.dispatch("branchSwitchAddress",{slug: this.selectedCustomer});
        }
	},
	resetBilling(){
		this.vbill = false
		this.user.billing = null
			this.manualBilling = false
			this.newBilling = {}
			setTimeout(() => {
		this.vbill = true
			}, 1000);
	},
	resetDelivery(){
		this.vadd = false

		this.user.address = null
			this.manualAddress = false
			this.newAddress = {}
			setTimeout(() => {
		this.vadd = true
			}, 1000);
	},
	addDeliveryAddress(){
		let errors = []
		if (this.newAddress.addressOne.length < 1) errors.push("Address Line One Is Required")
		if (this.newAddress.addressTwo.length < 1) errors.push("Address Line Two Is Required")
		if (this.newAddress.city.length < 1) errors.push("City Is Required")
		if (this.newAddress.county.length < 1) errors.push("County Is Required")
		if (this.newAddress.country.length < 1) errors.push("Country Is Required")
		if (this.newAddress.postCode.length < 1) errors.push("Post Code Is Required")
		if (errors.length) {
			alert(errors[0])
		}else{
			this.user.address = this.newAddress;
			this.manualAddress = true
			this.newAddress = {}
		}
	},
	addBillingAddress(){
		let errors = []
		if (this.newBilling.addressOne.length < 1) errors.push("Address Line One Is Required")
		if (this.newBilling.addressTwo.length < 1) errors.push("Address Line Two Is Required")
		if (this.newBilling.city.length < 1) errors.push("City Is Required")
		if (this.newBilling.county.length < 1) errors.push("County Is Required")
		if (this.newBilling.country.length < 1) errors.push("Country Is Required")
		if (this.newBilling.postCode.length < 1) errors.push("Post Code Is Required")
		if (errors.length) {
			alert(errors[0])
		}else{
			this.user.billing = this.newBilling;
			this.manualBilling = true
			this.newBilling = {}
		}
	},
	async purchase(){
		if (this.user.billing && this.user.address) {
			let items = [];
			this.userCartItems.forEach(element => {
				let totals = element.quote_id 
						? element.quantity.sellingProductPrice 
						: (
						(element.quantity.sellingProductPrice) 
						- (
							element.discountPCPAm
							+ element.discountPromoCodeAm
							+ element.spendDiscountAm
						)
					);
				
					let vatOrPrice = element.product !== undefined && element.product !== null
						? element.product.product.supplierVAT
						: element.quantity.supplierVATNumber !== undefined && element.quantity.supplierVATNumber !== null
						? element.quantity.supplierVATNumber
						: 0;

				let tt =  ((totals * vatOrPrice / 100) )				
				items.push({
					id:element._id,
					discountPromoCode:element.discountPromoCode,
					discountPCP:element.discountPCP,
					discountPCPAm:element.discountPCPAm,
					discountPromoCodeAm:element.discountPromoCodeAm,
					spendDiscountAm:element.spendDiscountAm,
				order: true,
					supProduct:element.product ? element.product : element.quote_id,
					vat:tt,

				})
			});

			let order = {
				items,
				email: (this.slEmail.length) ? this.slEmail : this.user.email,
				// user: (this.selectedCustomer == 'default') ? this.user._id : this.selectedCustomer,
				referralCode: (this.user.referralCode) ? this.user.referralCode : '',
				notes: this.notes,				
				// isSubChildID: (
				// 	this.selectedCustomer === 'default' ? '' : this.selectedCustomer === 'yes' ? this.branchSwitchYes : this.branchSwitch),
				deliveryAt: this.date,
				paymentMethod:this.paymentMethod,
				shippingMethod:this.shippingMethod,
			};

			if (this.user.isSubChildUser == true || this.selectedOption === null) {
				order.deliveryAddress = this.user.address;
				order.billingAddress = this.user.billing; 
					if (this.userAddresses.length > 1) {
						order.user =  this.user._id;
					}
					else{
						order.user =  (this.selectedCustomer == 'default') ? this.user._id : this.selectedCustomer;
					}
				} else {
					if (this.userAddresses.length > 1) {
						order.user =  this.user._id;
						order.isSubChildID = ''; 
						order.deliveryAddress = this.selectedOption === 'no' ?
						(this.selectedparentOption === 'both' || this.selectedparentOption === 'deiveryInfo' ? this.branchSwitchAddress : 
							(this.selectedparentOption === 'billInfo' ? this.user.address : '')
						) : 
						(this.selectedOption === 'yes' ?  this.user.address : this.branchSwitchAddress.address);

						order.billingAddress = this.selectedOption === 'no' ?
						(this.selectedparentOption === 'both' || this.selectedparentOption === 'billInfo' ? this.branchSwitchAddress : 
							(this.selectedparentOption === 'deiveryInfo' ? this.user.address : '')
						) : 
						(this.selectedOption === 'yes' ?  this.user.address : this.branchSwitch.address);
					}else{
						order.user =  (this.selectedCustomer == 'default') ? this.user._id : this.selectedCustomer;
						order.isSubChildID = (this.selectedCustomer === 'default' ? '' : this.selectedCustomer === 'yes' ? this.branchSwitchYes : this.branchSwitch);
						order.deliveryAddress = this.selectedOption === 'no' ?
						(this.selectedparentOption === 'both' || this.selectedparentOption === 'deiveryInfo' ? this.branchSwitch.address : 
							(this.selectedparentOption === 'billInfo' ? this.user.address : '')
						) : 
						(this.selectedOption === 'yes' ?  this.user.address : this.branchSwitch.address);
		
						order.billingAddress = this.selectedOption === 'no' ?
						(this.selectedparentOption === 'both' || this.selectedparentOption === 'billInfo' ? this.branchSwitch.address : 
							(this.selectedparentOption === 'deiveryInfo' ? this.user.address : '')
						) : 
						(this.selectedOption === 'yes' ?  this.user.address : this.branchSwitch.address);

					}
				}
			// return
			// console.log(order,'--------------items--------------');
			let dec = await this.$store.dispatch("placeOrder", order)
			if (dec.status) {
				this.$store.dispatch("getUser")
				this.$router.push({path:'/user-dashboard/jobs'})
			}

		}
	},
	handleQtyChange(index){
		let num = Number(this.$store.state.userCartItems[index].quantity)
		console.log(num)
		if (num) {
			this.$store.state.userCartItems[index].price = ((this.$store.state.userCartItems[index].product.sellingProductPrice / this.$store.state.userCartItems[index].product.quantity ) *  num)
		}
		
	},
	async proceedCheckout(){
		let cartItems = [];
		this.userCartItems.forEach(element => {
			cartItems.push({
				id: element._id,
				discountPCP: element.discountPCP,
				discountPromoCode: element.discountPromoCode,
				promoCode: element.promoCode,
				quantity: Number(element.quantity),
			})
		});
		let dec = await this.$store.dispatch("updateCartItems", {items: cartItems})
	},
	async checkPromo(){
		this.promoError = ''
		let dec = await this.$store.dispatch("checkPromo", { code: this.PROMOCODE });
		if (dec.status) {
			console.log(dec.code)
			if (moment().isBetween(dec.code.startAt, dec.code.endAt)) {	
				console.log("code is in valid date range")


				if (dec.code.discountOn == 'selected') {
				console.log("userPCP is selected")
				dec.code.products.forEach(element => {
					console.log("loop check on userPCP supported products")
					if (this.$store.state.userCartItems.length) {
						console.log("loop check on cart items started")
						this.$store.state.userCartItems.forEach((elm, index) => {
							console.log("loop check on cart items")
							if (elm.product._id == element) {
								if(elm.discountPromoCode){
									this.promoError = 'Promotion Code Discount Already Applied On '+elm.product.productName
								}else{
									// let dc = ((elm.price * dec.code.discount) / 100)
									// console.log("found match on cart item on userPCP supported item", dc)
									this.$store.state.userCartItems[index].discountPromoCode = dec.code.discount
									this.$store.state.userCartItems[index].promoCode = dec.code.code
								this.$store.state.userCartItems[index].discountPromoCodeAm = ((this.$store.state.userCartItems[index].quantity.sellingProductPrice * dec.code.discount) / 100)

								}
								
							}
						});
					}
				});
			}else{
				if (this.$store.state.userCartItems.length) {
						console.log("loop check on cart items started")
						this.$store.state.userCartItems.forEach((elm, index) => {
							console.log("loop check on cart items")
							// if (elm.product._id == element._id) {
								let dc = ((elm.price * dec.code.discount) / 100)
								console.log("found match on cart item on userPCP supported item", dc)
								this.$store.state.userCartItems[index].discountPromoCode = dc
							// }
							if(elm.discountPromoCode){
									this.promoError = 'Promotion Code Discount Already Applied On '+elm.product.productName
								}else{
									// let dc = ((elm.price * dec.code.discount) / 100)
									// console.log("found match on cart item on userPCP supported item", dc)
									this.$store.state.userCartItems[index].discountPromoCode = dec.code.discount
									this.$store.state.userCartItems[index].promoCode = dec.code.code
								this.$store.state.userCartItems[index].discountPromoCodeAm = ((this.$store.state.userCartItems[index].quantity.sellingProductPrice * dec.code.discount) / 100)

								}
						});
					}
			}


		}else{
				this.promoError = "The Code is not Useful"
			}
		}else{
			this.promoError = dec.message;
		}
	},
	async checkDiscounts(){
		console.log("check discount triggered")
	await this.$store.dispatch("getPcps")

		if (this.userPCP !== null) {
			if (moment().isBetween(this.userPCP.startAt, this.userPCP.endAt)) {
				console.log("found user PCP")
			if (this.userPCP.discountOn == 'selected') {
				console.log("userPCP is selected")
				this.userPCP.products.forEach(element => {
					console.log("loop check on userPCP supported products")
					if (this.$store.state.userCartItems.length) {
						console.log("loop check on cart items started")
						this.$store.state.userCartItems.forEach((elm, index) => {
							console.log("loop check on cart items")
							if (elm.product._id == element._id) {
								// let dc = ((elm.price * this.userPCP.discount) / 100)
								// console.log("found match on cart item on userPCP supported item", dc)
								this.$store.state.userCartItems[index].discountPCP = this.userPCP.discount
								this.$store.state.userCartItems[index].discountPCPAm = ((this.$store.state.userCartItems[index].quantity.sellingProductPrice * this.userPCP.discount) / 100)

							}
						});
					}
				});
			}else{
				if (this.$store.state.userCartItems.length) {
						console.log("loop check on cart items started")
						this.$store.state.userCartItems.forEach((elm, index) => {
							console.log("loop check on cart items")
							// if (elm.product._id == element._id) {
								let dc = ((elm.price * this.userPCP.discount) / 100)
								console.log("found match on cart item on userPCP supported item", dc)
								this.$store.state.userCartItems[index].discountPCP = this.userPCP.discount
								this.$store.state.userCartItems[index].discountPCPAm = ((this.$store.state.userCartItems[index].quantity.sellingProductPrice * this.userPCP.discount) / 100)

							// }
						});
					}
			}	
			}
			
		}else{
			console.log("userPCP found NULL")
		}
	}
},
}

</script>

<style scoped>
.mapouter{position:relative;text-align:right;height:100%;width:100%;}
.gmap_canvas {overflow:hidden;background:none!important;height:100%;width:100%;}

select.customer-list {
    width: 100%;
    padding: 8px;
    background-color: #E8E8E8;
    color: #000;
    border-radius: 4px;
    outline: none;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    border: 1px solid #D9D9D9;
}

select.customer-list option{
    background-color:#fff;
    border: 1px solid var(--Stroke, rgba(0, 0, 0, 0.10));
}

div.radio-btn {
    display: flex;
    gap: 20px;
}

div.radio-btn p{
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 400;
}

div.radio-btn p>input{
    margin-top:0;
    width: 20px;
    height: 20px;
}
.modal-mask {
  position: fixed;
  z-index: 999899989998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 385px;
  margin-left: auto;
    margin-right: auto;
  /* margin: 0px auto; */
  padding: 1px 0px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.close-btn {
    padding: 3px 7px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}
.branch-title {
    font-size: 16px;
    font-weight: 700;
}
.blue-text {
  color: blue;
}
</style>