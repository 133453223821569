<template>

<div>
    <nav-bar></nav-bar>
	<main id="main" class="cart">
		
		<section class="cart-content parten-bg">
			<div class="container">
				
				<div class="row cart-header hidden-xs" style="margin-top:15px;">
					<div class="col-md-6 col-sm-10 cart-title">
						<h1>Shopping cart ({{ userCartItems.length }})</h1>
						<p>If you have any queries, our Customer Services team will be happy to help — just call +44 (0)845 260 3880</p>
					</div>
					<!-- <div class="col-md-3 col-sm-2 continue-shopping">
						<a href="#" title="Continue shopping">
							Continue Shopping 
							<i class="fa fa-arrow-circle-o-right"></i>
						</a>
					</div> -->
				</div><!-- Cart title : End -->
				<div class="row">
					<!--Cart main content : Begin -->
					<section class="cart-main col-md-9 col-xs-12">
						<!--Cart Item-->
						<p class="visible-xs mobile-cart-title">There are 2 items in your cart.</p>
						<div class="table-responsive">
                            <table cellspacing="0" class="table-cart table">
                                <thead class="hidden-xs">
                                    <tr>
                                        <th class="product-info" style="width: 55%;">Products</th>
                                        <th class="product-price">Price</th>
                                        <th class="product-quantity">Qty</th>
                                        <th class="product-discount"> Discount</th>
                                        <!-- <th class="product-wishlist">Move to wishlist</th> -->
                                        <!-- <th class="product-subtotal">Total</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="cart_item" v-for="(item, index) in userCartItems" :key="item._id">
                                        <td class="product-info">
                                            <div class="product-image-col">
                                                <a class="product-image" title="product card">
													<img class="cart-images" :src="$store.state.URL+'/uploads/artworks/'+item.artwork" style="max-width:150px;" v-if="item.artwork">
													<img class="cart-images" :src="$store.state.URL+'/uploads/product/'+item.product.image" style="max-width:150px;" v-else>
                                                    <!-- <img :src="$store.state.URL+'/uploads/product/'+item.product.image" alt="product card"> -->
                                                </a>
                                                <div class="product-action hidden-xs">
                                                        <!-- <a href="#" class="cart-edit" title="Edit Product">
                                                                <i class="fa fa-pencil-square-o"></i>
                                                        </a> -->
                                                        <a href="#" class="cart-delete" title="Delete Product" @click="removeCartItem(item)">
                                                                <i class="fa fa-times"></i>
                                                        </a>
                                                        <!-- <a href="#" class="cart-update" title="Update Product">
                                                                <i class="fa fa-refresh"></i>
                                                        </a> -->
                                                </div>
                                        </div>
                                        <div class="product-info-col">
											<h3 class="product-name">{{ item.product ? item.product.productName : `${item.quote_id.quotesProduct} (${item.quote_id.quoteNumber})` }} </h3>
                                                <ul class="pro-option" v-if="item.opData">
                                                        <li v-if="item.opData.bookletPagination !== ''">
                                                                <span class="pro-opt-label">Booklet Pagination: </span>
                                                                <span class="pro-opt-value">{{ item.opData.bookletPagination }}</span>
                                                        </li>
														<li v-if="item.opData.bookletType !== ''">
                                                                <span class="pro-opt-label">Booklet Type: </span>
                                                                <span class="pro-opt-value">{{ item.opData.bookletType }}</span>
                                                        </li>
														<li v-if="item.opData.foldingOption !== ''">
                                                                <span class="pro-opt-label">Folding Option: </span>
                                                                <span class="pro-opt-value">{{ item.opData.foldingOption }}</span>
                                                        </li>
														<li v-if="item.opData.printed !== 'None'">
                                                                <span class="pro-opt-label">Print Sides: </span>
                                                                <span class="pro-opt-value">{{ item.opData.printed }}</span>
                                                        </li>
														<li v-if="item.opData.productPaperSizeFormat !== ''">
                                                                <span class="pro-opt-label">Paper Size: </span>
                                                                <span class="pro-opt-value">{{ item.opData.productPaperSizeFormat }}</span>
                                                        </li>
														<li v-if="item.opData.productPaperTypeWeight !== ''">
                                                                <span class="pro-opt-label">Paper Weight: </span>
                                                                <span class="pro-opt-value">{{ item.opData.productPaperTypeWeight }}</span>
                                                        </li>
                                                       
                                                         <li class="product-price hidden-lg hidden-md hidden-sm">
                                                             <span class="pro-opt-label">Price:</span>
                                                             <span class="pro-opt-value"  style="color: #25bce9;">&euro;  {{ Number(item.quantity.sellingProductPrice).toFixed(0) }}</span>
                                                        </li>
                                                </ul>
												<ul class="pro-option" v-else>
													<li v-if="item.quote_id !== ''">
                                                        <span class="pro-opt-label">Quote ID No: </span>
                                                        <span class="pro-opt-value">{{ item.quote_id.quoteNumber }}</span>
                                                    </li>	
													<li v-if="item.quote_id !== ''">
                                                    	<span class="pro-opt-label">Quote Description: </span>
                                                        <span class="pro-opt-value">{{ item.quote_id.suppliersDesription }}</span>
                                                    </li>

												</ul>
                                        </div>
                                        </td>
                                        <td class="product-price hidden-xs">
                                            <span>&euro;  {{ $LFT(item.quantity.sellingProductPrice) }}</span>
                                        </td>
                                        <td class="product-quantity hidden-xs"> 
											<span>{{ item.quantity.quantity }}</span>
                                        	<!-- <input type="number"  v-model="item.quantity" name="qty" id="qty" style="width: 100px;"> -->
                                        </td>
                                        <td class="product-discount hidden-xs"> 
                                            &euro;  {{ item.quote_id ? $LFT(item.quantity.sellingProductPrice) :
												$LFT(
													item.discountPCPAm 
													+
													item.discountPromoCodeAm
													+
													((item.quantity.sellingProductPrice * spendDiscount) / 100)
													 ) }}
											<br><span v-if="item.discountPCP"> PCP Applied: &euro; 
												{{ $LFT(item.discountPCPAm ) }} 
											</span>
											<br>
											<span v-if="item.discountPromoCode"> Promotion Applied: &euro; 
												{{ $LFT(item.discountPromoCodeAm ) }} </span><br>
												<span v-if="spendDiscount"> Spend Applied: &euro; 
												{{ $LFT((item.quantity.sellingProductPrice * spendDiscount) / 100) }} </span>	
                                        </td>
                                        <!-- <td class="product-wishlist hidden-xs checkbox-w">
                                        	<input type="checkbox" id="addWishlist01" name="move-wishlist" checked>
                                        	<label for="addWishlist01" class="clearfix">
                                        		<i class="fa fa-square-o"></i>
                                        		<i class="fa fa-check-square-o"></i>
                                        	</label>
                                        </td> -->
                                        <td class="product-subtotal hidden-xs">
                                        	<span>&euro; {{ item.quote_id ? $LFT(item.quantity.sellingProductPrice) :
												$LFT(
													item.quantity.sellingProductPrice
													 - (
														item.discountPCPAm
														 + 
														 item.discountPromoCodeAm
														 +
														 ((item.quantity.sellingProductPrice * spendDiscount) / 100)
														 ) )  }} </span>
                                        </td>
                                    </tr>
                                    
                                </tbody>
                            </table>
                        </div>
						<!--Cart Item-->
						<!-- <div class="row update-wishlist">
							<div class="col-sm-12 hidden-xs">
								<button type="button" name="update-wishlist" class="gbtn btn-update-wishlist">
									<i class="fa fa-refresh"></i>
									Update Wishlist
								</button>
							</div>
						</div> -->
						<div class="row cart-bottom">
						
							<div class="col-sm-6 discount-code clearfix">
								<h3>Discount Codes</h3>
								<p>Do you have a promotional code?</p>
								<form action="#" method="POST" name="discount-code">
									<div class="form-group">
    									<input type="text" class="form-control" v-model="PROMOCODE" id="discountCode" placeholder="Promo Code" :disabled="preferentialMessage.isDisabled">
									</div>
									<button  class="gbtn btn-discount" @click.prevent="checkPromo">Apply Coupon</button>
									<span class="text-danger" v-if="userCartItems.length > 0">
										{{ preferentialMessage.message }}
									 </span>
								</form>
							</div><!--Discount Code: End-->
							<div class="col-sm-6 subtotal clearfix">
								<h3>Sub total</h3>
								<p>Checkout with Multiple Addresses</p>
								<ul>
									<!-- <li>
										<span class="sub-title">Sub Total</span>
										<span class="sub-value">&euro; 20.26</span>
									</li>  -->

									<li class="grand-total">
										<span class="sub-title">Sub Total</span>
										<span class="sub-value">&euro; {{ $LFT(total) }} </span>
									</li>
									<li class="grand-total">
										<span class="sub-title">VAT</span>
										<span class="sub-value">&euro; {{ $LFT(VAT) }} </span>
									</li><li class="grand-total">
										<span class="sub-title">Grand Total</span>
										<span class="sub-value">&euro; {{ $LFT((total + VAT)) }} </span>
									</li>
								</ul>
								<button type="button" class="gbtn btn-checkout" @click="proceedCheckout">Proceed Checkout</button>
							</div>
						</div>
					</section><!-- Cart main content : End -->
					<!--Cart right banner: Begin-->
					<section class="col-sm-3 cart-right-banner hidden-sm hidden-xs">
						<div class="cart-right-banner">
							<a href="#" title="cart right banner">
								<img src="/images/banner/cart/cart-right-banner.jpg" alt="cart right banner">
							</a>
						</div>
					</section><!--Cart right banner: End-->
				</div>
				
			</div>
		</section>
	</main><!-- Main Category: End -->
	<!--Footer : Begin-->
    <footer-section></footer-section>
  </div>
</template>

<script>
import moment from 'moment'
import NavBar from "@/components/NavBar.vue"
import FooterSection from "@/components/FooterSection.vue"
import {mapState} from 'vuex'
export default {
components:{NavBar, FooterSection},
data(){
	return {
		PROMOCODE:'',
		promoError:'',
	}
},
computed:{
	...mapState(['user','userCart','userCartItems','userPCP','userOrders']),
	// ((item.product.sellingProductPrice / item.product.quantity ) * item.quantity)
	// (( ((item.product.sellingProductPrice / item.product.quantity ) * item.quantity) * item.discountPCP ) / 100 )
	// (( ((item.product.sellingProductPrice / item.product.quantity ) * item.quantity) * item.discountPromoCode ) / 100 )
	completedOrders(){
		if (this.userOrders) {
			let orders = this.userOrders.filter((order) => { return order.isOrderPaid });
			return orders;
		}else{
			return []
		}
	},
	completedOrdersSpend(){
		if (this.completedOrders) {
			let orders = 0;
			this.completedOrders.forEach(element => {
				element.items.forEach(elm => {
					orders += elm.quantity.sellingProductPrice 
				});
			});
			
			return orders;
		}else{
			return 0
		}
	},
	spendDiscount(){
		if ( this.completedOrdersSpend ) {
			let am = 0;
			if (this.completedOrdersSpend >1999 ) am += 2 
			if (this.completedOrdersSpend >2999 ) am += 2
			return am 
		} else {
			return 0
		}
	},
	total(){
		if (this.userCartItems.length) {

			let total = 0;

			this.userCartItems.forEach(element => {
				total += element.quote_id 
					? element.quantity.sellingProductPrice 
					: ((element.quantity.sellingProductPrice) 
					- (element.discountPCPAm 
						+ element.discountPromoCodeAm 
						+ element.spendDiscountAm));
			});

			return Number(total.toFixed(2))
		}
		return 0;
	},
	VAT(){
		if (this.userCartItems.length) {

			let total = 0;

			this.userCartItems.forEach(element => {
				let totals = element.quote_id 
						? element.quantity.sellingProductPrice 
						: (
						(element.quantity.sellingProductPrice) 
						- (
							element.discountPCPAm
							+ element.discountPromoCodeAm
							+ element.spendDiscountAm
						)
						);
				
						 let vatOrPrice = element.product !== undefined && element.product !== null
						? element.product.product.supplierVAT
						: element.quantity.supplierVATNumber !== undefined && element.quantity.supplierVATNumber !== null
						? element.quantity.supplierVATNumber
						: 0;

				let tt =  ((totals * vatOrPrice / 100) )
				total += Number(tt)
			});

			return Number(total.toFixed(2))
		}
		return 0;
	},
	preferentialMessage() {
      let message = '';
      let isDisabled = false;

      if (this.userCartItems.length === 1) {
        message = this.userCartItems[0].discountPCP
          ? 'Preferential Pricing agreements already applied on product.'
          : this.promoError;
        isDisabled = this.userCartItems[0].discountPCP > 0;
      } else if (this.userCartItems.length > 1) {
        for (let item of this.userCartItems) {
        if (item.quote_id && item.discountPCP == 1 || (item.product && item.discountPCP > 1)) {
            message = 'Preferential Pricing agreements already applied on product Rahul.';
            isDisabled = true;
          }
		  else{
	      	isDisabled = false;
		  }
        }
        if (!isDisabled) {
          message = this.promoError;
        }
      }
      
      return { message, isDisabled };
    },
},
async mounted(){
	await this.$store.dispatch("getPcps")
	setTimeout(() => {
		this.checkDiscounts()
	}, 3000);
},
methods:{
	async removeCartItem(item){
		console.log("removing cart item", item)
		await this.$store.dispatch("removeCartItem",{item})
		await this.$store.dispatch("getUser")
		
	},
	handleQtyChange(index){
		let num = Number(this.$store.state.userCartItems[index].quantity)
		console.log(num)
		if (num) {
			this.$store.state.userCartItems[index].price = ((this.$store.state.userCartItems[index].product.sellingProductPrice / this.$store.state.userCartItems[index].product.quantity ) *  num)
		}
		
	},
	async proceedCheckout(){
		let cartItems = [];
		this.userCartItems.forEach(element => {
			cartItems.push({
				id: element._id,
				discountPCP: element.discountPCP,
				discountPromoCode: element.discountPromoCode,
				discountPCPAm: element.discountPCPAm,
				discountPromoCodeAm: element.discountPromoCodeAm,
				spendDiscount: this.spendDiscount,
				spendDiscountAm: Number(((element.quantity.sellingProductPrice * this.spendDiscount) / 100).toFixed(2)),
				promoCode: element.promoCode,
				// total:Number(this.total + this.VAT),
				// quantity: element.quantity,
			})
		});
		let dec = await this.$store.dispatch("updateCartItems", {items: cartItems})
		if (dec.status) {
			this.$router.push({path:'/checkout'})
		}
	},
	async checkPromo(){
		this.promoError = ''
		let dec = await this.$store.dispatch("checkPromo", { code: this.PROMOCODE });
		if (dec.status) {
			console.log(dec.code)
			if (moment().isBetween(dec.code.startAt, dec.code.endAt)) {	
				console.log("code is in valid date range")


				if (dec.code.discountOn == 'selected') {
				console.log("userPCP is selected")
				dec.code.products.forEach(element => {
					console.log("loop check on userPCP supported products")
					if (this.$store.state.userCartItems.length) {
						console.log("loop check on cart items started")
						this.$store.state.userCartItems.forEach((elm, index) => {
							console.log("loop check on cart items")
							if (elm.product._id == element) {
								if(elm.discountPromoCode){
									this.promoError = 'Promotion Code Discount Already Applied On '+elm.product.productName
								}else{
									// let dc = ((elm.price * dec.code.discount) / 100)
									// console.log("found match on cart item on userPCP supported item", dc)
									this.$store.state.userCartItems[index].discountPromoCode = dec.code.discount
									this.$store.state.userCartItems[index].promoCode = dec.code.code
								this.$store.state.userCartItems[index].discountPromoCodeAm = ((this.$store.state.userCartItems[index].quantity.sellingProductPrice * dec.code.discount) / 100)

								}
								
							}
						});
					}
				});
			}else{
				if (this.$store.state.userCartItems.length) {
						console.log("loop check on cart items started")
						this.$store.state.userCartItems.forEach((elm, index) => {
							console.log("loop check on cart items")
							// if (elm.product._id == element._id) {
								let dc = ((elm.price * dec.code.discount) / 100)
								console.log("found match on cart item on userPCP supported item", dc)
								this.$store.state.userCartItems[index].discountPromoCode = dc
							// }
							if(elm.discountPromoCode){
									this.promoError = 'Promotion Code Discount Already Applied On '+elm.product.productName
								}else{
									// let dc = ((elm.price * dec.code.discount) / 100)
									// console.log("found match on cart item on userPCP supported item", dc)
									this.$store.state.userCartItems[index].discountPromoCode = dec.code.discount
									this.$store.state.userCartItems[index].promoCode = dec.code.code
								this.$store.state.userCartItems[index].discountPromoCodeAm = ((this.$store.state.userCartItems[index].quantity.sellingProductPrice * dec.code.discount) / 100)

								}
						});
					}
			}


		}else{
				this.promoError = "The Code is not Useful"
			}
		}else{
			this.promoError = dec.message;
		}
	},
	async checkDiscounts(){
		console.log("check discount triggered")
	await this.$store.dispatch("getPcps")

		if (this.userPCP !== null) {
			if (moment().isBetween(this.userPCP.startAt, this.userPCP.endAt)) {
				console.log("found user PCP")
			if (this.userPCP.discountOn == 'selected') {
				console.log("userPCP is selected")
				this.userPCP.products.forEach(element => {
					console.log("loop check on userPCP supported products")
					if (this.$store.state.userCartItems.length) {
						console.log("loop check on cart items started")
						this.$store.state.userCartItems.forEach((elm, index) => {
							console.log("loop check on cart items")
							if (elm.product._id == element._id) {
								// let dc = ((elm.price * this.userPCP.discount) / 100)
								// console.log("found match on cart item on userPCP supported item", dc)
								this.$store.state.userCartItems[index].discountPCP = this.userPCP.discount
								this.$store.state.userCartItems[index].discountPCPAm = ((this.$store.state.userCartItems[index].quantity.sellingProductPrice * this.userPCP.discount) / 100)
							}
						});
					}
				});
			}else{
				if (this.$store.state.userCartItems.length) {
						console.log("loop check on cart items started")
						this.$store.state.userCartItems.forEach((elm, index) => {
							console.log("loop check on cart items")
							// if (elm.product._id == element._id) {
								// let dc = ((elm.price * this.userPCP.discount) / 100)
								// console.log("found match on cart item on userPCP supported item", dc)
								this.$store.state.userCartItems[index].discountPCP = this.userPCP.discount
								this.$store.state.userCartItems[index].discountPCPAm = ((this.$store.state.userCartItems[index].quantity.sellingProductPrice * this.userPCP.discount) / 100)

							// }
						});
					}
			}	
			}
			
		}else{
			console.log("userPCP found NULL")
		}
	}
},
}

</script>

<style>
.mapouter{position:relative;text-align:right;height:100%;width:100%;}
.gmap_canvas {overflow:hidden;background:none!important;height:100%;width:100%;}
</style>