<template>
	<div>
		<nav-bar></nav-bar>
		<div>
			<dashboard-stats></dashboard-stats>

		</div>
		<div>
			<section class="cart-main col-xs-12" style="min-height:auto;">
				<main>
					<h3 class="acc-title lg">Orders/Jobs</h3>
				</main>
				<!--Cart Item-->
				<p class="visible-xs mobile-cart-title">There are 2 items in your cart.</p>
				<div class="jobs-tabs" v-if="selectedItem">
					<!-- <button class="btn btn-danger btn-sm" style="float: left;" > Back </button> -->
					<!-- <br> -->
					<!-- <br> -->
					<div class="tabs">
						<span v-if="!user.isSubChildUser" class="tab" :class="{ 'selected': tab == 0 }"
							@click="tab = 0">DETAILS</span>
						<span class="tab" :class="{ 'selected': tab == 1 }" @click="tab = 1">STATUS</span>
						<span v-if="(selectedItem.items[0].product && selectedItem.items[0].product.product.nonPrint != 'Non Print') ||  (selectedItem.items[0].quote_id && selectedItem.items[0].quote_id.productProcess != 'Non Print')" class="tab" :class="{ 'selected': tab == 2 }" @click="tab = 2">ARTWORK</span>

						<!-- <span v-if="selectedItem.items[0].product.product.nonPrint != 'Non Print'" class="tab"
							:class="{ 'selected': tab == 2 }" @click="tab = 2">ARTWORK</span> -->

						<span class="tab" :class="{ 'selected': tab == 3 }" @click="tab = 3">BILLING & DELIVERY</span>
						<span class="tab" :class="{ 'selected': tab == 4 }" @click="tab = 4">Notifications</span>
						<span class="tab" :class="{ 'selected': tab == 5 }" @click="tab = 5">Order History</span>
						<span class="tab" @click="refresh">BACK</span>
					</div>
					<div v-if="tab == 5">
						<p class="item-details head"> <span class="first"> <b> Order History </b> </span> </p>
						<template v-if="orderActivs[selectedItem['orderNumber']]?.length > 0">
							<tr v-for="ordAct in orderActivs[selectedItem['orderNumber']]" :key="ordAct.order">
								<td>
									<p class="item-details sec">
										<span class="first">
											<b>
												{{ 
												(selectedItem.items[0].product && selectedItem.items[0].product.product.nonPrint == 'Non Print' && ordAct.title == 'Artwork Approved') || 
												(selectedItem.items[0].quote_id && selectedItem.items[0].quote_id.productProcess == 'Non Print' && ordAct.title == 'Artwork Approved') 
												? 'Order Approved' 
												: ordAct.title 
												}}
											</b>
											</span>
											<span class="second">
												{{ 
													(selectedItem.items[0].product && selectedItem.items[0].product.product.nonPrint == 'Non Print' && ordAct.title == 'Artwork Approved') || 
													(selectedItem.items[0].quote_id && selectedItem.items[0].quote_id.productProcess == 'Non Print' && ordAct.title == 'Artwork Approved') 
													? 'Order Approved' 
													: ordAct.title 
												}} 
												at {{ new Date(ordAct.createdAt).toDateString() }}
											</span>
									</p>
								</td>
							</tr>
						</template>
						<template v-else>
							<tr>
								<td>
									<p class="item-details sec">
										<span class="first"><b>Status:</b></span>
										<span class="first"><b>{{ selectedItem['status'] }}</b></span>
									</p>
								</td>
							</tr>
						</template>
					</div>
					<div v-if="tab == 4">
						<div v-if="selectedItem.isSubChildID.length > 0 && selectedItem.isSubChildID[0] != ''">
							<h3 class="jobs-email">Email Configured for Notifications : <b>{{ selectedItem.isSubChildID[0].email }}</b> </h3>
							<h3 class="jobs-email">Email Configured for Parent Notifications : <b>{{ selectedItem.orderEmail }}</b> </h3>
						</div>
						<div v-else>
							<h3 class="jobs-email">Email Configured for Notifications : <b>
									{{ selectedItem.user.email ? selectedItem.user.email : user.email }}</b> </h3>
						</div>
						<!-- <div  v-if="selectedItem.user.isSubChildUser ">
									<h3>Email Configured for Parent Notifications : <b>{{ user.email }}22</b> </h3>
								</div> -->
						<div v-if="!cEmail">
							<!-- <h4 > <button class="btn btn-primary" style="float: right;" @click="changeEmail()"> Change </button> </h4> -->
						</div>
						<div v-else>
							<label for="">Please Provide a Valid Email Address</label>
							<input type="email" name="" id="" v-model="cEmailValue" class="form-control">
							<button class="btn btn-primary" @click="updateCEmail()"> Update Email </button>
							<span class="text-danger" v-if="cEmailError.length">{{ cEmailError }}</span>
							<span class="text-success" v-if="cEmailSuccess.length"> {{ cEmailSuccess }}</span>
						</div>
					</div>
					<div v-if="tab == 0">
						<p class="item-details"> <span class="first"> <b> ID </b> </span> <span class="second"> {{
							selectedItem.orderNumber }}</span> </p>
						<p class="item-details sec"> <span class="first"> <b> status </b> </span> <span class="second"> {{
							selectedItem.status }}</span> </p>
						<p class="item-details"> <span class="first"> <b> Payment </b> </span> <span class="second"> {{
							(selectedItem.isOrderPaid) ? "PAID" : "NOT PAID" }}</span> </p>
						<p class="item-details sec"> <span class="first"> <b> Payment Method </b> </span> <span
								class="second"> {{ selectedItem.paymentMethod }}</span> </p>
						<p class="item-details"> <span class="first"> <b> Shipping Method </b> </span> <span class="second">
								{{ selectedItem.shippingMethod }}</span> </p>
						<p class="item-details sec"> <span class="first"> <b> Product </b> </span>
							<span class="second" v-if="selectedItem.isOrderCustom"> Custom Order</span>
							<span class="second" v-else> {{ selectedItem.items[0].product ? selectedItem.items[0].product.productName : selectedItem.items[0].quote_id.quotesProduct }}</span>
						</p>
						<p class="item-details"> <span class="first"> <b> Description </b> </span>
							<span class="second" v-if="selectedItem.isOrderCustom"> Custom Order</span>
							<span class="second" v-else> {{ selectedItem.items[0].product ? selectedItem.items[0].product.productMktDesc : selectedItem.items[0].quote_id.suppliersDesription }}</span>
						</p>
						<p class="item-details sec"> <span class="first"> <b> Quantity </b> </span> <span class="second"> {{
							selectedItem.items[0].quantity.quantity }}</span> </p>
						<br>
						<div
							v-if="selectedItem.items[0].discountPromoCode || selectedItem.items[0].discountPCP || selectedItem.items[0].spendDiscount">

							<p class="item-details head"> <span class="first"> <b> Discounts: </b> </span> </p>

							<p class="item-details"> <span class="first"> <b> Promotion <i
											v-if="selectedItem.items[0].discountPromoCode">({{
												selectedItem.items[0].discountPromoCode }}%)</i> </b> </span>
								<span class="second"> {{ selectedItem.items[0].discountPromoCodeAm }}</span>
							</p>
							<p class="item-details"> <span class="first"> <b> Original Product Price <i
											v-if="selectedItem.items[0].quantity.sellingProductPrice"></i> </b> </span>
								<span class="second"> {{ selectedItem.items[0].quantity.sellingProductPrice }}</span>
							</p>
							<p class="item-details sec"> <span class="first"> <b> Preferential <i
											v-if="selectedItem.items[0].discountPCP">({{ selectedItem.items[0].discountPCP
											}}%)</i> </b> </span>
								<span class="second"> {{ selectedItem.items[0].discountPCPAm }}</span>
							</p>
							<!-- <p class="item-details"> <span class="first"> <b> Spends <i
											v-if="selectedItem.items[0].spendDiscount">({{
												selectedItem.items[0].spendDiscount }}%)</i> </b> </span>
								<span class="second"> {{ selectedItem.items[0].spendDiscountAm }}</span>
							</p> -->
						</div>
						<br>
						<p class="item-details head"> <span class="first"> <b> Totals: </b> </span> </p>
						<p class="item-details"> <span class="first"> <b> Subtotal </b> </span>
							<span class="second"> {{ $LFT(selectedItem.items[0].quantity.sellingProductPrice - (
								selectedItem.items[0].discountPromoCodeAm + selectedItem.items[0].discountPCPAm +
								selectedItem.items[0].spendDiscountAm)) }} </span>
						</p>
						<p class="item-details"> <span class="first"> <b> VAT </b> </span>
							<span class="second"> {{ $LFT(((selectedItem.items[0].quantity.sellingProductPrice - (
								selectedItem.items[0].discountPromoCodeAm + selectedItem.items[0].discountPCPAm +
								selectedItem.items[0].spendDiscountAm)) * selectedItem.items[0].vat) / 100) }} </span>
						</p>
						<p class="item-details sec"> <span class="first"> <b> TOTAL </b> </span>
							<span class="second"> {{ $LFT(
								(selectedItem.items[0].quantity.sellingProductPrice - (
									selectedItem.items[0].discountPromoCodeAm + selectedItem.items[0].discountPCPAm +
									selectedItem.items[0].spendDiscountAm))
								+
								(((selectedItem.items[0].quantity.sellingProductPrice - (
									selectedItem.items[0].discountPromoCodeAm + selectedItem.items[0].discountPCPAm +
									selectedItem.items[0].spendDiscountAm)) * selectedItem.items[0].vat) / 100)) }}

							</span>
						</p>

					</div>
					<div v-if="tab == 1">
						<p class="item-details head"> <span class="first"> <b> STATUS </b> </span> <span class="second">
								{{ selectedItem.status }} </span> </p>
					</div>
					<div v-if="tab == 2">
						<h3 class="title">Artworks</h3>
						<div v-for="it in selectedItem.items" :key="it._id">
							<p class="subtitle">{{ it.product ? it.product.productName : it.quote_id.quotesProduct}}</p>
							<a id="downloadLink" style="display: none;"></a>
							<span v-if="it.artwork.length">
								<a :href="$store.state.URL + '/uploads/artworks/' + it.artwork" class="text-danger"
									target="_blank"><b>View Artwork</b></a>
								<span v-if="selectedItem.artworkApproved" class="link text-danger"
									@click="downloadFile($store.state.URL + '/uploads/artworks/' + it.artwork, it.artwork)">
									<b> / Download Artwork</b></span>

							</span>
							<span v-else> <b>Artwork Not Provided</b> </span>
							<div v-if="selectedItem.artworkApproved != true">
								<h3 class="title">Upload Correct Artwork</h3>
								<!-- <p class="p-name subtitle">{{ it.product.productName }}</p> -->
								<div v-if="it.artworkSamples.length">
									<div v-for="(sample, index) in it.artworkSamples" :key="sample">
										<a style="color: blue;" :href="$store.state.URL + '/uploads/artworks-attach/' + sample"
											target="_blank" rel="noopener noreferrer">
											<b>{{ index + 1 }}: {{ sample }}</b>
										</a>
									</div>
								</div>
								<!-- <span v-else> <b>Artwork Samples Not Provided</b> </span> -->
								<!-- <p class="p-title" v-if="selectedItem.artworkComments"> Upload Artwork Sample / Attachment </p> -->
								<p class="p-title" v-if="it.artwork"> Please Upload the Correct Artwork </p>
								<p class="p-title" v-else>
									<b v-if="selectedItem.artworkComment" class="atstatus"> {{ selectedItem.artworkComment
									}} <br></b>
									<b>Please Upload Artwork</b>
								</p>
								<div class="" style="padding:10px;border:2px solid lightgray;width: 301px;">
									<!-- <p> <b>Upload Your Artwork Related Samples and Attachments</b> </p> -->
									<!-- <p>You can email us the artwork to "artwork@discoverprint.ie" after completing the order with order number</p> -->
									<div style="border:1px dotted lightgray;padding:10px;position:relative;">
										<input type="file" name="" id="" class="v-input" @change="handle">

										<img src="/images/download.png" alt="" class="" srcset=""
											style="width:110px;display: block; margin-left:auto; margin-right:auto;">
										<p>Click here to choose the files or drag them here</p>
									</div>
									<div>
									</div>
									<div v-if="$store.state.uploadProgress">
										<b>Uploading:</b> <span> {{ $store.state.uploadProgress }}% </span>
									</div>
									<div v-if="allFiles">
										{{ allFiles.name }}
									</div>
								</div>
								<!-- <div v-if="it.artwork"><button class="btn btn-upload" v-if="allFiles !== null" @click="startUpload(it)" style="width: 291px;margin: 5px;">Upload</button></div> -->
								<div> <button class="btn btn-upload" v-if="allFiles !== null" @click="startUploadArt(it)"
										style="width: 291px;margin: 5px;">Upload Artwork</button> </div>
							</div>


						</div>
					</div>
					<div v-if="tab == 3">
						<p class="item-details head"> <span class="first"> <b> Billing </b> </span> </p>
						<p class="item-details"> <span class="first"> <b> Address Line 1 </b> </span> <span class="second">
								{{ selectedItem.billingAddress.billAddressOne ?  selectedItem.billingAddress.billAddressOne : selectedItem.billingAddress.addressOne}}</span> </p>
						<p class="item-details sec"> <span class="first"> <b> Address Line 2 </b> </span> <span
								class="second"> {{ selectedItem.billingAddress.billAddressTwo ? selectedItem.billingAddress.billAddressTwo : selectedItem.billingAddress.addressTwo }} </span> </p>
						<p class="item-details"> <span class="first"> <b> City </b> </span> <span class="second"> {{
							selectedItem.billingAddress.billCity ? selectedItem.billingAddress.billCity : selectedItem.billingAddress.city }}</span> </p>
						<p class="item-details sec"> <span class="first"> <b> County </b> </span> <span class="second"> {{
							selectedItem.billingAddress.billCounty ? selectedItem.billingAddress.billCounty : selectedItem.billingAddress.county }} </span> </p>
						<p class="item-details"> <span class="first"> <b> Country </b> </span> <span class="second"> {{
							selectedItem.billingAddress.billCountry ? selectedItem.billingAddress.billCountry : selectedItem.billingAddress.country }}</span> </p>
						<p class="item-details sec"> <span class="first"> <b> Post Code </b> </span> <span class="second">
								{{ selectedItem.billingAddress.billPostCode ? selectedItem.billingAddress.billPostCode : selectedItem.billingAddress.postCode }} </span> </p>
						<p class="item-details head"> <span class="first"> <b> Delivery </b> </span> </p>
						<p class="item-details"> <span class="first"> <b> Address Line 1 </b> </span> <span class="second">
								{{ selectedItem.deliveryAddress.addressOne }}</span> </p>
								<p class="item-details sec"> <span class="first"> <b> Address Line 2 </b> </span> <span
								class="second"> {{ selectedItem.deliveryAddress.addressTwo }} </span> </p>
						<p class="item-details"> <span class="first"> <b> City </b> </span> <span class="second"> {{
							selectedItem.deliveryAddress.city }}</span> </p>
						<p class="item-details sec"> <span class="first"> <b> County </b> </span> <span class="second"> {{
							selectedItem.deliveryAddress.county }} </span> </p>
						<p class="item-details"> <span class="first"> <b> Country </b> </span> <span class="second"> {{
							selectedItem.deliveryAddress.country }}</span> </p>
						<p class="item-details sec"> <span class="first"> <b> Post Code </b> </span> <span class="second">
								{{ selectedItem.deliveryAddress.postCode }} </span> </p>
						<p class="item-details"> <span class="first"> <b> Delivery Turnaround </b> </span> <span
								class="second"> {{ selectedItem.items[0].product ? selectedItem.items[0].product.deliveryTurnaround :  selectedItem.items[0].quote_id.quantity.deliveryTurnaround }} </span> </p>


					</div>
					<!-- {{ selectedItem }} -->
				</div>
				<div v-else style="margin: 20px 0;">
					<table class="table-cart table" style="margin: auto;">
						<tr>
							<th style="padding:0px 20px;">ID</th>
							<th>Username</th>
							<th>Order Placed By</th>
							<th>Branch Location</th>
							<th>Description</th>
							<th>Quantity</th>
							<th>Status</th>
							<th>Ordered</th>
							<th>Action</th>
							<!-- <th>Approved</th> -->
							<!-- <th>Shipping </th> -->
							<!-- <th>Due </th> -->
						</tr>
						<tr v-for="order in usOrders" :key="order._id">
							<td class="link" @click="selectedItem = order">{{ order.orderNumber }}</td>

							<td v-if="order.isSubChildID && order.isSubChildID.length > 0 && order.isSubChildID[0] != ''"
								class="link" @click="openModal(order)">
								{{ order.isSubChildID[0].username ? order.isSubChildID[0].username : order.user.username }}
							</td>
							<td v-else class="link" @click="openModal(order)">{{ user.isSubChildUser ? user.username :
								order.user.username }}</td>
							<td>
								<div v-if="order.isSubChildID.length > 0 && order.isSubChildID[0] != ''">
									<h3>HQ</h3>				
								</div>
								<div v-else>					
									<h3 v-if="order.user._id != user._id">Sub Child User</h3>
								</div>
								<div v-if="order.user._id == user._id">
									<h3>HQ</h3>					
								</div>
								
							</td>
							<td>
									AddressOne : {{ order['deliveryAddress']['addressOne'] }}<br>
									AddressTwo : {{ order['deliveryAddress']['addressTwo'] }}<br>
									City : {{ order['deliveryAddress']['city'] }}<br>
									County : {{ order['deliveryAddress']['county'] }}<br>
									Country : {{ order['deliveryAddress']['country'] }}<br>
							</td>
							

							<!-- <td>{{ (order.items[0].product ? order.items[0].product.productName : order.items[0].quote_id.quotesProduct) + ',' + (order.items[0].product ? order.items[0].product.productDesc : order.items[0].quote_id.suppliersDesription) }}</td> -->
							 <td>
								<div>
									<span v-if="isExpanded(order._id)">
										{{ getDescription(order) }}
										<a href="#" v-if="isLongDescription(order)" @click.prevent="toggleDescription(order._id)">See less</a>
									</span>
									<span v-else>
										{{ getShortDescription(order) }}
										<a href="#" v-if="isLongDescription(order)" @click.prevent="toggleDescription(order._id)">See more</a>
									</span>
								</div>
							 </td>
							<td>{{ order.items[0].quantity.quantity }}</td>
							<td>
								{{ 
										order.items[0].product 
											? (order.items[0].product.product.nonPrint == 'Non Print' && order.status == 'Artwork Approved' ? 'Order Approved' : order.status) 
											: (order.items[0].quote_id.productProcess == 'Non Print' && order.status == 'Artwork Approved' ? 'Order Approved' : order.status) 
										}}
							</td>
							<!-- <td>{{ order.items[0].product.product.nonPrint == 'Non Print' && order.status == 'Artwork Approved' ? 'Order Approved' : order.status }}</td> -->

							<td>{{ new Date(order.createdAt).toDateString() }}</td>
							<td v-if="order.status == 'Order Completed & Shipped'">
								<a v-if="order.items[0].quote_id"
								:href="'/single-product/' + order.items[0].quote_id._id"
								title="product"
								class="product-name">Re-order</a>
								<a :href="'/custom-product/re-order/' + order._id" title="product"
									class="product-name" v-else>Re-order</a>
							</td>
							<!-- <td class="link" @click="reOrderItem(order)">Re-order</td> -->
							<!-- 							
							<td v-if="order.approvedAt">{{ new Date(order.approvedAt).toDateString() }}</td>
							<td v-else> Waiting Approval </td> -->
							<!-- 
							<td v-if="order.deliveryAt">{{ new Date(order.deliveryAt).toDateString() }}</td>
							<td v-else>Not Assigned</td> -->
							<!-- <td v-if="order.dueOn">{{ new Date(order.dueOn).toDateString() }}</td>
							<td v-else>Not Assigned</td> -->
							<!-- <td>Order Delivery Address</td> -->
							<!-- <td> <span v-if="order.deliveryAt">{{ new Date(order.deliveryAt).toDateString() }}</span> </td> -->
							<!-- <td>{{ order.paymentMethod }}</td> -->
							<!-- <td>{{ order.shippingMethod }}</td> -->
							<!-- <td>{{ order.status }}</td> -->
						</tr>
					</table>

				</div>

			</section><!-- Cart main content : End -->
			<!--Cart right banner: Begin-->
			<footer-section></footer-section>
			<div class="modal-mask" v-if="orderDetails == true">
				<div class="modal-wrapper">
					<div class="modal-container">


						<div class="modal-body">
							<button class="btn btn-danger close-btn" style="float:right;" @click="closeModal">
								<b>&#10006;</b>
							</button>
							<h3 class="branch-title" style="text-align: center; font-weight: bold;"><span
									class="icon icon-one"></span>ORDERS/JOBS Details</h3><br>
							<div v-if="!user.isSubChildUser" style="margin: 20px 0;">
								<h3 class="text-left acc-title lg" style="margin: 5px;">User Details</h3><br>
								<table class="table-cart table" style="margin: auto;">
									<tr>
										<th style="padding:0px 20px;">ID</th>
										<th>Name</th>
										<th>Business Name</th>
										<th>Email</th>

									</tr>
									<tr v-if="user.isUserType === 'dentalcare'">
										<td>{{ selectedOrderNumber['isSubChildID'][0] != '' ?
											selectedOrderNumber['isSubChildID'][0]['userCode'] :
											selectedOrderNumber['user']['userCode'] }}</td>
										<td>{{ selectedOrderNumber['isSubChildID'][0] != '' ?
											selectedOrderNumber['isSubChildID'][0]['name'] :
											selectedOrderNumber['user']['name'] }} {{
		selectedOrderNumber['isSubChildID'][0]['lastName'] ?
		selectedOrderNumber['isSubChildID'][0]['lastName'] :
		selectedOrderNumber['user']['lastName'] }}</td>
										<td>{{ selectedOrderNumber['isSubChildID'][0] != '' ?
											selectedOrderNumber['isSubChildID'][0]['accountTitle'] :
											selectedOrderNumber['user']['accountTitle'] }}
										</td>
										<td>{{ selectedOrderNumber['isSubChildID'][0] != '' ?
											selectedOrderNumber['isSubChildID'][0]['email'] :
											selectedOrderNumber['user']['email'] }}
										</td>

									</tr>
									<tr v-else>
										<td>{{ selectedOrderNumber.user.userCode }}</td>
										<td>{{ selectedOrderNumber.user.name }} {{ selectedOrderNumber.user.lastName }}</td>
										<td>{{ selectedOrderNumber.user.accountTitle }}</td>
										<td>{{ selectedOrderNumber.user.email }}</td>
									</tr>

								</table>
							</div>

							<h3 class="text-left acc-title lg" style="margin: 5px;">Order Place</h3><br>
							<div class="row">
								<div class="col-lg-6">
									<h3 class="branch-title" style="font-weight: bold;"><span
											class="icon icon-one"></span>Delivery Address</h3><br>
									AddressOne : {{ selectedOrderNumber['deliveryAddress']['addressOne'] }}<br>
									AddressTwo : {{ selectedOrderNumber['deliveryAddress']['addressTwo'] }}<br>
									City : {{ selectedOrderNumber['deliveryAddress']['city'] }}<br>
									County : {{ selectedOrderNumber['deliveryAddress']['county'] }}<br>
									Country : {{ selectedOrderNumber['deliveryAddress']['country'] }}<br>
								</div>
								<div class="col-lg-6">
									<h3 class="branch-title" style="font-weight: bold;"><span
											class="icon icon-one"></span>Billing Address</h3><br>
									AddressOne : {{ selectedOrderNumber['billingAddress']['billAddressOne']  ? selectedOrderNumber['billingAddress']['billAddressOne'] : selectedOrderNumber['billingAddress']['addressOne'] }}<br>
									AddressTwo : {{ selectedOrderNumber['billingAddress']['billAddressTwo'] ? selectedOrderNumber['billingAddress']['billAddressTwo'] : selectedOrderNumber['billingAddress']['addressTwo'] }}<br>
									City : {{ selectedOrderNumber['billingAddress']['billCity'] ? selectedOrderNumber['billingAddress']['billCity'] : selectedOrderNumber['billingAddress']['city'] }}<br>
									County : {{ selectedOrderNumber['billingAddress']['billCounty'] ? selectedOrderNumber['billingAddress']['billCounty'] : selectedOrderNumber['billingAddress']['county'] }}<br>
									Country : {{ selectedOrderNumber['billingAddress']['billCountry'] ?  selectedOrderNumber['billingAddress']['billCountry'] : selectedOrderNumber['billingAddress']['country']}}<br>
								</div>
							</div>
							<br>
							<h3 class="text-left acc-title lg" style="margin: 5px;">Order History</h3><br>
							<template v-if="orderActivs[selectedOrderNumber['orderNumber']]?.length > 0">
								<tr v-for="ordAct in orderActivs[selectedOrderNumber['orderNumber']]" :key="ordAct.order">
									<td>
										<p class="item-details sec">
											<span class="first"><b>{{ ordAct.title === "Order Created" ? "Order Approved" :
												ordAct.title }}</b></span>
											<span class="second">{{ ordAct.title === "Order Created" ? "Order Approved" :
												ordAct.title }} at {{ new Date(ordAct.createdAt).toDateString() }}</span>
										</p>
									</td>
								</tr>
							</template>
							<template v-else>
								<tr>
									<!-- Handle the case when orderActivs[selectedOrderNumber['orderNumber']] is undefined -->
									<td>
										<p class="item-details sec">
											<span class="first"><b>{{ selectedOrderNumber['status'] }}</b></span>
										</p>
									</td>
								</tr>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue"
import DashboardStats from "./DashboardStats.vue"
import FooterSection from "@/components/FooterSection.vue"
import { mapState } from 'vuex'
export default {
	components: { DashboardStats, NavBar, FooterSection },
	methods: {
		downloadFile(fileUrl, fileName) {
			fetch(fileUrl)
				.then(response => response.blob())
				.then(blob => {
					const url = URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = url;
					link.download = fileName;
					link.click();
					URL.revokeObjectURL(url);
				})
				.catch(error => console.error('Error:', error));
		},

		getDescription(order) {
            const productName = order.items[0].product ? order.items[0].product.productName : '';
            const productDesc = order.items[0].product ? order.items[0].product.productDesc : order.items[0].quote_id.suppliersDesription;
			return order.items[0].quote_id ? productName + ' ' + productDesc : productName + ', ' + productDesc;
        },
        getShortDescription(order) {
            const description = this.getDescription(order);
            const words = description.split(' ');
            if (words.length > 10) {
                return words.slice(0, 10).join(' ') + '...';
            }
            return description;
        },
        toggleDescription(orderId) {
            this.$set(this.expandedDescriptions, orderId, !this.expandedDescriptions[orderId]);
        },
        isExpanded(orderId) {
            return !!this.expandedDescriptions[orderId];
        },
        isLongDescription(order) {
            const description = this.getDescription(order);
            const words = description.split(' ');
            return words.length > 10;
        },

		async startUploadArt(item) {
			if (this.allFiles !== null) {
				let name = Date.now() + this.allFiles.name
				const formData = new FormData();
				formData.append('encType', 'multipart/form-data');

				formData.append('artworks', this.allFiles, name)
				console.log(formData)
				//   let DD = Date.now()
				let dec = await this.$store.dispatch("uploadFilesArtworks", formData)
				//   if (dec.status) { 
				// item.artworkSamples.push(name)
				// let supplierEmail = this.selectedItem.items[0].product.product.supplierEmail
				let supplierEmail = this.selectedItem.items[0].product ? this.selectedItem.items[0].product.product.supplierEmail : this.selectedItem.items[0].quote_id.supplierEmail;

				await this.$store.dispatch('updateArtowrkData', { item, name, order: this.selectedItem, supplierEmail })
				this.selectedItem.artworkComment = ''
				this.selectedItem.status = 'Under Review'
				this.selectedItem.items[0].artwork = name
				//update order artwork status
				// clear artowrk comment
				// update order artwork iten name on cartitem
				this.$store.dispatch("getUser")
				this.allFiles = null;
				setTimeout(() => {
					this.$store.state.uploadProgress = 0
				}, 1000);

			}
			// }

		},

		async startUpload(item) {
			if (this.allFiles !== null) {
				const formData = new FormData();
				formData.append('encType', 'multipart/form-data');
				let name = Date.now() + '.' + this.allFiles.type.replace(/(.*)\//g, '')
				formData.append('artworksattach', this.allFiles, name)
				//   formData.append('file', file, Date.now()+'.'+this.allFiles.type.replace(/(.*)\//g, ''));
				console.log(formData)
				let dec = await this.$store.dispatch("uploadFilesArtworksAttach", formData)
				if (dec.status) {
					item.artworkSamples.push(name)
					await this.$store.dispatch('updateArtowrkSamples', { item })
					this.$store.dispatch("getUser")
					this.allFiles = null;
					setTimeout(() => {
						this.$store.state.uploadProgress = 0
					}, 1000);

				}
			}

		},

		handle(event) {
			// this.images = []
			this.allFiles = []
			this.allFiles = event.target.files[0]
			console.log(event, this.allFiles)
			const reader = new FileReader();
			reader.readAsDataURL(this.allFiles);
			reader.onload = () => {
				// this.images.push(reader.result)
			};

			// this.allFiles.forEach((element) => {

			// })


		},

		async updateCEmail() {
			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.cEmailValue)) {
				let dec = await this.$store.dispatch("updateOrderEmail", { email: this.cEmailValue, order: this.selectedItem.orderNumber })
				if (dec.status) {
					this.$store.dispatch("getUser")

					this.cEmailSuccess = "Order Notification Email has Been Updated."
					this.selectedItem.orderEmail = this.cEmailValue
					setTimeout(() => {
						this.cEmail = false;
					}, 2000);
				}
			} else {
				this.cEmailError = "Invalid Email, Please provide a valid Email"
			}
		},
		changeEmail() {
			let email = { email: this.selectedItem.orderEmail }
			let mail = JSON.stringify(email)
			mail = JSON.parse(mail)
			this.cEmailValue = mail.email;
			this.cEmail = true;
		},
		openModal(order) {
			this.orderDetails = true;
			this.selectedOrderNumber = order;
		},
		closeModal() {
			this.orderDetails = false;
			this.selectedOrderNumber = null;
		},
		reOrderItem(order) {
			tab = 2;
		},
		async refresh(){
        	this.selectedItem = null;
			this.tab = 0;
	
    	},
		
	},
	computed: {
		...mapState(['userOrders', 'user', 'orderActivs','jobId']),
		usOrders() {
			let ps = [];
			this.userOrders.forEach(element => {
				if (element.items[0].product !== null || element.items[0].quote_id !== null) {
					ps.push(element)
				}
			});
			return ps;
		},
	
	},

	async mounted() {
		let jobID = this.$route.params.id;
		if (jobID) {
			try {
				let selectedUserData = await this.$store.dispatch("getJobById", { id: this.$route.params.id });
				this.selectedItem = selectedUserData[0];
				this.tab = 2;
			} catch (error) {
				console.error("Error fetching job by ID:", error);
			}
		}

		this.$store.dispatch("getUser");
	},
	data() {		
		return {
			selectedItem: null,
			tab: '',
			// images:[],
			allFiles: [],
			expandedDescriptions: {},
			cEmail: false,
			cEmailValue: '',
			cEmailError: '',
			cEmailSuccess: '',
			orderDetails: false,
			selectedOrderNumber: null,
		}
	}
}
</script>

<style>
.tabs {
	margin: 13px 0px;
	border-bottom: 1px solid lightgray;
	padding: 5px 0px;
}

.tab {
	color: red;
	padding: 5px 25px;
	cursor: pointer;
}

.selected {
	border-bottom: 4px solid red;
}

.link {
	color: blue;
	font-weight: 800;
}

.item-details .first {
	display: inline-block;
	width: 160px;
}

.item-details {
	padding: 11px 4px;
	margin: 0px;
}

.sec {
	background: aliceblue;
}

.head {
	background: #fd5b4e;
}

.v-input {
	position: absolute;
	z-index: 9999;
	height: 100%;
	border: 2px solid red;
	width: 100%;
	opacity: 0;
}

.atstatus {
	background: #282827;
	color: #FFF;
	font-weight: 600;
	display: block;
	width: 545px;
	max-width: 100%;
	padding: 9px 11px;
}

.title {
	font-size: 32px;
	font-weight: 700;
}

.subtitle {
	font-size: 23px;
	font-weight: 500;
}

.modal-mask {
	position: fixed;
	z-index: 999899989998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-container {
	width: 1200px;
	margin-left: auto;
	margin-right: auto;
	/* margin: 0px auto; */
	padding: 1px 0px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
	margin-top: 0;
	color: #42b983;
}

.modal-body {
	margin: 0px 0;
}

.modal-default-button {
	float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.close-btn {
	padding: 3px 7px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 3px;
}
.jobs-tabs{
	margin: 20px 0;
}
.jobs-email{
	margin-bottom: 10px;
}

/* .c-table > tr > th{ padding:10px; font-weight:600; font-size:14px;} */
/* .c-table > tr > td{ padding:10px; font-size:14px;} */</style>