<template>
    
<div>
    <nav-bar></nav-bar>
    <main class="main">
		<section>
			<div class="ef-image">

			</div>
			
		</section>
		<section id="aboutus" class="pr-main">
			<div class="container" style="width:100%;padding:0px;margin:0px;">			
				
				<div class="ef-header">
				<h2 class="text-center ab-heading" >ONLINE PRINTSHOP</h2>
				<p class="text-center ab-desc">High quality print at competitive prices</p>
			</div>
				<div class="col-md-12 col-sm-12 col-xs-12" style="background:white;">
					<h1 class="text-center my-4 acc-title csc lg text-center" style="border:none;">
						ABOUT ONLINE PRINTSHOP
					</h1>
					<p class="tx-intro" v-html="$store.state.aboutUs.intro"></p>
				</div>
				<div class="col-md-12 col-sm-12 col-xs-12" style="background:white;">
					<h1 style="border: none;" class="acc-title csc lg text-center">MEET THE TEAM</h1>
					<p >We’re passionate about print, but what does that mean for you? It’s a promise that you’ll always receive </p>
					<p>print created on a press that sits at the very forefront of printing technology.</p>
					<div class="col-md-4 col-sm-4 col-xs-12" v-for="member in $store.state.aboutUs.team" :key="member.id">
					  <div>
					  <img :src="getImgUrl(member.image)" height="300" width="300">
					  <h3>{{ member.name }}</h3>
					  <h4>{{ member.role }}</h4>
					  <p>Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. <span>Lorem ipsum dolor</span> sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. </p>
					  </div>
				    </div>
					
				</div>
				<!-- {{ $store.state.aboutUs }} -->
				<div class="col-md-12 col-sm-12 col-xs-12" style="background:white;">
					<h1 style="border: none;" class="acc-title csc lg text-center">SOME OF OUR CLIENTS</h1>
					<div class="col-xs-12" >
						<carousel :autoplay="true" :loop="true" :perPageCustom="[[480, 2], [768, 4]]"  :v-model="6000">
                          <slide v-for="member in $store.state.aboutUs.clients" :key="member.id">
							<a :href="member.link" target="_blank" rel="noopener noreferrer">
								<img :src="$store.state.URL+'/uploads/clients/'+member.image" :alt="member.title" srcset="">
							</a>
                         </slide>
                      </carousel>
				    </div>
				</div>
				<div class="col-md-12 col-sm-12 col-xs-12" style="background:white;">
					<h1 style="border: none;" class="acc-title csc lg text-center">TESTIMONIALS</h1>
					<div class="col-xs-12" >
						<carousel :autoplay="true" :loop="true" :perPageCustom="[[480, 1], [768, 1]]"  :autoplayTimeout="6000">
                          <slide v-for="member in $store.state.aboutUs.tests" :key="member.id">
							<p 
							style="font-size:1.3em;font-style: italic;line-height:1.5;color:#7A7A7A;"
							>
							{{ member.text }}	
							</p>
							<div style="width:600px;margin-left: auto; margin-right:auto;max-width:100%;">
                               <div style="display:inline-block;width:20%;float:left;"> <img :src="$store.state.URL+'/uploads/tests/'+member.image" alt="" style="height:100px;"> </div>
                               <div style="display:inline-block;width:80%;float: right;padding:22px 0px;">
							     <p style="margin-bottom:1px;text-align: left;line-height:1.5;font-size:14px;"> 
									{{ member.title }}
									<!-- Karen Thorpe -->
									
								 </p>
							     <p style="margin-bottom:1px;text-align: left;line-height:1.5;font-size:14px;color: red;" > 
									{{ member.desc }}
									<!-- Events, Player Alumni and Education Manager at Gaelic Players Association. -->
								</p>
							   </div>
							</div>
                         </slide>
                      </carousel>
				    </div>
				</div>
			</div>
		</section>
		
	</main><!--Main index : End-->
	<div >
		<footer-section></footer-section>	
	</div>
</div>
</template>

<script>
import NavBar from "@/components/NavBar.vue"
import FooterSection from "@/components/FooterSection.vue"
export default {
components:{NavBar, FooterSection},
methods:{
	getImgUrl(pet) {
    var images = require.context('../../public/images/team/', false, /\.png$/)
    return images('./' + pet)
  }
},
mounted(){
    this.$store.dispatch("getaboutusPageData")
	setTimeout(() => {
		window.scrollTo(0,5);
		
	}, 500);
}
}
</script>

<style>
.ef-header{
	margin-bottom:62px;
}
.ef-image{
	height:345px;
	top:170px;
	position:fixed;
	width:100%;
	background-image: url('../../public/images/Online-Print-Shop-scaled.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.ab-desc{
    text-align: center;
    color: #FFFEFE !important;
    font-family: "Roboto", Sans-serif !important;
    font-size: 31px !important;
	position:relative !important;
	line-height:110px !important;
    font-weight: 400 !important;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 30%) !important;
}
.ab-heading {
	margin-top:55px;
    color: #FCFCFC !important;
    font-family: "Roboto", Sans-serif !important;
    font-size: 58px !important;
    font-weight: 600 !important;
    text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
	border:none !important;
}

.tx-intro{
	text-align: center;
    font-family: "Poppins", Sans-serif;
    font-weight: 400;
    line-height: 27px !important;
}
.elementor-widget:not(:last-child) {
    margin-bottom: 20px;
}

.elementor:not(.elementor-bc-flex-widget) .elementor-widget-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.elementor-widget-wrap>.elementor-element {
    width: 100%;
}
.elementor-widget-wrap {
    position: relative;
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: start;
    align-content: flex-start;
}
.elementor-section{background-image: url('../../public/images/Online-Print-Shop-scaled.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.csc{
	border: none;
    margin: 20px 0px !important;
    padding: 14px 0px !important;
    color: #FFF !important;
}
</style>