<template>
    
<div>
    <nav-bar></nav-bar>
	<main id="main" class="account dashboard">
		
		<section class="account-content parten-bg">
			<div class="container-fluid" style="margin-top:15px;">
			
				<div class="row acc-dashboard">
					<!--Account Sidebar: End-->
					<!-- <aside class="col-md-3 col-sm-4 col-xs-12 account-sidebar sidebar">
						<h3 class="acc-title lg">Account</h3>
						<ul>
							<li class="active">
								<a href="myaccount-dashboard.html" title="account dashboard">Account Dashboard</a>
							</li>
							<li>
								<a href="myaccount-information.html" title="account dashboard">Account information</a>
							</li>
							<li>
								<a href="myaccount-address.html" title="account dashboard">Address Book</a>
							</li>
							<li>
								<a href="myaccount-order.html" title="account dashboard">My Orders</a>
							</li>
							<li>
								<a href="wishlist.html" title="account dashboard">Wishlist</a>
							</li>
							<li>
								<a href="myaccount-newsletter.html" title="account dashboard">Newsletter Subscriptions</a>
							</li>
						</ul>
					</aside> -->
					<!--Account main content : Begin -->
					<section class="account-main col-md-12 col-xs-12">
						<div class="row">
							<div class="col-12">
								<h3 class="acc-title lg text-center"> WELLCOME VESTIGO LOGISTICS (DUBLIN) TO YOUR CUSTOMER AREA </h3>
							</div>
							<div class="col-12">
								<dashboard-stats></dashboard-stats>
							</div>
							
						</div>
							
						<h3 class="acc-title lg">My Addresses
						</h3>
						<div class="row db-content">
							<div class="db-hello col-xs-12">
								<div class="pad-1015">
									<p class="hello-user">
										Hello, Vestigo User!
										<span class="link ddarker" style="float: right" @click="goTo('/checkout')">Go To Checkout</span>
									</p>
								
								</div>
							</div>
							<div class="col-md-6 col-sm-6 db-contact">
								<h4 class="acc-title h-icon" > Add New Address </h4>
								<div class="" style="padding:10px;">
									<label for="">Address Line 1</label>
								<input type="text" name="" placeholder="Address Line 1" v-model="addLineOne" id="" class="form-control">
								</div>
								<div class="" style="padding:10px;">
									<label for="">Address Line 2(Optional)</label>
								<input type="text" name="" placeholder="Address Line 2" v-model="addLineTwo" id="" class="form-control">
								</div>
								<div class="" style="padding:10px;">
									<label for="">City/Town</label>
								<input type="text" name="" placeholder="City" v-model="city" id="" class="form-control">
								</div>
								<div class="" style="padding:10px;">
									<label for="">County</label>
								<input type="text" name="" placeholder="County" v-model="county" id="" class="form-control">
								</div>
								<div class="" style="padding:10px;">
									<label for="">Country</label>
								<input type="text" name="" placeholder="Country" v-model="country" id="" class="form-control">
								</div>
								<div class="" style="padding:10px;">
									<label for="">Post Code</label>
								<input type="text" name="" placeholder="Post Code" v-model="postCode" id="" class="form-control">
								</div>
								<div class="" style="padding:10px;">
									<label for="">Mobile No</label>
								<input type="number" name="" placeholder="Mobile No" v-model="mobileNumber" id="" class="form-control">
								</div>
								<button class="btn btn-primary form-control" @click="addAddress" :disabled="verify"> Add Address </button>
							</div>
							<div v-if="userAddresses.length == 1 || userAddresses.length == 0">
									<div class="col-md-6 col-sm-6 db-contact" v-for="address in userAddresses" :key="address._id">
										<h4 class="acc-title h-icon" v-if="user.address && address._id == user.address._id">
											 Default Delivery Information
											<!-- <a href="#" class="acc-edit" title="edit address">
												<i class="fa fa-pencil-square-o"></i>
											</a> -->
										</h4>
										<h4 class="acc-title h-icon" v-else>
											Delivery Information 
<!-- 											
											<span class="acc-edit link" title="edit address">
												Set as Default
										</span> -->
										</h4>
										<div class="acc-info-content pad-1015">
											<div>
												<span style="display:inline-block;width:120px;">Business Name:</span>
										        <span style="display:inline-block;width:140px;">{{ user.name }}</span>
											</div>
											<div>
												<span style="display:inline-block;width:120px;">Address Line 1:</span>
										        <span style="display:inline-block;width:140px;">{{ address.addressOne }}</span>
											</div>
											<div>
												<span style="display:inline-block;width:120px;">Address Line 2</span>
										        <span style="display:inline-block;width:140px;">{{ address.addressTwo }}</span>
											</div>
											<div>
												<span style="display:inline-block;width:120px;">City/Town:</span>
										        <span style="display:inline-block;width:140px;">{{ address.city }}</span>
											</div>
											<div>
												<span style="display:inline-block;width:120px;">County:</span>
										        <span style="display:inline-block;width:140px;">{{ address.county }}</span>
											</div>
											<div>
												<span style="display:inline-block;width:120px;">Country:</span>
										        <span style="display:inline-block;width:140px;">{{ address.country }}</span>
											</div>
											<div>
												<span style="display:inline-block;width:120px;">PostCode:</span>
										        <span style="display:inline-block;width:140px;">{{ address.postCode }}</span>
											</div>
											<div>
												<span style="display:inline-block;width:120px;">Mobile Number:</span>
										        <span style="display:inline-block;width:140px;">{{ address.mobileNumber }}</span>
											</div>
											<div>
												<span class="text-danger span-link" v-if="user.address && address._id !== user.address._id"  @click="updateDefaultAddress(address._id)">Set As Default Delivery Address</span>
												<span class="text-danger span-link" v-if="!user.address"  @click="updateDefaultAddress(address._id)">Set As Default Delivery Address</span>
											</div>
											<div >
												<span class="text-danger span-link" v-if="user.billing == null"  @click="updateDefaultBillingAddress(address._id)">Set As Billing Address</span>
												<span class="text-danger span-link" v-else-if="address._id !== user.billing._id" @click="updateDefaultBillingAddress(address._id)">Set As Billing Address</span>
											</div>
											<!-- <div>
												<span style="display:inline-block;width:120px;">Status:</span>
										        <span style="display:inline-block;width:120px;">OFF</span>
											</div> -->
										</div>
									</div>
							</div>
							<div v-else>
								<table class="table table-bordered table-responsive" style="position: relative; top: 20px; margin-bottom: 37px">
										<thead class="acc-title lg">
											<tr>
												<th scope="col">Address Line 1</th>
												<th scope="col">Address Line 2</th>
												<th scope="col">City/Town</th>
												<th scope="col">County</th>
												<th scope="col">Country</th>
												<th scope="col">Post Code</th>
												<th scope="col">Mobile No</th>
												<th scope="col">Set As Default Delivery Address / Billing Address</th>
												<th scope="col">Action</th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="address in userAddresses" :key="address._id">
												<td>{{ address.addressOne }}</td>
												<td>{{ address.addressTwo }}</td>
												<td>{{ address.city }}</td>
												<td>{{ address.county }}</td>
												<td>{{ address.country }}</td>
												<td>{{ address.postCode }}</td>
												<td>{{ address.mobileNumber }}</td>
												<td>
													<div>
														<span class="text-danger span-link" v-if="user.address && address._id !== user.address._id" @click="updateDefaultAddress(address._id)">Set As Default Delivery Address</span>
														<span class="text-danger span-link" v-else-if="!user.address" @click="updateDefaultAddress(address._id)">Set As Default Delivery Address</span>
														<span class="text-success" v-else><b>Active (Delivery Address)</b></span>
													</div>
													<div>
														<span class="text-danger span-link" v-if="user.billing == null" @click="updateDefaultBillingAddress(address._id)">Set As Billing Address</span>
														<span class="text-danger span-link" v-else-if="address._id !== user.billing._id" @click="updateDefaultBillingAddress(address._id)">Set As Billing Address</span>
														<span class="text-success" v-else><b>Active  (Billing Address)</b></span>
													</div>
												</td>
												<td>
													<!-- Delete button -->
													<button class="btn btn-danger" @click="deleteAddress(address._id)" :disabled="isAddressActive(address)">Delete</button>
												</td>
											</tr>
										</tbody>
									</table>


							</div>
							
						</div>
					</section><!-- Cart main content : End -->
				</div>
				
			</div>
		</section>
	</main><!-- Main Category: End -->
	<!--Footer : Begin-->
	<div >
		<footer-section></footer-section>	
	</div>
</div>
</template>


<script>
import NavBar from "@/components/NavBar.vue"
import DashboardStats from "./DashboardStats.vue"
import FooterSection from "@/components/FooterSection.vue"
import {mapState} from 'vuex';

export default {
components:{DashboardStats,NavBar, FooterSection},

computed:{
	...mapState(['user','userAddresses']),
	verify(){
		if (this.addLineOne.length < 1 || this.city.length < 1 || this.county.length < 1 || this.country.length < 1 || this.postCode.length < 1 || this.mobileNumber.length < 1) {
			return true
		}else{
			return false
		}
	}

},
methods:{
	goTo(path){
		location.replace(path)
	},
	async updateDefaultAddress(id){
		this.error = ''
		let dec = await this.$store.dispatch("setDefaultAddress", {id, user: this.user._id})
		if (dec.status) {
			this.$store.dispatch("getUser")
		}else{
			this.error = dec.message
		}
	},
	isAddressActive(address) {
      // Check if the address is active (delivery or billing)
      if (this.user.address && address._id === this.user.address._id) {
        return true; // Disable button if address is active delivery address
      } else if (this.user.billing && address._id === this.user.billing._id) {
        return true; // Disable button if address is active billing address
      } else {
        return false; // Enable button otherwise
      }
    },
	async updateDefaultBillingAddress(id){
		// console.log(id);
		// return
		this.error = ''
		let dec = await this.$store.dispatch("setDefaultBillingAddress", {id, user: this.user._id})
		if (dec.status) {
			this.$store.dispatch("getUser")
		}else{
			this.error = dec.message
		}
	},
	async deleteAddress(addressId) {
		try {
			const dec = await this.$store.dispatch("deleteAddress", addressId)
			if (dec.status) {
				this.$store.dispatch("getUser")
			}else{
				this.error = dec.message
			}
		} catch (error) {
			console.error("Error deleting address:", error);
			throw error;
		}
    },
	async addAddress(){
        if (!this.verify) {
			this.error = ''
			let address = {
				user: this.user._id,
				addressOne: this.addLineOne,
				addressTwo: this.addLineTwo,
				city: this.city,
				county: this.county,
				country: this.city,
				postCode: this.postCode,
				mobileNumber: this.mobileNumber
			}
			let dec = await this.$store.dispatch("addAddress", address)
			if (dec.status) {
				this.$store.dispatch("getUser")
			}else{
				this.error = dec.message
			}
		}
	}
},
data(){
	return {
		addLineOne:'',
		addLineTwo:'',
		city:'',
		county:'',
		country:'',
		postCode:'',
		mobileNumber:'',
		error:'',
	}
},
mounted(){
	
}
}
</script>

<style>
.link{
	text-decoration: underline;
}
.span-link{
	cursor: pointer;
	text-decoration: underline;
}
.span-link:hover{
transition: all 200ms;
font-weight:700;
}
.ddarker{
	font-weight: bold;
    color: #444444;
    font-size: 18px;
    padding-bottom: 10px;
    position: relative;
}
.table-cart.table {
    margin-top: 45%;
    background-color: #ffffff;
    border: 1px solid #f1f1f1;
    border-top: none;
}
.table th {
    background-color: #f5f5f5;
    font-weight: bold;
    /* Add any other styles as needed */
}
thead.acc-title.lg tr th{
    background-color: #fd5b4e;
    font-weight: 500;
}
</style>