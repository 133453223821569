<template>
  <div>
    <nav-bar></nav-bar>
    <main id="main" class="account dashboard">
      <section class="account-content parten-bg">
        <div class="container-fluid" style="margin-top: 15px">
          <div class="row acc-dashboard">
            <!--Account Sidebar: End-->
            <!-- <aside class="col-md-3 col-sm-4 col-xs-12 account-sidebar sidebar">
							<h3 class="acc-title lg">Account</h3>
							<ul>
								<li class="active">
									<a href="myaccount-dashboard.html" title="account dashboard">Account Dashboard</a>
								</li>
								<li>
									<a href="myaccount-information.html" title="account dashboard">Account information</a>
								</li>
								<li>
									<a href="myaccount-address.html" title="account dashboard">Address Book</a>
								</li>
								<li>
									<a href="myaccount-order.html" title="account dashboard">My Orders</a>
								</li>
								<li>
									<a href="wishlist.html" title="account dashboard">Wishlist</a>
								</li>
								<li>
									<a href="myaccount-newsletter.html" title="account dashboard">Newsletter Subscriptions</a>
								</li>
							</ul>
						</aside> -->
            <!--Account main content : Begin -->
            <section class="account-main col-md-12 col-xs-12">
              <div class="row" style="margin: 0px">
                <div class="col-12">
                  <h3 class="acc-title lg text-center">
                    WELLCOME {{ user.accountTitle }} (DUBLIN) TO YOUR CUSTOMER
                    AREA
                  </h3>
                </div>
                <div class="col-12">
                  <dashboard-stats></dashboard-stats>
                </div>
              </div>

              <h3 class="acc-title lg">My Dashboard</h3>
              <div class="row db-content">
                

                <div class="db-hello col-xs-12">
                  <div class="pad-1015">
                    <p class="hello-user">
                      Hello, {{ user.accountTitle }} User! ({{ user.userCode }})
                    </p>
                    <p class="hello-par">
                      From your My Account Dashboard you have the ability to
                      view a snapshot of your recent account activity and update
                      your account information. Select a link below to view or
                      edit information.
                    </p>
                  </div>
                </div>
                <div class="db-info col-xs-12">
                  <h3 class="acc-sub-tit-i">
                    <i class="fa fa-user"></i>
                    Account Information
                  </h3>
                  <div class="row">
                    <div class="col-md-6 col-sm-6 db-contact">
                      <h4 class="acc-title h-icon">
                        Contact information
                        <!-- <a href="#" class="acc-edit" title="edit information">
													<i class="fa fa-pencil-square-o"></i>
												</a> -->
                      </h4>
                      <div
                        class="acc-info-content pad-1015"
                        v-if="infoSection == 'view'"
                      >
                        <!-- <div>
													<span style="display:inline-block;width:150px;">Company Name:</span>
													<span style="display:inline-block;width:140px;">{{ user.company }}</span>
												</div> -->
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >Business Name:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.accountTitle
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >Account Number:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.userCode
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >Contact Person:</span
                          >
                          <span style="display: inline-block; width: 140px"
                            >{{ user.name }} {{ user.lastName }}</span
                          >
                        </div>
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >Job Title:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.titleName
                          }}</span>
                        </div>
                        <!-- <div>
													<span style="display:inline-block;width:150px;">Customer Last Name:</span>
													<span style="display:inline-block;width:140px;">{{ user.lastName }}</span>
												</div> -->
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >Telephone:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.telefone
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >Email:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.email
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >Username:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.username
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >Last Updated:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            new Date(user.upadtedAt).toLocaleDateString()
                          }}</span>
                        </div>
                        <div v-if="!user.isSubChildUser">
                          <span
                            class="text-danger span-link"
                            @click="infoSection = 'changePassword'"
                            >Change Password</span
                          >
                        </div>
                        <div v-if="!user.isSubChildUser">
                          <span
                            class="text-danger span-link"
                            @click="infoSection = 'deleteAccount'"
                            >Delete Account</span
                          >
                        </div>
                        <div v-if="!user.isSubChildUser">
                          <span
                            class="text-danger span-link"
                            @click="infoSection = 'editInfo'"
                            >Edit Info</span
                          >
                        </div>

                        <!-- <span class="name">John Dugan</span> -->
                        <!-- <span mailto:class="email">john@netbase.vn <b class="text-success">(Verified)</b> </span> -->
                        <!-- <a href="#" title="Change Password">Change Password</a> -->
                      </div>
                      <div
                        class="acc-info-content pad-1015"
                        v-if="infoSection == 'editInfo'"
                      >
                        <h3 class="text-center">Edit Account Info</h3>
                        <div>
                          <label for="">Customer First Name(required)</label>
                          <input
                            type="text"
                            name=""
                            v-model="editName"
                            id=""
                            placeholder="Firstname"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">Customer Last Name(required)</label>
                          <input
                            type="text"
                            name=""
                            v-model="editLastName"
                            id=""
                            placeholder="Lastname"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">Company</label>
                          <input
                            type="text"
                            name=""
                            v-model="editCompany"
                            id=""
                            placeholder="Company"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">Account Title & Name</label>
                          <input
                            type="text"
                            name=""
                            v-model="editAccountTitle"
                            id=""
                            placeholder="Account Title & Name"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">Telefone</label>
                          <input
                            type="text"
                            name=""
                            v-model="editTele"
                            id=""
                            placeholder="Telefone"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <button
                            class="btn btn-danger"
                            @click="infoSection = 'view'"
                          >
                            Back
                          </button>
                          <button
                            class="btn btn-danger"
                            @click="saveChanges()"
                            :disabled="
                              editName.length < 3 || editLastName.length < 3
                            "
                          >
                            Save Changes
                          </button>
                        </div>
                      </div>
                      <div
                        class="acc-info-content pad-1015"
                        v-if="infoSection == 'changePassword'"
                      >
                        <h3 class="text-center">Change Password</h3>
                        <div>
                          <label for="">Password</label>
                          <input
                            type="password"
                            name=""
                            v-model="updatePassword"
                            id=""
                            placeholder="Password"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">Confirm Password</label>
                          <input
                            type="password"
                            name=""
                            v-model="updateConfirmPassword"
                            id=""
                            placeholder="Confirm Password"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <span class="text-danger" v-if="passwordError.length">{{
                          passwordError
                        }}</span>
                        <button
                          class="btn btn-danger"
                          style="float: right"
                          @click="infoSection = 'view'"
                        >
                          Back
                        </button>
                        <button class="btn btn-danger" @click="passChanges">
                          Save Changes
                        </button>
                      </div>
                      <div
                        class="acc-info-content pad-1015"
                        v-if="infoSection == 'deleteAccount'"
                      >
                        <h3 class="text-center">Delete Account</h3>
                        <button
                          class="btn btn-danger"
                          style="float: right"
                          @click="infoSection = 'view'"
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    <div
                      class="col-md-6 col-sm-6 db-newsletter"
                      v-if="user.accountTermsActive"
                    >
                      <h4 class="acc-title h-icon">
                        Customer Credit Account Terms
                        <span href="#" class="acc-edit" title="edit">
                          <i class="fa fa-pencil-square-o"></i>
                        </span>
                      </h4>
                      <div
                        class="acc-info-content pad-1015"
                        v-if="user.accountTermsActive"
                      >
                        <p>
                          You are currently subscribed to
                          {{ user.accountTerms }} days Credit Term
                        </p>
                      </div>
                      <div class="acc-info-content pad-1015" v-else>
                        <p>
                          You are currently not subscribed to any Credit Term
                        </p>
                      </div>
                      <h4 class="acc-title h-icon">
                        <a href="#" class="acc-edit" title="edit subscription">
                          <i class="fa fa-pencil-square-o"></i>
                        </a>
                      </h4>
                      <div class="acc-info-content pad-1015">
                        <p>
                          You are currently not subscribed to any newsletter
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 col-sm-6 db-contact">
                      <h4 class="acc-title h-icon">
                        Order Emails
                        <a href="#" class="acc-edit" title="edit information">
                          <i class="fa fa-pencil-square-o"></i>
                        </a>
                      </h4>
                      <h3>Add New Email</h3>
                      <div style="max-width: 350px">
                        <input
                          type="email"
                          name=""
                          id=""
                          v-model="emNew"
                          class="form-control"
                        />
                        <button
                          class="btn btn-primary"
                          style="float: right"
                          @click="addEmail"
                        >
                          Add Email
                        </button>
                        <span class="text-danger" v-if="emError.length">{{
                          emError
                        }}</span>
                      </div>
                      <div class="acc-info-content pad-1015" v-if="user.emails">
                        <div v-for="em in user.emails" :key="em">
                          <span style="display: inline-block; width: 120px">{{
                            em
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-md-6 col-sm-6 db-contact"
                      v-if="user.billing"
                    >
                      <h4 class="acc-title h-icon">
                        Billing information
                        <a
                          v-if="!user.isSubChildUser"
                          class="acc-edit"
                          title="edit information"
                        >
                          <i
                            class="fa fa-pencil-square-o"
                            @click="infoBillSection = 'editBillingInfo'"
                          ></i>
                        </a>
                      </h4>
                      <div
                        class="acc-info-content pad-1015"
                        v-if="(user.billing, infoBillSection == 'view')"
                      >
                        <div>
                          <span style="display: inline-block; width: 120px"
                            >Business Name:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.name
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 120px"
                            >Address Line 1:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.billing.billAddressOne
                              ? user.billing.billAddressOne
                              : user.billing.addressOne
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 120px"
                            >Address Line 1</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.billing.billAddressTwo
                              ? user.billing.billAddressTwo
                              : user.billing.addressTwo
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 120px"
                            >City/Town:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.billing.billCity
                              ? user.billing.billCity
                              : user.billing.city
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 120px"
                            >County:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.billing.billCounty
                              ? user.billing.billCounty
                              : user.billing.county
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 120px"
                            >Country:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.billing.billCountry
                              ? user.billing.billCountry
                              : user.billing.country
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 120px"
                            >PostCode:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.billing.billPostCode
                              ? user.billing.billPostCode
                              : user.billing.postCode
                          }}</span>
                        </div>
                        <!-- <div>
													<span class="text-danger span-link">Change Password</span>
												</div>
												<div>
													<span class="text-danger span-link">Delete Account</span>
												</div> -->
                        <!-- <span class="name">John Dugan</span> -->
                        <!-- <span mailto:class="email">john@netbase.vn <b class="text-success">(Verified)</b> </span> -->
                        <!-- <a href="#" title="Change Password">Change Password</a> -->
                      </div>
                      <div class="acc-info-content pad-1015" v-else>
                        <p class="text-danger">
                          No Billing Address Set Currently
                        </p>
                      </div>
                      <div
                        class="acc-info-content pad-1015"
                        v-if="infoBillSection == 'editBillingInfo'"
                      >
                        <h3 class="text-center">
                          Edit Billing Delivery Information
                        </h3>
                        <div>
                          <label for="">Address Line 1:</label>
                          <input
                            type="text"
                            name=""
                            v-model="editBillAddressOne"
                            id=""
                            placeholder="Address Line 1"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">Address Line 2:</label>
                          <input
                            type="text"
                            name=""
                            v-model="editBillAddressTwo"
                            id=""
                            placeholder="Address Line 2"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">City/Town:</label>
                          <input
                            type="text"
                            name=""
                            v-model="editBillCity"
                            id=""
                            placeholder="City/Town"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">County:</label>
                          <input
                            type="text"
                            name=""
                            v-model="editBillCounty"
                            id=""
                            placeholder="County"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">Country:</label>
                          <input
                            type="text"
                            name=""
                            v-model="editBillCountry"
                            id=""
                            placeholder="Country"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">PostCode:</label>
                          <input
                            type="text"
                            name=""
                            v-model="editBillPostCode"
                            id=""
                            placeholder="PostCode"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <button
                          class="btn btn-danger"
                          @click="infoBillSection = 'view'"
                        >
                          Back
                        </button>
                        <button
                          class="btn btn-danger"
                          @click="saveBillingChanges()"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                    <div
                      class="col-md-6 col-sm-6 db-contact"
                      v-if="user.address"
                    >
                      <h4 class="acc-title h-icon">
                        Default Delivery Information
                        <a
                          v-if="!user.isSubChildUser"
                          class="acc-edit"
                          title="edit subscription"
                        >
                          <i
                            class="fa fa-pencil-square-o"
                            @click="infoDDSection = 'editDefaultDeliveryInfo'"
                          ></i>
                        </a>
                      </h4>
                      <div
                        class="acc-info-content pad-1015"
                        v-if="(user.address, infoDDSection == 'view')"
                      >
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >Business Name:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.name
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >Address Line 1:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.address.addressOne
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >Address Line 1</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.address.addressTwo
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >City/Town:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.address.city
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >County:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.address.county
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >Country:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.address.country
                          }}</span>
                        </div>
                        <div>
                          <span style="display: inline-block; width: 150px"
                            >PostCode:</span
                          >
                          <span style="display: inline-block; width: 140px">{{
                            user.address.postCode
                          }}</span>
                        </div>
                      </div>

                      <div
                        class="acc-info-content pad-1015"
                        v-if="infoDDSection == 'editDefaultDeliveryInfo'"
                      >
                        <h3 class="text-center">
                          Edit Default Delivery Information
                        </h3>
                        <div>
                          <label for="">Address Line 1:</label>
                          <input
                            type="text"
                            name=""
                            v-model="editAddressOne"
                            id=""
                            placeholder="Address Line 1"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">Address Line 2:</label>
                          <input
                            type="text"
                            name=""
                            v-model="editAddressTwo"
                            id=""
                            placeholder="Address Line 2"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">City/Town:</label>
                          <input
                            type="text"
                            name=""
                            v-model="editCity"
                            id=""
                            placeholder="City/Town"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">County:</label>
                          <input
                            type="text"
                            name=""
                            v-model="editCounty"
                            id=""
                            placeholder="County"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">Country:</label>
                          <input
                            type="text"
                            name=""
                            v-model="editCountry"
                            id=""
                            placeholder="Country"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <div>
                          <label for="">PostCode:</label>
                          <input
                            type="text"
                            name=""
                            v-model="editPostCode"
                            id=""
                            placeholder="PostCode"
                            style="margin: 10px"
                            class="form-control"
                          />
                        </div>
                        <button
                          class="btn btn-danger"
                          @click="infoDDSection = 'view'"
                        >
                          Back
                        </button>
                        <button
                          class="btn btn-danger"
                          @click="saveDeliveryAddressChanges()"
                        >
                          Save Changes
                        </button>
                      </div>
                    </div>
                    <div class="col-md-12 db-contact" v-if="userPCP.length > 0">
                      <h4 class="acc-title h-icon">
                        Preferential Customer Pricing
                        <a href="#" class="acc-edit" title="edit subscription">
                          <i class="fa fa-pencil-square-o"></i>
                        </a>
                      </h4>
                      <div class="acc-info-content pad-1015" v-if="userPCP">
                        <div v-for="pcp in userPCP" :key="pcp._id">
                          <p>
                            <b>Contact Person:</b> {{ user.name }}
                            {{ user.lastName }}
                          </p>
                          <p>
                            <b>Discount Validity: </b> can be used from
                            <i>{{ new Date(pcp.startAt).toDateString() }}</i> to
                            <i>{{ new Date(pcp.endAt).toDateString() }}</i>
                          </p>
                          <p><b>Discount Amount: </b>{{ pcp.discount }}%</p>
                          <p>
                            <b>Date of Preferential Pricing Agreement:</b>
                            {{ new Date(pcp.createdAt).toDateString() }}
                          </p>
                          <p><b>comments: </b>{{ pcp.comments }}</p>
                          <p>
                            <b>Discount On: </b
                            ><span v-if="pcp.discountOn !== 'selected'">
                              <b>All Products</b>
                            </span>
                            <span
                              v-for="(product, index) in pcp.products"
                              :key="product._id"
                            >
                              <ol>
                                <li>
                                  <b>{{ index + 1 }}.</b>
                                  {{ product.productName }}
                                </li>
                              </ol>
                            </span>
                          </p>
                          <!-- <table class="table table-bordered">
														<tr>
															<th>Product Name</th>
															<th>Normal Price</th>
															<th>Discounted Price </th>
														</tr>
														<tr v-for="product in userPCP.products" :key="product._id">
															<td>{{ product.productName }}</td>
															<td>{{ (product.sellingProductPrice).toFixed(2) }}</td>
															<td>{{ (product.sellingProductPrice - ((product.sellingProductPrice * userPCP.discount) / 100 )).toFixed(2) }}</td>
														</tr>
													</table>
													<ul>
														<li ></li>
													</ul> -->
                          <hr />
                        </div>
                        <!-- <span style="display:inline-block;width:140px;">{{ user.name }}  {{ user.lastName }}</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <!-- Cart main content : End -->
          </div>
        </div>
      </section>
    </main>
    <!-- Main Category: End -->
    <!--Footer : Begin-->
    <div>
      <footer-section></footer-section>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import DashboardStats from "./DashboardStats.vue";
import FooterSection from "@/components/FooterSection.vue";
import { mapState } from "vuex";
export default {
  components: { DashboardStats, NavBar, FooterSection },
  data() {
    return {
      infoSection: "view",
      infoDDSection: "view",
      editName: "",
      editLastName: "",
      emNew: "",
      emError: "",
      passwordError: "",
      editCompany: "",
      editAccountTitle: "",
      editTele: "",
      infoBillSection: "view",
      updatePassword: "",
      updateConfirmPassword: "",
    };
  },
  watch: {
    infoSection(value) {
      if (value == "editInfo") {
        this.editName = this.user.name;
        this.editLastName = this.user.lastName ? this.user.lastName : "";
        this.editCompany = this.user.company;
        this.editAccountTitle = this.user.accountTitle;
        this.editTele = this.user.telefone;
      }
    },
    infoBillSection(value) {
      if (value == "editBillingInfo") {
        (this.editBillAddressOne = this.user.billing.billAddressOne
          ? this.user.billing.billAddressOne
          : this.user.billing.addressOne),
          (this.editBillAddressTwo = this.user.billing.billAddressTwo
            ? this.user.billing.billAddressTwo
            : this.user.billing.addressTwo),
          (this.editBillCity = this.user.billing.billCity
            ? this.user.billing.billCity
            : this.user.billing.city),
          (this.editBillCounty = this.user.billing.billCounty
            ? this.user.billing.billCounty
            : this.user.billing.county),
          (this.editBillCountry = this.user.billing.billCountry
            ? this.user.billing.billCountry
            : this.user.billing.country),
          (this.editBillPostCode = this.user.billing.billPostCode
            ? this.user.billing.billPostCode
            : this.user.billing.postCode);
      }
    },
    infoDDSection(value) {
      if (value == "editDefaultDeliveryInfo") {
        (this.editBusinessName = this.user.name),
          (this.editAddressOne = this.user.address.addressOne),
          (this.editAddressTwo = this.user.address.addressTwo),
          (this.editCity = this.user.address.city),
          (this.editCounty = this.user.address.county),
          (this.editCountry = this.user.address.country),
          (this.editPostCode = this.user.address.postCode);
      }
    },
  },
  computed: {
    ...mapState(["userOrders", "user", "userPCP"]),
  },
  methods: {
    validateEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    async addEmail() {
      let exists = this.user.emails.includes(this.emNew);
      let val = this.validateEmail(this.emNew);
      if (exists || !val) {
        this.emError = exists ? "Email Already Added" : "Email is not valid";
      } else {
        let dec = await this.$store.dispatch("addNewEmail", {
          emails: this.user.emails ? this.user.emails : [],
          email: this.emNew,
          user: this.user._id,
        });
        if (dec.status) {
          alert("email added");
          this.emError = "";
          this.user.emails.push(this.emNew);
          this.emNew = "";
        } else {
          this.emError = "Failed to Add Email";
        }
      }
      //check email is not already added
      //check email is valid
      //check email is added then add it in users's array
    },
    async saveBillingChanges() {
      let details = {
        _id: this.user.billing._id,
        billAddressOne: this.editBillAddressOne,
        billAddressTwo: this.editBillAddressTwo,
        billCity: this.editBillCity,
        billCounty: this.editBillCounty,
        billCountry: this.editBillCountry,
        billPostCode: this.editBillPostCode,
        user: this.user.billing.user,
        default: this.user.billing.default,
      };
      let dec = await await this.$store.dispatch("updateAddress", {
        address: details,
      });

      if (dec.status) {
        (this.user.billing.billAddressOne = this.editBillAddressOne),
          (this.user.billing.billAddressTwo = this.editBillAddressTwo),
          (this.user.billing.billCity = this.editBillCity),
          (this.user.billing.billCounty = this.editBillCounty),
          (this.user.billing.billCountry = this.editBillCountry),
          (this.user.billing.billPostCode = this.editBillPostCode),
          (this.editBillAddressOne = "");
        this.editBillAddressTwo = "";
        this.editBillCity = "";
        this.editBillCounty = "";
        this.editBillCountry = "";
        this.editBillPostCode = "";
        this.infoBillSection = "view";
      } else {
        alert("error in updating");
      }
    },
    async saveChanges() {
      let details = {
        name: this.editName,
        lastName: this.editLastName,
        company: this.editCompany,
        telefone: this.editTele,
        accountTitle: this.editAccountTitle,
      };
      let dec = await this.$store.dispatch("updateUserInfo", {
        details,
        user: this.user._id,
      });
      if (dec.status) {
        this.user.name = this.editName;
        this.user.lastName = this.editLastName;
        this.user.company = this.editCompany;
        this.user.accountTitle = this.editAccountTitle;
        this.user.telefone = this.editTele;
        // this.editLastName = (this.user.lastName) ? this.user.lastName : "";
        // this.editCompany = this.user.company
        // this.editAccountTitle = this.user.accountTitle
        // this.editTele = this.user.telefone
        this.editName = "";
        this.editLastName = "";
        this.editCompany = "";
        this.editAccountTitle = "";
        this.editTele = "";
        this.infoSection = "view";
      } else {
        alert("error in updating");
      }
    },

    async passChanges() {
      if (this.updatePassword !== this.updateConfirmPassword) {
        this.passwordError = "Passwords do not match.";
        return;
      }

      let dec = await await this.$store.dispatch("resetPassword", {
        user: this.user._id,
        password: this.updatePassword,
      });
      if (dec.status) {
        this.infoSection = "view";
      }
    },

    async saveDeliveryAddressChanges() {
      let details = {
        _id: this.user.address._id,
        addressOne: this.editAddressOne,
        addressTwo: this.editAddressTwo,
        city: this.editCity,
        county: this.editCounty,
        country: this.editCountry,
        postCode: this.editPostCode,
        user: this.user.user,
        default: this.user.default,
        __v: this.user.__v,
      };
      let dec = await await this.$store.dispatch("updateAddress", {
        address: details,
      });

      if (dec.status) {
        (this.user.address.addressOne = this.editAddressOne),
          (this.user.address.addressTwo = this.editAddressTwo),
          (this.user.address.city = this.editCity),
          (this.user.address.county = this.editCounty),
          (this.user.address.country = this.editCountry),
          (this.user.address.postCode = this.editPostCode),
          (this.editAddressOne = "");
        this.editAddressTwo = "";
        this.editCity = "";
        this.editCounty = "";
        this.editPostCode = "";
        this.infoDDSection = "view";
      } else {
        alert("error in updating");
      }
    },
  },
  mounted() {
    this.$store.dispatch("getPcps");
    // let user = (this.user) ? this.
    // this.$store.dispatch("fetchUserOrders", {user})
  },
};

</script>

<style>
.span-link {
  cursor: pointer;
  text-decoration: underline;
}
.span-link:hover {
  transition: all 200ms;
  font-weight: 700;
}
.middle {
  background-color: rgb(31, 31, 191);
}
.calculate-spend {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 17px 0;
}
.calculate-spend select {
  margin: 0 5px;
}

.spend-title {
  background: #fd5b4e;
  padding: 10px 15px;
  color: #ffffff;
  font-size: 16px;
  overflow: hidden;
}

</style>
